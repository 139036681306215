import { images, IThemeImage } from './images';
import { colors, IThemeColor } from './colors';

export interface ITheme {
  space: number[];
  fontSizes: number[];
  images: IThemeImage;
  colors: IThemeColor;
  breakpoints: string[];
}

export const theme: ITheme = {
  fontSizes: [12, 14, 16, 18, 24, 32, 36, 72, 96],

  space: [2, 4, 8, 16, 32, 64, 128, 256],

  breakpoints: ['576px', '768px', '1150px', '1250px'],

  images,

  colors,
};
