import React from 'react';
import { useStore } from 'effector-react';
import { renderRoutes } from 'react-router-config';

// DASHBOARD PAGE
import { DashboardPage } from './dashboard/page';

// GUARDS
import { compileGuards } from 'lib/guards';
import { $isAuthenticated } from 'lib/token';
import { onlyAnon, onlyAuth } from 'features/common';

// AUTH PAGES
import { SignInPage } from './auth/signin/page';
import { SignUpPage } from './auth/signup/page';
import { SignUpSuccess } from './auth/signup-success/page';
import { ResetPasswordPage } from './auth/reset-password/page';
import { RequestPasswordPage } from './auth/request-password/page';
import { EmailConfirmationPage } from './auth/email-confirmation/page';

// ONBOARDING PAGES
import { OnboardingPage } from './onboarding/page';

// PROFILE PAGES
import { ProfileViewPage } from './profile/view/page';
import { ProfileEditPage } from './profile/edit/page';

// JOBS PAGES
import { JobPage } from './job/page';
import { JobsPage } from './jobs/page';
import { NewJobPage } from './new-job/page';
import { EditJobPage } from './edit-job/page';
import { ApplyJobPage } from './apply-job/page';
import { SearchJobPage } from './search-job/page';

// USERS PAGES
import { UserPage } from './user/page';

// SEARCH PAGES
import { SearchesPage } from './searches/page';

// HIRING PAGES
import { ApplyPage } from './apply/page';
import { AppliesPage } from './applies/page';
import { ProposalPage } from './proposal/page';
import { ProposalsPage } from './proposals/page';
import { FeedbackPage } from './feedback/page';

const routes = () => [
  // GENERAL ROUTES
  {
    path: '/',
    component: DashboardPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  // AUTH ROUTES
  {
    path: '/signin',
    component: SignInPage,
    exact: true,
    guards: [onlyAnon({ redirect: `/` })],
  },
  {
    path: '/signup',
    component: SignUpPage,
    exact: true,
    guards: [onlyAnon({ redirect: `/` })],
  },
  {
    path: '/signup-success',
    component: SignUpSuccess,
    exact: true,
    guards: [onlyAnon({ redirect: `/` })],
  },
  {
    path: '/request-password',
    component: RequestPasswordPage,
    exact: true,
    guards: [onlyAnon({ redirect: `/` })],
  },
  {
    path: '/forgot-password/:token',
    component: ResetPasswordPage,
    exact: true,
    guards: [onlyAnon({ redirect: `/` })],
  },
  {
    path: '/confirm-email/:token',
    component: EmailConfirmationPage,
    exact: true,
    guards: [onlyAnon({ redirect: `/` })],
  },
  // ONBOARDING ROUTES
  {
    path: '/onboarding',
    component: OnboardingPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  // PROFILE ROUTES
  {
    path: '/profile',
    component: ProfileViewPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/profile/edit',
    component: ProfileEditPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  // JOBS ROUTES
  {
    path: '/jobs',
    component: JobsPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/jobs/new',
    component: NewJobPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/jobs/search',
    component: SearchJobPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/jobs/:id',
    component: JobPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/jobs/apply/:id',
    component: ApplyJobPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/jobs/edit/:id',
    component: EditJobPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  // USER ROUTES
  {
    path: '/users/:id',
    component: UserPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  // SEARCHES ROUTES
  {
    path: '/searches',
    component: SearchesPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  // HIRING ROUTES
  {
    path: '/hiring/proposals',
    component: ProposalsPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/proposals/not-confirmed',
    component: ProposalsPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/proposals/hired',
    component: ProposalsPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/proposals/finished',
    component: ProposalsPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/proposals/cancelled',
    component: ProposalsPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/proposals/:id/:status',
    component: ProposalPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/applies',
    component: AppliesPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/applies/not-confirmed',
    component: AppliesPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/applies/confirmed',
    component: AppliesPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/applies/finished',
    component: AppliesPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/applies/cancelled',
    component: AppliesPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/applies/:id/:status',
    component: ApplyPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
  {
    path: '/hiring/feedback/:type/:id/:user_id',
    component: FeedbackPage,
    exact: true,
    guards: [onlyAuth({ redirect: '/signin' })],
  },
];

export const Pages = () => {
  const isAuthenticated = useStore($isAuthenticated);

  const result = React.useMemo(
    () => renderRoutes(compileGuards(routes(), { isAuthenticated })),
    [isAuthenticated],
  );

  return <>{result}</>;
};
