import { createEffect, createStore, forward } from 'effector';
import { createGate } from 'effector-react';

import { createFetching } from 'lib/fetching';
import { IError, IResponse, IJob } from 'lib/types';
import { getPostedJobs, ListJobsDTO } from 'api/jobs';

export const PostedJobsGate = createGate<{ per_page: number; current_page: number }>();

export const $postedJobs = createStore<IJob[] | null>(null);
const postedJobsLoadingEffect = createEffect<
  IPaginationConfig,
  IResponse<ListJobsDTO>,
  IError
>();
export const postedJobsFetching = createFetching(postedJobsLoadingEffect);

postedJobsLoadingEffect.use(x => getPostedJobs(x));

$postedJobs.on(postedJobsLoadingEffect.done, (_, { result }) => result.data.data);

export type IPaginationConfig = {
  total?: number;
  per_page: number;
  current_page: number;
};

export const $paginationConfig = createStore<IPaginationConfig>({
  per_page: 20,
  current_page: 1,
});

$paginationConfig.on(postedJobsLoadingEffect.done, (_, { result }) => {
  const { total, per_page, current_page } = result.data;

  return { total, per_page: parseInt(per_page, 10), current_page };
});

forward({
  from: PostedJobsGate.state,
  to: postedJobsLoadingEffect,
});
