import React, { Fragment, useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { SearchBar } from 'features/search';
import { Header, PageLoader } from 'features/common';
import { JobCard, JobsPagination } from 'features/jobs';
import { DashboardTemplate, Box, PrimaryButton, Text, Image, theme } from 'ui';
import {
  $searchJobs,
  $paginationConfig,
  searchJobsFetching,
  setPaginationConfig,
  $searchConfig,
  setSearchConfig,
} from './model';

export const SearchJobPage = () => {
  const { t } = useTranslation();
  const isLoading = useStore(searchJobsFetching.isLoading);
  const searchJobs = useStore($searchJobs);
  const searchConfig = useStore($searchConfig);
  const paginationConfig = useStore($paginationConfig);
  const [searchBarIsVisible, setSearchBarVisibility] = useState(
    window.innerWidth >= 1150,
  );

  // INIT SEARCH CONFIG
  useEffect(() => {
    setSearchConfig();
  }, []);

  return (
    <Fragment>
      <DashboardTemplate
        render={() => (
          <Box
            width={['100%', '510px', '510px', 'auto']}
            px={['10px', '0px', '0px', '0px']}
            ml={['0px', '0px', '0px', '-200px']}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Box display={['flex', 'flex', 'flex', 'none']} width={1}>
              <PrimaryButton
                type="button"
                style={{ marginBottom: '32px', width: '100%' }}
                onClick={() => setSearchBarVisibility(!searchBarIsVisible)}
              >
                {t('SHOW FILTERS')}

                <Image
                  src={theme.images.whiteArrowDownIcon}
                  style={{
                    position: 'relative',
                    bottom: '1px',
                    marginLeft: '10px',
                    transform: searchBarIsVisible ? 'rotate(180deg)' : 'rotate(360deg)',
                  }}
                />
              </PrimaryButton>
            </Box>

            {searchBarIsVisible && (
              <Box
                mr={['0px', '0px', '0px', '40px']}
                backgroundColor="theme.colors.UIWhite"
              >
                <SearchBar searchConfig={searchConfig} />
              </Box>
            )}

            <Box flexDirection="column" width={['100%', '510px', '510px', '510px']}>
              {!!searchJobs?.length ? (
                <Fragment>
                  <Box mb={4} flexDirection="column">
                    {searchJobs &&
                      searchJobs.map((job, i) => <JobCard key={i} job={job} />)}
                  </Box>

                  {paginationConfig && searchJobs && (
                    <JobsPagination
                      totalJobs={paginationConfig.total || 0}
                      currentPage={paginationConfig.page}
                      itemsPerPage={paginationConfig.per_page}
                      triggerPagination={({ per_page, current_page }) =>
                        setPaginationConfig({ per_page, page: current_page })
                      }
                    />
                  )}
                </Fragment>
              ) : (
                <Text textAlign="center" fontWeight="bold" lineHeight="24px">
                  {t('No jobs were found for your search')}
                  <br />
                  {t('Change the parameters and try again')}
                </Text>
              )}
            </Box>
          </Box>
        )}
        renderHeader={profile => <Header profile={profile} />}
      />

      {isLoading && <PageLoader />}
    </Fragment>
  );
};
