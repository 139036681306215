import React, { Fragment, useState } from 'react';
import { useStore } from 'effector-react';
import {
  Dot,
  Slide,
  Slider,
  DotGroup,
  ImageWithZoom,
  CarouselProvider,
} from 'pure-react-carousel';

import { $enviroment } from 'lib/config';
import { Image, theme } from 'ui';

export const JobPicturesCarousel = ({ pictures }: { pictures: string }) => {
  const enviroment = useStore($enviroment);
  const parsedPictures: string[] = JSON.parse(pictures);
  const [carouselIsVisible, setCarouselVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <CarouselProvider
      currentSlide={currentSlide}
      naturalSlideWidth={10}
      naturalSlideHeight={10}
      totalSlides={parsedPictures.length}
    >
      <Fragment>
        {carouselIsVisible && (
          <Slider>
            {parsedPictures.map((x, i) => (
              <Slide index={i} key={i}>
                <ImageWithZoom src={`${enviroment.STORAGE_URL}${x}`} />
              </Slide>
            ))}
          </Slider>
        )}

        {carouselIsVisible && (
          <Image
            style={{
              position: 'absolute',
              top: '-13px',
              right: '-13px',
            }}
            src={theme.images.deleteFileIcon}
            onClick={() => setCarouselVisible(false)}
            isPointer={true}
          />
        )}
      </Fragment>

      <DotGroup
        renderDots={() => (
          <Fragment>
            {parsedPictures.map((x, i) => (
              <Dot
                key={i}
                slide={i}
                onClick={() => {
                  setCurrentSlide(i);
                  setCarouselVisible(true);
                }}
                disabled={false}
              >
                <Image src={`${enviroment.STORAGE_URL}${x}`} width="70px" height="70px" />
              </Dot>
            ))}
          </Fragment>
        )}
      />
    </CarouselProvider>
  );
};
