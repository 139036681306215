import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, FormikBag } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router';

import { AuthTemplate } from '../templates/auth';
import { H3, Box, Form, Input, PrimaryButton, Card, BoxError } from 'ui';
import {
  pageUnmounted,
  invokeResetPassword,
  $resetPasswordError,
  resetPasswordFetching,
} from './model';

export const ResetPasswordPage = withRouter(
  ({ match }: RouteComponentProps<{ token: string }>) => {
    const { t } = useTranslation();
    const token = match.params.token;
    const error = useStore($resetPasswordError);
    const isDone = useStore(resetPasswordFetching.isDone);

    useEffect(() => () => pageUnmounted(), []);

    return (
      <AuthTemplate
        card={
          <Card>
            <ResetPasswordForm token={token} />
          </Card>
        }
        error={error && <BoxError>{t(error.message)}</BoxError>}
        success={isDone ? { title: 'Your password reset!', route: '/signin' } : undefined}
      />
    );
  },
);

const ResetPasswordLayout = ({
  values,
  errors,
  touched,
  handleBlur,
  handleSubmit,
  handleChange,
}: FormikProps<IResetPasswordFormState> & IResetPasswordFormProps) => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="column">
      <H3 textAlign="center">{t('Reset password')}</H3>

      <Form onSubmit={handleSubmit}>
        <Input
          id="email"
          name="email"
          type="email"
          label="Email"
          error={touched.email && errors.email}
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
        />

        <Input
          id="password"
          name="password"
          type="password"
          label="New password"
          error={touched.email && errors.password}
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
        />

        <Input
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          label="Confirm new password"
          error={touched.password_confirmation && errors.password_confirmation}
          value={values.password_confirmation}
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
        />

        <Box mt={4} justifyContent="center">
          <PrimaryButton type="submit">{t('Reset')}</PrimaryButton>
        </Box>
      </Form>
    </Box>
  );
};

const ResetPasswordForm = withFormik({
  mapPropsToValues: (): IResetPasswordFormState => ({
    email: '',
    password: '',
    password_confirmation: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().required('Email field is required'),
    password: Yup.string().required('Password field is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords should match')
      .required('Password field is required'),
  }),

  handleSubmit: (
    values: IResetPasswordFormState,
    fb: FormikBag<IResetPasswordFormProps, IResetPasswordFormState>,
  ) => invokeResetPassword(Object.assign({}, values, fb.props)),
})(ResetPasswordLayout);

export interface IResetPasswordFormState {
  email: string;
  password: string;
  password_confirmation: string;
}

export interface IResetPasswordFormProps {
  token: string;
}

export type IResetPasswordRequestType = IResetPasswordFormState & IResetPasswordFormProps;
