import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Box, Image, theme, Text } from 'ui';

export const ButtonTooltip = ({
  children,
  tooltip,
}: {
  children: ReactNode;
  tooltip: string;
}) => {
  return (
    <TooltipWrapper className="tooltip-wrapper">
      {children}

      <Box
        p="15px"
        mt="10px"
        display="none"
        width="300px"
        alignItems="center"
        backgroundColor="UIBlue"
        className="tooltip"
        position="absolute"
        bottom="calc(100% + 20px)"
      >
        <Image src={theme.images.whiteWarningIcon} height="25px" />

        <Text
          pl="15px"
          color="UIWhite"
          dangerouslySetInnerHTML={{ __html: tooltip }}
        ></Text>
      </Box>
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled(Box)`
  position: relative;

  > ${Box}:before {
    content: '';

    position: absolute;
    left: 10px;
    bottom: -10px;

    width: 0;
    height: 0;
    border-top: 10px solid ${theme.colors.UIBlue};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 59px;
  }

  a:hover + .tooltip {
    display: flex;
  }

  button:hover + .tooltip {
    display: flex;
  }
`;
