import { createGate } from 'effector-react';
import { forward, createEffect, createStore, createEvent } from 'effector';

import { IError } from 'lib/types';
import { createFetching } from 'lib/fetching';
import { emailConfirmation } from 'api/auth';

export interface IEmailConfirmationState {
  token: string;
}

export const pageUnmounted = createEvent();
export const Gate = createGate<IEmailConfirmationState>();
export const $emailConfirmationError = createStore<IError | null>(null);
export const $emailConfirmationData = createStore('');
export const emailConfirmationEffect = createEffect<
  IEmailConfirmationState,
  any,
  IError
>();
export const emailConfirmationFetching = createFetching(emailConfirmationEffect);

emailConfirmationEffect.use(x => emailConfirmation(x));

$emailConfirmationError
  .on(emailConfirmationEffect.fail, (_, { error }) => error)
  .reset(pageUnmounted, emailConfirmationFetching.isDone);

forward({
  from: Gate.state,
  to: emailConfirmationEffect,
});
