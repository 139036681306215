import styled from 'styled-components';

import { theme } from 'ui/theme';

export const BoxError = styled.span`
  display: block;
  text-align: center;

  color: ${theme.colors.UIRed};
  font-size: ${theme.fontSizes[1]}px;
  font-weight: bold;
`;
