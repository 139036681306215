import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { FormikHandlers, FormikProps } from 'formik';

import { formatDate } from 'lib/format-date';
import { Box, Text, Label, FieldError } from 'ui';

interface IDatePicker {
  id: string;
  form: FormikProps<any>;
  field: {
    name: string;
    value: Date | null;
    onBlur: FormikHandlers['handleBlur'];
    onChange: FormikHandlers['handleChange'];
  };
  label: string;
  error: string;
  required: boolean;
}

export const Datepicker = ({ id, form, field, label, error, required }: IDatePicker) => {
  const { t } = useTranslation();

  return (
    <DatepickerWrapper mb="15px" width={1} flexDirection="column">
      {label && (
        <Label htmlFor={id}>
          {required && (
            <Text color="UIRed" mr={0}>
              *
            </Text>
          )}

          {t(label)}
        </Label>
      )}

      <DatePicker
        selected={field.value ? new Date(field.value) : null}
        onChange={date => form.setFieldValue(field.name, formatDate(date as Date))}
        dateFormat="yyyy-MM-dd"
        minDate={new Date()}
      />

      {error && <FieldError>{t(error as string)}</FieldError>}
    </DatepickerWrapper>
  );
};

const DatepickerWrapper = styled(Box)`
  > div {
    width: 100%;
  }

  svg {
    position: relative;
    bottom: 3px;
  }
`;
