import React, { Fragment, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';

import { BoxError } from 'ui';
import { AuthTemplate } from '../templates/auth';
import { FETCHING_STATUSES } from 'lib/fetching';
import { PageLoader, SuccessCard } from 'features/common';
import {
  Gate,
  emailConfirmationFetching,
  pageUnmounted,
  $emailConfirmationError,
} from './model';

export const EmailConfirmationPage = withRouter(
  ({ match }: RouteComponentProps<{ token: string }>) => {
    const { t } = useTranslation();
    const token = match.params.token;
    const error = useStore($emailConfirmationError);
    const status = useStore(emailConfirmationFetching.status);

    useEffect(() => () => pageUnmounted(), []);

    return (
      <Fragment>
        <Gate token={token} />

        <AuthTemplate
          card={
            status === FETCHING_STATUSES.DONE && (
              <SuccessCard
                title="Your account has been successfully confirmed!"
                route="/signin"
              />
            )
          }
          error={error && <BoxError>{t(error.message)}</BoxError>}
        />

        {status === FETCHING_STATUSES.LOADING && <PageLoader />}
      </Fragment>
    );
  },
);
