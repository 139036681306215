import logo from './common/logo.svg';
import hintIcon from './hiring/hint-icon.svg';
import menuIcon from './common/menu-icon.svg';
import closeIcon from './common/close-icon.svg';
import alertIcon from './common/alert-icon.svg';
import searchIcon from './search/search-icon.svg';
import dollarIcon from './jobs/dollar-icon.svg';
import logoutIcon from './common/logout-icon.svg';
import percentIcon from './jobs/percent-icon.svg';
import successIcon from './auth/success-icon.svg';
import profileIcon from './common/profile-icon.svg';
import warningIcon from './common/warning-icon.svg';
import calendarIcon from './common/calendar-icon.svg';
import locationIcon from './profile/location-icon.svg';
import handshakeIcon from './jobs/handshake-icon.svg';
import otherRoleIcon from './profile/other-role-icon.svg';
import arrowDownIcon from './common/arrow-down-icon.svg';
import inputClockIcon from './common/input-clock-icon.svg';
import dealerRoleIcon from './profile/dealer-role-icon.svg';
import brokerRoleIcon from './profile/broker-role-icon.svg';
import deleteFileIcon from './jobs/delete-file-icon.svg';
import emptyAvatarIcon from './profile/empty-avatar-icon.svg';
import profileEditIcon from './profile/profile-edit-icon.svg';
import auctionRoleIcon from './profile/auction-role-icon.svg';
import bodyShopRoleIcon from './profile/body-shop-role-icon.svg';
import arrowTopBlueIcon from './common/arrow-top-blue-icon.svg';
import changeAvatarIcon from './profile/change-avatar-icon.svg';
import deleteSearchIcon from './search/delete-search-icon.svg';
import whiteWarningIcon from './common/white-warning-icon.svg';
import whiteArrowDownIcon from './common/white-arrow-down-icon.svg';
import technicianRoleIcon from './profile/technician-role-icon.svg';
import rentalAgencyRoleIcon from './profile/rental-agency-role-icon.svg';
import manufacturerRoleIcon from './profile/manufacturer-role-icon.svg';

export const images = {
  logo,
  menuIcon,
  hintIcon,
  alertIcon,
  closeIcon,
  searchIcon,
  dollarIcon,
  logoutIcon,
  percentIcon,
  successIcon,
  profileIcon,
  warningIcon,
  calendarIcon,
  locationIcon,
  otherRoleIcon,
  handshakeIcon,
  arrowDownIcon,
  brokerRoleIcon,
  dealerRoleIcon,
  deleteFileIcon,
  inputClockIcon,
  auctionRoleIcon,
  emptyAvatarIcon,
  profileEditIcon,
  bodyShopRoleIcon,
  deleteSearchIcon,
  whiteWarningIcon,
  arrowTopBlueIcon,
  changeAvatarIcon,
  whiteArrowDownIcon,
  technicianRoleIcon,
  rentalAgencyRoleIcon,
  manufacturerRoleIcon,
};

export interface IThemeImage {
  [name: string]: string;
}
