import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IJob } from 'lib/types';
import { history } from 'lib/routing';
import { Box, theme } from 'ui';
import { formatDate } from 'lib/format-date';
import { JobUserInfo } from './job-user-info';
import { JobInfoCell } from './job-info-cell';
import { JobLocationInfo } from './job-location-info';
import { ApplicationsQty } from './applications-qty';
import { JobPaymentSection } from './job-payment-section';
import { WORK_PLACE_OPTIONS } from '../lib/constants';
import { capitalizeFirstLetter } from 'lib/react-select-format-array';
import { SectionTitle, SectionTemplate } from './job-section';

interface IJobCard {
  job: IJob;
  jobRoute?: string;
  applicationsQty?: number;
  activeApplicationsQty?: number;
}

export const JobCard = ({
  job,
  jobRoute = `/jobs/${job.id}`,
  applicationsQty,
  activeApplicationsQty,
}: IJobCard) => {
  const {
    user,
    city,
    state,
    country,
    continent,
    work_place,
    created_at,
    damage_type,
    highlighted,
    payment_type,
    pricing_type,
    price_from_car,
    work_start_date,
    work_start_time,
    price_by_agreement,
    price_from_percentage,
  } = job;

  return (
    <Job
      p={['20px', '20px 40px']}
      onClick={() => history.push(jobRoute)}
      highlighled={highlighted}
    >
      <JobUserInfo user={user} postedDate={created_at} />

      <LocationSection location={{ continent, country, state, city }} />

      <InformationSection
        workPlace={WORK_PLACE_OPTIONS.find(x => x.value === work_place)?.label as string}
        startDate={work_start_date}
        damageType={damage_type}
        startTime={work_start_time}
      />

      <PaymentSection
        pricingType={pricing_type}
        paymentType={payment_type}
        paymentValues={{ price_by_agreement, price_from_percentage, price_from_car }}
      />

      <ApplicationsQty
        applicationsQty={applicationsQty}
        activeApplicationsQty={activeApplicationsQty}
      />
    </Job>
  );
};

const Job = styled(Box)<{ highlighled: number }>`
  flex-direction: column;
  margin-bottom: 20px;

  position: relative;

  cursor: pointer;
  box-shadow: 0px 4px 59px -5px rgba(0, 0, 0, 0.14);
  background-color: ${theme.colors.UIWhite};

  :hover {
    box-shadow: 0px 4px 59px -5px rgba(0, 0, 0, 0.25);
  }

  :last-child {
    border-bottom: 0px;
  }

  ${({ highlighled }) =>
    highlighled &&
    `
    border-top: 7px solid ${theme.colors.UIOrange};
  `}
`;

interface ILocationSection {
  location: {
    continent: string;
    country: string;
    state: string;
    city: string;
  };
}

const LocationSection = ({ location }: ILocationSection) => (
  <SectionTemplate>
    <SectionTitle title="Job location" />

    <JobLocationInfo location={location} />
  </SectionTemplate>
);

interface IInformationSection {
  damageType: string;
  workPlace: string;
  startDate: string;
  startTime: string;
}

const InformationSection = ({
  damageType,
  workPlace,
  startDate,
  startTime,
}: IInformationSection) => {
  const { t } = useTranslation();

  return (
    <SectionTemplate>
      <SectionTitle title="Job information" />

      <Box
        flexDirection={['column', 'row']}
        flexBasis="1 1 220px"
        justifyContent="space-between"
      >
        <Box flexDirection="column">
          <JobInfoCell
            title="Damage type"
            value={t(capitalizeFirstLetter(damageType)) as string}
          />

          <JobInfoCell title="Work place" value={t(workPlace) as string} />
        </Box>

        <Box flexDirection="column">
          <JobInfoCell title="Start date" value={formatDate(startDate)} />

          {startTime && <JobInfoCell title="Start time" value={startTime} />}
        </Box>
      </Box>
    </SectionTemplate>
  );
};

interface IPaymentSection {
  pricingType: string;
  paymentType: string;
  paymentValues: {
    [key: string]: string | number;
    price_from_car: number;
    price_by_agreement: string;
    price_from_percentage: number;
  };
}

const PaymentSection = ({ pricingType, paymentType, paymentValues }: IPaymentSection) => (
  <Fragment>
    <SectionTitle title="Payment information" />

    <JobPaymentSection
      pricingType={pricingType}
      paymentType={paymentType}
      paymentValues={paymentValues}
    />
  </Fragment>
);
