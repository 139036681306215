import { createEffect, createStore, forward } from 'effector';
import { createGate } from 'effector-react';

import { getJobById } from 'api/jobs';
import { IError, IResponse, IJob } from 'lib/types';
import { createFetching } from 'lib/fetching';

export const JobGate = createGate<{ id: string }>();

export const $job = createStore<IJob | null>(null);
const jobLoadingEffect = createEffect<{ id: string }, IResponse<IJob>, IError>();
export const jobFetching = createFetching(jobLoadingEffect);

jobLoadingEffect.use(x => getJobById(x.id));

$job.on(jobLoadingEffect.done, (_, { result }) => result.data);

forward({
  from: JobGate.open,
  to: jobLoadingEffect,
});
