import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'ui';
import { IUser } from 'lib/types';
import { InfoCell } from './info-cell';
import { InfoTitle } from './info-title';
import { ProfileInfoTemplate } from '../templates/profile-info-template';
import { capitalizeFirstLetter } from 'lib/react-select-format-array';
import { LANGUAGE_OPTIONS } from 'features/common';

export const ContactInfo = ({ profile }: { profile: IUser }) => {
  const { t } = useTranslation();
  const { email, phone, viber, telegram, whatsapp, languages, contact_method } = profile;
  const contactMethod = contact_method.map(x => t(capitalizeFirstLetter(x))).join(', ');
  const languagesLabel = languages
    .map(x => {
      const languageOption = LANGUAGE_OPTIONS.find(option => option.value === x);

      return t(capitalizeFirstLetter(languageOption?.label || ''));
    })
    .join(', ');

  return (
    <ProfileInfoTemplate
      styles={{ mb: '40px' }}
      title={<InfoTitle title="Contact" />}
      info={
        <Fragment>
          <InfoCell label="Language of communication" value={languagesLabel} />

          <InfoCell label="Contact method" value={contactMethod} />

          <InfoCell label="Email" value={<Link href={`mailto:${email}`}>{email}</Link>} />

          {phone && (
            <InfoCell label="Phone" value={<Link href={`tel:${phone}`}>{phone}</Link>} />
          )}

          {viber && (
            <InfoCell label="Viber" value={<Link href={`tel:${viber}`}>{viber}</Link>} />
          )}

          {whatsapp && (
            <InfoCell
              label="Whatsapp"
              value={<Link href={`tel:${whatsapp}`}>{whatsapp}</Link>}
            />
          )}

          {telegram && (
            <InfoCell
              label="Telegram"
              value={<Link href={`tel:${telegram}`}>{telegram}</Link>}
            />
          )}
        </Fragment>
      }
    />
  );
};

const Link = styled.a`
  color: ${theme.colors.UIBlue};
`;
