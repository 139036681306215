import { ISearchConfig } from 'pages/search-job/model';
import { ISearchFormState } from '../../components/search-bar';
import { removeEmptyFieldsInObject } from 'lib/remove-blank-fields-in-object';
import { convertArrayToSelectOptions } from 'lib/react-select-format-array';
import { CONTINENT_OPTIONS, IContinentOption } from 'features/common';

// FUNCTION FOR SEARCH API DTO
export const generateDataForRegionSearch = (values: ISearchFormState) => {
  const continents = values.continents.map(x => x.value);
  const countries = values.countries && values.countries.map(x => x.value);
  const states = values.states && values.states.map(x => x.value);
  const sort_by = values.sort_by ? values.sort_by : 0;
  const regionData = Object.assign({}, values, {
    continents,
    countries,
    states,
    sort_by,
  });
  return removeEmptyFieldsInObject<ISearchConfig>(regionData);
};

export const generateDataForCitySearch = (values: ISearchFormState) => {
  const cityData = Object.assign({}, values, {
    continent: '',
    state: '',
    country: '',
    city: values.city as string,
    continents: [values.continent] as string[],
    states: [values.state] as string[],
    countries: [values.country] as string[],
    sort_by: values.sort_by ? values.sort_by : 0,
  });

  return removeEmptyFieldsInObject<ISearchConfig>(cityData);
};

// FUNCTIONS FOR UI DATA STRUCTURE
export const generateAddressDataForRegionType = (searchConfig: ISearchConfig) => {
  const { states, countries, continents } = searchConfig;
  const statesForState = states ? convertArrayToSelectOptions(states) : [];
  const countriesForState = countries ? convertArrayToSelectOptions(countries) : [];
  const continentsForState = continents
    ? (continents!.map(continent =>
        CONTINENT_OPTIONS.find(option => option.value === continent),
      ) as IContinentOption[])
    : [];

  return {
    states: statesForState,
    countries: countriesForState,
    continents: continentsForState,
  };
};
