import { request } from 'lib/request';

const getProposals = (_: string, status: number) =>
  request({ method: 'GET', url: '/user/proposals', params: { status } });

const getProposal = (_: string, id: string, status: string) =>
  request({ method: 'GET', url: `/user/job/${id}/proposals`, params: { status } });

const declineProposal = (id: string) =>
  request({ method: 'POST', url: `/user/proposals/${id}/cancel` });

const confirmProposal = (id: string) =>
  request({ method: 'POST', url: `/user/proposals/${id}/confirm` });

const cancelProposal = (id: string) =>
  request({ method: 'POST', url: `/user/proposals/${id}/cancel` });

const getProposalsStatistic = (_: string) =>
  request({ method: 'GET', url: `/user/proposals/statistics` });

const declineProposals = (id: string) =>
  request({ method: 'DELETE', url: `/user/job/${id}/proposals/declineAll` });

export {
  getProposal,
  getProposals,
  declineProposal,
  confirmProposal,
  cancelProposal,
  declineProposals,
  getProposalsStatistic,
};
