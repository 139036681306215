import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';

import { IUser } from 'lib/types';
import { ProfileDTO } from 'api/profile';
import { SocialInput } from './social-input';
import { OnboardingCardTemplate } from '../templates/onboarding-card-template';
import { removeEmptyFieldsInObject } from 'lib/remove-blank-fields-in-object';
import { CONTACT_METHOD_OPTIONS, LANGUAGE_OPTIONS } from 'features/common';
import { Box, Form, Input, IOption, PrimaryButton, RSelect, Text } from 'ui';
import {
  convertArrayToSelectOptions,
  convertSelectOptionsToArray,
} from 'lib/react-select-format-array';
import {
  STEP_INDEXES,
  $onboardingData,
  changeCurrentStep,
  invokeProfileCreation,
  populateProfileData,
} from '../model';

const ContactInfoLayout = ({
  status,
  values,
  errors,
  touched,
  handleBlur,
  handleSubmit,
  handleChange,
  setFieldValue,
}: FormikProps<IContactInfoFormState>) => {
  const { t } = useTranslation();
  const { phone } = values;
  const [hint, setHint] = useState(phone || '');

  useEffect(() => setHint(phone), [phone]);

  const handleHintClick = (field: string) => setFieldValue(field, phone);

  return (
    <OnboardingCardTemplate title={t('Contact info')}>
      <Form onSubmit={handleSubmit}>
        <RSelect
          id="languages"
          name="languages"
          label="Language of communication"
          error={touched.languages && errors.languages}
          value={values.languages}
          isMulti={true}
          options={LANGUAGE_OPTIONS}
          onBlur={handleBlur}
          onChange={(option: any) => setFieldValue('languages', option)}
          required={true}
          getOptionLabel={x => t(x.label)}
        />

        <RSelect
          id="contact_method"
          name="contact_method"
          label="Contact method"
          error={touched.contact_method && errors.contact_method}
          value={values.contact_method}
          isMulti={true}
          options={CONTACT_METHOD_OPTIONS}
          onBlur={handleBlur}
          onChange={(option: any) => setFieldValue('contact_method', option)}
          required={true}
          getOptionLabel={x => t(x.label)}
        />

        <Input
          id="phone"
          name="phone"
          type="text"
          label="Phone"
          error={touched.phone && errors.phone}
          value={values.phone}
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
        />

        <SocialInput
          id="viber"
          name="viber"
          type="text"
          hint={hint}
          label="Viber"
          value={values.viber}
          onBlur={handleBlur}
          onChange={handleChange}
          onHintClick={() => handleHintClick('viber')}
        />

        <SocialInput
          id="whatsapp"
          name="whatsapp"
          type="text"
          hint={hint}
          label="Whatsapp"
          value={values.whatsapp}
          onBlur={handleBlur}
          onChange={handleChange}
          onHintClick={() => handleHintClick('whatsapp')}
        />

        <SocialInput
          id="telegram"
          name="telegram"
          type="text"
          hint={hint}
          label="Telegram"
          value={values.telegram}
          onBlur={handleBlur}
          onChange={handleChange}
          onHintClick={() => handleHintClick('telegram')}
        />

        <Box
          width={1}
          position="absolute"
          bottom="-100px"
          left="50%"
          alignItems="center"
          justifyContent="space-between"
          style={{ transform: 'translateX(-50%)' }}
        >
          <PrimaryButton
            type="button"
            onClick={() => {
              populateProfileData(values);
              changeCurrentStep(STEP_INDEXES.ADDRESS_INFO);
            }}
          >
            {t('Back')}
          </PrimaryButton>

          <PrimaryButton type="submit">{t('Finish')}</PrimaryButton>
        </Box>
      </Form>

      {status && (
        <Text fontSize="12px" textAlign="center" my={3} color="UIRed">
          {t(status)}
        </Text>
      )}
    </OnboardingCardTemplate>
  );
};

export const ContactInfo = withFormik({
  mapPropsToValues: ({ profile }: { profile: IUser }): IContactInfoFormState => ({
    viber: profile?.viber || '',
    phone: profile?.phone || '',
    whatsapp: profile?.whatsapp || '',
    telegram: profile?.telegram || '',
    languages: convertArrayToSelectOptions(profile?.languages) || [],
    contact_method: convertArrayToSelectOptions(profile?.contact_method) || [],
  }),

  validationSchema: Yup.object().shape({
    phone: Yup.string().required('Phone field is required'),
    viber: Yup.string(),
    whatsapp: Yup.string(),
    telegram: Yup.string(),
    languages: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.mixed().default() }))
      .required('Language of communication field is required'),
    contact_method: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.mixed().default() }))
      .required('Contact method field is required'),
  }),

  handleSubmit: (values: IContactInfoFormState, fb) => {
    const onboardingData = $onboardingData.getState();
    const socialContactMethods = values.contact_method
      .map(x => x.value)
      .filter(x => x !== 'email' && x !== 'phone');
    const isValidSocialContactMethods = socialContactMethods.reduce(
      (_, x) => !!values[x],
      true,
    );
    const profileDTO = removeEmptyFieldsInObject<ProfileDTO>(
      Object.assign({}, onboardingData, values, {
        languages: convertSelectOptionsToArray(values.languages),
        contact_method: convertSelectOptionsToArray(values.contact_method),
      }),
    );

    isValidSocialContactMethods
      ? invokeProfileCreation(profileDTO)
      : fb.setStatus('Please fill choosed contact methods');
  },
})(ContactInfoLayout);

export interface IContactInfoFormState {
  [key: string]: string | IOption[];
  viber: string;
  phone: string;
  whatsapp: string;
  telegram: string;
  languages: IOption[];
  contact_method: IOption[];
}
