import { createStore, createEvent, createEffect, forward } from 'effector';

import { history } from 'lib/routing';
import { createFetching } from 'lib/fetching';
import { INewJobFormState } from './components/new-edit-job';
import { IError, IResponse, IJob } from 'lib/types';
import {
  createJob,
  JobCreationDTO,
  ListJobsDTO,
  deleteJob,
  uploadPicture,
  deleteJobPicture as deleteJobPictureRequest,
} from 'api/jobs';
import { $job } from 'pages/job/model';

export const pageUnmounted = createEvent();

// JOB PICTURES MODEL
export const $jobPictures = createStore<File[]>([]);
export const setJobPicture = createEvent<File>();
export const deleteJobPicture = createEvent<number>();

$jobPictures.on(setJobPicture, (state, payload) => [...state, payload]);

$jobPictures.on(deleteJobPicture, (state, payload) => {
  const copy = [...state];

  copy.splice(payload, 1);

  return copy;
});

$jobPictures.reset(pageUnmounted);

// JOB CREATION MODEL
export const $apiError = createStore<string>('');
export const invokeJobCreation = createEvent<INewJobFormState>();
export const jobCreationEffect = createEffect<
  JobCreationDTO,
  IResponse<ListJobsDTO>,
  IError
>();
export const jobCreationFetching = createFetching(jobCreationEffect, 'initial', {
  reset: pageUnmounted,
});

jobCreationEffect.use(x => createJob(x));

invokeJobCreation.watch(formState => {
  const pictures = $jobPictures.getState();

  const newJob = pictures.length
    ? Object.assign({}, formState, { 'pictures[]': pictures })
    : formState;

  jobCreationEffect(newJob);
});

$apiError
  .on(jobCreationEffect.fail, (state, { params, error }) => error.message)
  .reset(pageUnmounted);

// DELETE JOB MODEL
export const invokeDeleteJob = createEvent<number>();
const deleteJobEffect = createEffect<number, IResponse<ListJobsDTO>, IError>();

deleteJobEffect.use(x => deleteJob(x));

deleteJobEffect.done.watch(_ => history.push('/jobs'));

forward({
  from: invokeDeleteJob,
  to: deleteJobEffect,
});

// UPLOAD JOB PICTURE
export const $uploadingPictureApiError = createStore('');
export const invokeJobPictureUpload = createEvent<{ id: number; picture: File }>();
const jobPictureUpload = createEffect<
  { id: number; picture: File },
  IResponse<IJob>,
  IError
>();

jobPictureUpload.use(x => uploadPicture(x.id, x.picture));

$job.on(jobPictureUpload.done, (_, { result }) => result.data);

$uploadingPictureApiError
  .on(jobPictureUpload.fail, (_, { error }) => error.message)
  .reset(pageUnmounted);

forward({
  from: invokeJobPictureUpload,
  to: jobPictureUpload,
});

// DELETE JOB PICTURE
export const invokeJobPictureDelete = createEvent<{ id: number; pictureURL: string }>();
const jobPictureDelete = createEffect<
  { id: number; pictureURL: string },
  IResponse<IJob>,
  IError
>();

jobPictureDelete.use(x => deleteJobPictureRequest(x.id, x.pictureURL));

$job.on(jobPictureDelete.done, (_, { result }) => result.data);

forward({
  from: invokeJobPictureDelete,
  to: jobPictureDelete,
});
