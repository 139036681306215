import styled from 'styled-components';
import { theme } from 'ui';

export const Button = styled.button`
  font-weight: bold;

  padding: 10px 16px;

  border: 1px solid ${theme.colors.UIWhite};
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

export const PrimaryButton = styled(Button)`
  color: ${theme.colors.UIWhite};
  background-color: ${theme.colors.UIBlue};

  transition: all 0.1s linear;

  :hover {
    border-color: ${theme.colors.UIBlue};
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${theme.colors.UIDarkGray};
  border: 1px solid ${theme.colors.UIGray};
`;

export const WarnButton = styled(Button)`
  color: ${theme.colors.UIWhite};
  background-color: ${theme.colors.UIRed};

  :hover {
    border-color: ${theme.colors.UIRed};
  }
`;
