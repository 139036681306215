import React, { Fragment } from 'react';
import { useStore } from 'effector-react';

import { SearchesTable } from 'features/search';
import { DashboardTemplate, Box } from 'ui';
import { Header, PageLoader, EmptyEntityCard } from 'features/common';
import {
  $searches,
  SearchesGate,
  deleteSearchFetching,
  searchesLoadingFetching,
} from './model';

export const SearchesPage = () => {
  const searches = useStore($searches);
  const isLoading = useStore(searchesLoadingFetching.isLoading);
  const isDeleting = useStore(deleteSearchFetching.isLoading);
  const isSearchesExists = !!searches.length;

  return (
    <DashboardTemplate
      render={profile => (
        <Fragment>
          <SearchesGate />

          {isSearchesExists && <SearchesTable searches={searches} />}

          {!isSearchesExists && (
            <Box height="calc(100vh - 220px)" alignItems="center">
              <EmptyEntityCard
                route="/jobs/search"
                waringText="You haven't saved searches yet."
                warningButtonText="Search"
              />
            </Box>
          )}

          {(isLoading || isDeleting) && <PageLoader />}
        </Fragment>
      )}
      renderHeader={profile => <Header profile={profile} />}
    />
  );
};
