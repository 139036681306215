import React from 'react';
import { useStore } from 'effector-react';

import { useQuery } from 'react-query';
import { getProfile } from 'api/profile';
import { PageLoader } from 'features/common';
import { IResponse, IUser } from 'lib/types';
import { $steps, $currentStep } from 'features/onboarding';
import { Box, LanguageSelectorContentTemplate } from 'ui';

export const OnboardingPage = () => {
  const steps = useStore($steps);
  const currentStep = useStore($currentStep);
  const OnboardingCurrentComponent = steps[currentStep].component;
  const { data, isLoading } = useQuery<IResponse<{ user: IUser }>>(
    ['profile'],
    getProfile,
  );

  return (
    <LanguageSelectorContentTemplate>
      <Box
        width={['100%', '400px']}
        px={['10px', '0']}
        flexDirection="column"
        alignItems="center"
      >
        {data && <OnboardingCurrentComponent profile={data.data.user} />}

        {isLoading && <PageLoader />}
      </Box>
    </LanguageSelectorContentTemplate>
  );
};
