import React from 'react';
import { Redirect } from 'react-router';

import { Route } from 'lib/guards';

type RequiredContext = {
  isAuthenticated: boolean;
};

/**
 * Pass if user authenticated
 * Else remove route or redirect
 */
export const onlyAuth = ({ redirect }: { redirect?: string } = {}) => (
  route: Route<RequiredContext>,
  context: RequiredContext,
) => {
  if (context.isAuthenticated) {
    return route;
  } else {
    return redirect ? { ...route, component: () => <Redirect to={redirect} /> } : null;
  }
};

/**
 * Pass if user anonymous
 * Else remove route or redirect
 */
export const onlyAnon = ({ redirect }: { redirect?: string } = {}) => (
  route: Route<RequiredContext>,
  context: RequiredContext,
) => {
  if (context.isAuthenticated) {
    return redirect ? { ...route, component: () => <Redirect to={redirect} /> } : null;
  } else {
    return route;
  }
};
