import React, { ReactNode } from 'react';

import { SuccessCard } from 'features/common';
import { Box, LanguageSelectorContentTemplate } from 'ui';

interface IAuthTemplate {
  card: ReactNode;
  error?: ReactNode;
  success?: { title: string; route: string };
}

export const AuthTemplate = ({ card, error, success }: IAuthTemplate) => (
  <LanguageSelectorContentTemplate>
    <Box
      width={['100%', '400px']}
      px={['10px', '0']}
      flexDirection="column"
      alignItems="center"
    >
      {success ? <SuccessCard {...success} /> : card}

      <Box mt={3}>{error}</Box>
    </Box>
  </LanguageSelectorContentTemplate>
);
