import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';

import { IUser } from 'lib/types';
import { AddressForm } from 'features/common';
import { OnboardingCardTemplate } from '../templates/onboarding-card-template';
import { Box, Form, PrimaryButton } from 'ui';
import { changeCurrentStep, STEP_INDEXES, populateProfileData } from '../model';

const validationSchema = Yup.object().shape({
  city: Yup.string().required('City field is required'),
  state: Yup.string().required('State field is required'),
  address: Yup.string().required('Address field is required'),
  country: Yup.string().required('Country field is required'),
  continent: Yup.string().required('Continent field is required'),
  postal_code: Yup.number().required('Postal code field is required'),
});

const AddressInfoLayout = ({
  values,
  errors,
  touched,
  handleBlur,
  handleSubmit,
  handleChange,
  setFieldValue,
}: FormikProps<IAddressInfoFormState>) => {
  const { t } = useTranslation();

  return (
    <OnboardingCardTemplate title={t('Address info')}>
      <Form onSubmit={handleSubmit}>
        <AddressForm
          values={{
            country: values.country,
            address: values.address,
            city: values.city,
            continent: values.continent,
            state: values.state,
            postal_code: values.postal_code,
          }}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          validationSchema={validationSchema.fields}
        />

        <Box
          width={1}
          position="absolute"
          bottom="-100px"
          left="50%"
          alignItems="center"
          justifyContent="space-between"
          style={{ transform: 'translateX(-50%)' }}
        >
          <PrimaryButton
            type="button"
            onClick={() => changeCurrentStep(STEP_INDEXES.GENERAL_INFO)}
          >
            {t('Back')}
          </PrimaryButton>

          <PrimaryButton type="submit">{t('Next')}</PrimaryButton>
        </Box>
      </Form>
    </OnboardingCardTemplate>
  );
};

export const AddressInfo = withFormik({
  mapPropsToValues: ({ profile }: { profile: IUser }): IAddressInfoFormState => ({
    city: profile?.city || '',
    state: profile?.state || '',
    address: profile?.address || '',
    country: profile?.country || '',
    continent: profile?.continent || '',
    postal_code: profile?.postal_code || '',
  }),

  validationSchema,

  handleSubmit: (values: IAddressInfoFormState) => {
    populateProfileData(values);
    changeCurrentStep(STEP_INDEXES.CONTACT_INFO);
  },
})(AddressInfoLayout);

export interface IAddressInfoFormState {
  city: string;
  state: string;
  address: string;
  country: string;
  continent: string;
  postal_code: string;
}
