import React from 'react';
import { useQuery } from 'react-query';

import { JobCard } from 'features/jobs';
import { history } from 'lib/routing';
import { ProfileGate } from 'pages/profile';
import { getProposals } from 'api/proposals';
import { IResponse, IJob } from 'lib/types';
import { Box, DashboardTemplate } from 'ui';
import { Header, PageLoader, HiringNavigation } from 'features/common';
import {
  PageHint,
  getCurrentPageByPathname,
  customerHiringNavigationItems,
} from 'features/hiring';

export const ProposalsPage = () => {
  const { pathname } = history.location;
  const currentPage = getCurrentPageByPathname(pathname, customerHiringNavigationItems);
  const { data, isLoading } = useQuery<IResponse<IJob[]>>(
    ['proposals', currentPage?.status],
    getProposals,
  );

  return (
    <DashboardTemplate
      render={profile => (
        <Box width={['100%', '850px', '850px', '850px']} flexDirection="column">
          <ProfileGate />

          <HiringNavigation navItems={customerHiringNavigationItems} isProposals={true} />

          {currentPage && (
            <PageHint title={currentPage.label} description={currentPage.hint} />
          )}

          <Box m="0 auto" width={['100%', '510px']} flexDirection="column">
            {data &&
              Boolean(data.data.length) &&
              data.data.map(x => (
                <JobCard
                  key={x.id}
                  job={x}
                  jobRoute={`/hiring/proposals/${x.id}/${currentPage?.status}`}
                  applicationsQty={(x.job_applies as unknown) as number}
                  activeApplicationsQty={x.client_new}
                />
              ))}
          </Box>

          {isLoading && <PageLoader />}
        </Box>
      )}
      renderHeader={profile => <Header profile={profile} />}
    />
  );
};
