import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  ColorProps,
} from 'styled-system';

export const Box = styled.div<
  BorderProps &
    SpaceProps &
    FlexboxProps &
    LayoutProps &
    ColorProps &
    PositionProps & { isPointer?: boolean }
>`
  display: flex;

  ${color}
  ${space}
  ${border}
  ${layout}
  ${flexbox}
  ${position}

  ${({ isPointer }) =>
    isPointer &&
    `
      cursor: pointer;
    `}
`;
