import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from 'ui';
import { JobInfoCell } from './job-info-cell';
import { PRICING_TYPES } from '../lib/constants';
import { PAYMENT_TYPES } from 'features/common';
import { capitalizeFirstLetter } from 'lib/react-select-format-array';

interface IPaymentSection {
  pricingType: string;
  paymentType: string;
  paymentValues: {
    [key: string]: string | number;
    price_from_car: number;
    price_by_agreement: string;
    price_from_percentage: number;
  };
}

export const JobPaymentSection = ({
  pricingType,
  paymentType,
  paymentValues,
}: IPaymentSection) => {
  const { t } = useTranslation();
  const { BY_AGREEMENT } = PRICING_TYPES;
  const pricingTypeItem = Object.values(PRICING_TYPES).find(x => x.value === pricingType);
  const paymentTypeLabel = Object.values(PAYMENT_TYPES).find(
    x => x.value === paymentType,
  );

  const pricingTypeLabel =
    pricingTypeItem && pricingTypeItem.value === BY_AGREEMENT.value
      ? BY_AGREEMENT.label
      : pricingTypeItem
      ? `${paymentValues[pricingTypeItem.fieldName]} ${pricingTypeItem.fieldSign}`
      : undefined;

  return (
    <Box flexDirection="column" flexBasis="1 1 220px" justifyContent="space-between">
      <Box>
        <JobInfoCell
          title="Payment type"
          value={t(capitalizeFirstLetter(paymentTypeLabel?.label || '')) as string}
        />
      </Box>

      {pricingTypeLabel && (
        <Box>
          <JobInfoCell title="Pricing" value={t(pricingTypeLabel) as string} />
        </Box>
      )}
    </Box>
  );
};
