import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IApply } from 'lib/types/apply';
import { formatDate } from 'lib/format-date';
import { JobUserInfo, PRICING_TYPES } from 'features/jobs';
import { Box, Text, theme } from 'ui';

type ApplicationCardProps = {
  children: ReactNode;
  jobApply: IApply;
};

export const ApplicationCard = ({ children, jobApply }: ApplicationCardProps) => {
  const { t } = useTranslation();
  const {
    final_price,
    created_at,
    cover_letter,
    by_percent,
    by_agreement,
    init_price,
    worker,
  } = jobApply;

  const PricingLabel = (
    <Text color="UIBlue" fontWeight="bold">
      {t('Pricing')}:&nbsp;
    </Text>
  );

  const ProposedLabel = (
    <Text color="UIBlue" fontWeight="bold">
      {t('Proposed terms')}:&nbsp;
    </Text>
  );

  return (
    <Box mb="40px" flexDirection="column">
      <Box
        p={['20px', '20px 40px']}
        mb="30px"
        mx={['10px', '10px', '0px']}
        style={{ boxShadow: '0px 4px 59px rgba(0, 0, 0, 0.14)' }}
        width={['100%', '100%', '550px', '650px']}
        position="relative"
        flexDirection="column"
      >
        <JobUserInfo
          user={worker}
          postedLabel={t('Applied date')}
          postedDate={formatDate(created_at)}
        />

        {Boolean(by_agreement) && (
          <Box pb="15px">
            {PricingLabel}

            <Text fontWeight="bold">
              <Text fontWeight="bold">{t('By agreement')}</Text>
            </Text>
          </Box>
        )}

        {Boolean(by_percent) && (
          <Box>
            <Box pb="15px" mr="70px">
              {PricingLabel}

              <Text fontWeight="bold">
                {init_price} {PRICING_TYPES.FROM_ASSESSMENT.fieldSign}
              </Text>
            </Box>

            {init_price !== final_price && (
              <Box pb="15px">
                {ProposedLabel}

                <Text fontWeight="bold">
                  {final_price} {PRICING_TYPES.FROM_ASSESSMENT.fieldSign}
                </Text>
              </Box>
            )}
          </Box>
        )}

        {!Boolean(by_agreement) && !Boolean(by_percent) && (
          <Box>
            <Box pb="15px" mr="70px">
              {PricingLabel}

              <Text fontWeight="bold">
                {init_price} {PRICING_TYPES.FOR_CAR.fieldSign}
              </Text>
            </Box>

            {init_price !== final_price && (
              <Box pb="15px">
                {ProposedLabel}

                <Text fontWeight="bold">
                  {final_price} {PRICING_TYPES.FOR_CAR.fieldSign}
                </Text>
              </Box>
            )}
          </Box>
        )}

        <Divider />

        <Box flexDirection="column">
          <Text color="UIBlue" fontWeight="bold" mb={2}>
            {t('Cover letter')}:
          </Text>

          <Text style={{ wordWrap: 'break-word' }}>{cover_letter}</Text>
        </Box>
      </Box>

      <Box justifyContent="center">{children}</Box>
    </Box>
  );
};

export const Divider = styled.div`
  height: 1px;
  margin: 0 -40px;
  margin-bottom: 15px;

  background-color: ${theme.colors.UIGray};

  @media screen and (max-width: 576px) {
    margin: 0 -20px;
    margin-bottom: 15px;
  }
`;
