export const dropdownMenus = [
  {
    title: {
      label: 'Post job',
      field: 'proposals',
    },
    links: [
      { title: 'Post your job', route: '/jobs/new', isProposalOrApplyLink: false },
      { title: 'My posted jobs', route: '/jobs', isProposalOrApplyLink: false },
      { title: 'My proposals', route: '/hiring/proposals', isProposalOrApplyLink: true },
    ],
  },
  {
    title: {
      label: 'Find job',
      field: 'applies',
    },
    links: [
      { title: 'Search job', route: '/jobs/search', isProposalOrApplyLink: false },
      { title: 'My saved searches', route: '/searches', isProposalOrApplyLink: false },
      {
        title: 'My applied jobs',
        route: '/hiring/applies',
        isProposalOrApplyLink: true,
      },
    ],
  },
];
