import React, { useState, useRef, Dispatch } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';

import { IUser } from 'lib/types';
import { Avatar } from './avatar';
import { history } from 'lib/routing';
import { tokenDropped } from 'lib/token';
import { useOutsideClick } from '../lib/detect-outside-click';
import { Box, Image, Text, theme } from 'ui';

export const UserMenu = ({ profile }: { profile: IUser }) => {
  const [isVisibleUserMenu, setVisibleUserMenu] = useState(false);

  return (
    <Box position="relative">
      <Avatar
        avatar={profile.avatar}
        username={profile.username}
        handleAvatarClick={() => setVisibleUserMenu(true)}
      />

      {isVisibleUserMenu && (
        <Menu profile={profile} setVisibleUserMenu={setVisibleUserMenu} />
      )}
    </Box>
  );
};

const Menu = ({
  profile,
  setVisibleUserMenu,
}: {
  profile: IUser;
  setVisibleUserMenu: Dispatch<boolean>;
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  useOutsideClick(ref, setVisibleUserMenu);

  return (
    <Box
      py={2}
      ref={ref}
      width="220px"
      position="absolute"
      top="58px"
      zIndex={9999}
      right="calc(100% - 32px)"
      flexDirection="column"
      backgroundColor="UIWhite"
      style={{
        boxShadow: '0px 4px 59px rgba(0, 0, 0, 0.14)',
      }}
    >
      <UserInfo
        avatar={profile.avatar}
        userName={profile.username}
        userRole={profile.user_role}
      />

      <MenuItem
        pl={3}
        py={2}
        alignItems="center"
        onClick={() => {
          history.push('/profile');
          setVisibleUserMenu(false);
        }}
        isPointer={true}
      >
        <Image src={theme.images.profileIcon} />

        <Text ml={2}>{t('Profile')}</Text>
      </MenuItem>

      <MenuItem
        pl={3}
        py={2}
        alignItems="center"
        onClick={() => tokenDropped()}
        isPointer={true}
      >
        <Image src={theme.images.logoutIcon} />

        <Text ml={2}>{t('Logout')}</Text>
      </MenuItem>
    </Box>
  );
};

type IUserInfo = {
  avatar: string;
  userName: string;
  userRole: string;
};

const UserInfo = ({ avatar, userName, userRole }: IUserInfo) => {
  return (
    <Box alignItems="center" mb={3} pt={2} pl={3}>
      <Avatar avatar={avatar} username={userName} handleAvatarClick={() => {}} />

      <Box flexDirection="column" pl={2}>
        <Text fontWeight="bold">{userName}</Text>

        <Text color="UIDarkGray" fontSize="14px">
          {userRole}
        </Text>
      </Box>
    </Box>
  );
};

const MenuItem = styled(Box)`
  :hover {
    background-color: ${lighten(0.16, theme.colors.UIGray)};
  }
`;
