import { IJob } from 'lib/types';
import { request } from 'lib/request';
import { ISearchConfig } from 'pages/search-job/model';
import { INewJobFormState } from 'features/jobs';
import { IPaginationConfig } from 'pages/jobs/model';

export interface ListJobsDTO {
  data: IJob[];
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: string;
  prev_page_url: string;
  to: number;
  total: number;
  current_page: number;
  first_page_url: string;
}

const getPostedJobs = (x: IPaginationConfig) =>
  request({
    method: 'GET',
    url: '/user/job',
    params: { page: x.current_page, per_page: x.per_page },
  });

export interface JobCreationDTO extends INewJobFormState {
  'pictures[]'?: File[];
  [key: string]: any;
}

const createJob = (body: JobCreationDTO) => {
  const data = new FormData();
  const picturesKey = 'pictures[]';
  const pictures = body[picturesKey];

  Object.keys(body).forEach(key =>
    key === picturesKey
      ? pictures?.forEach(x => data.append(picturesKey, x))
      : data.append(key, body[key]),
  );

  return request({ method: 'POST', url: '/user/job', data });
};

const searchJobs = (data: ISearchConfig) =>
  request({ method: 'POST', url: '/job/search', data });

export type GetAllSearchesDTO = ISearchConfig & {
  id: number;
  updated_at: string;
  user_id: number;
};

const getAllJobSearches = () => request({ method: 'GET', url: '/job/searches' });

const deleteJobSearch = (id: string) =>
  request({ method: 'DELETE', url: `job/search/${id}` });

const getJobById = (id: string) =>
  request({
    method: 'GET',
    url: `/job/${id}`,
  });

const deleteJob = (id: number) => request({ method: 'DELETE', url: `/user/job/${id}` });

const updateJob = (data: IJob) => {
  const { pictures, ...rest } = data;

  return request({ method: 'PUT', url: `/user/job/${rest.id}`, data: rest });
};

const uploadPicture = (id: number, picture: File) => {
  const data = new FormData();

  data.append('picture', picture);

  return request({ method: 'POST', url: `/user/job/${id}/picture`, data });
};

const deleteJobPicture = (id: number, pictureURL: string) =>
  request({
    method: 'DELETE',
    url: `/user/job/${id}/picture`,
    data: { picture: pictureURL },
  });

export {
  deleteJob,
  createJob,
  updateJob,
  searchJobs,
  getJobById,
  uploadPicture,
  getPostedJobs,
  deleteJobSearch,
  deleteJobPicture,
  getAllJobSearches,
};
