import { createEvent, createEffect, forward, createStore } from 'effector';

import { signIn } from 'api/auth';
import { IError } from 'lib/types';
import { history } from 'lib/routing';
import { tokenChanged } from 'lib/token';
import { ISignInFormState } from './page';

type SignInEffectType = ISignInFormState;

export const $signInError = createStore<IError | null>(null);
export const invokeSignIn = createEvent<SignInEffectType>();
export const signInEffect = createEffect<
  SignInEffectType,
  {
    access_token: string;
    token_type: string;
    expires_in: number;
    shouldRedirectToOnboarding: boolean;
  },
  IError
>();

signInEffect.use(x => signIn(x));

signInEffect.done.watch(
  ({ params, result: { access_token, shouldRedirectToOnboarding } }) => {
    const nextRoute = shouldRedirectToOnboarding ? '/onboarding' : '/';

    tokenChanged(access_token);
    history.push(nextRoute);
  },
);

$signInError.on(signInEffect.fail, (_, { error }) => error);

forward({
  from: invokeSignIn,
  to: signInEffect,
});
