import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Box, theme } from 'ui';

export const PageLoader = (): ReactElement => {
  return (
    <LoaderWrapper alignItems="center" justifyContent="center">
      <Spinner viewBox="0 0 50 50" color={theme.colors.UIBlue}>
        <Circle />
      </Spinner>
    </LoaderWrapper>
  );
};

export const SlimLoader = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}): ReactElement => {
  return (
    <Spinner
      color={theme.colors.UIBlue}
      width={width}
      height={height}
      viewBox="0 0 50 50"
    >
      <Circle />
    </Spinner>
  );
};

export const Spinner = styled.svg<{ color: string; width?: string; height?: string }>`
  width: ${({ width }) => width || '50px'};
  height: ${({ height }) => height || '50px'};

  animation: rotate 2s linear infinite;

  & .path {
    stroke: ${({ color }) => color};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const Circle = (): ReactElement => (
  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
);

const LoaderWrapper = styled(Box)`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;

  background-color: ${theme.colors.UIWhite};
`;
