import { createEvent, createEffect, forward, createStore } from 'effector';

import { signUp } from 'api/auth';
import { IError } from 'lib/types';
import { createFetching } from 'lib/fetching';
import { ISignUpFormState } from './page';
import { history } from 'lib/routing';

export const pageUnmounted = createEvent();
export const $signUpError = createStore<IError | null>(null);
export const invokeSignUp = createEvent<ISignUpFormState>();
export const signUpEffect = createEffect<
  ISignUpFormState,
  { status: number; message: string },
  IError
>();
export const signUpFetching = createFetching(signUpEffect, 'loading', {
  reset: pageUnmounted,
});

signUpEffect.use(x => signUp(x));

signUpEffect.done.watch(() => history.push('/signup-success'));

$signUpError
  .on(signUpEffect.fail, (_, { error }) => error)
  .reset(pageUnmounted, signUpEffect.done);

forward({
  from: invokeSignUp,
  to: signUpEffect,
});
