import styled from 'styled-components';
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  border,
  BorderProps,
  TypographyProps,
} from 'styled-system';

export const Text = styled.span<
  ColorProps & SpaceProps & TypographyProps & BorderProps & { isPointer?: boolean }
>`
  ${color}
  ${space}
  ${border}
  ${typography}

  ${({ isPointer }) =>
    isPointer &&
    `
      cursor: pointer;
    `}
`;
