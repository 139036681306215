import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, Form } from 'formik';

import { history } from 'lib/routing';
import { isNumber } from '../lib/is-number';
import { SearchType } from './search-type';
import { SearchRegion } from './search-region';
import { isRegionType } from '../lib/is-region-type';
import { windowScrollToTop } from 'lib/scroll-to-top';
import { setSearchConfig, ISearchConfig } from 'pages/search-job/model';
import { IContinentOption, AddressForm, ScrollToError } from 'features/common';
import {
  generateDataForCitySearch,
  generateDataForRegionSearch,
  generateAddressDataForRegionType,
} from '../lib/search-form-submition-helpers';
import {
  SEARCH_TYPE,
  DAMAGE_TYPES,
  SORT_BY_OPTIONS,
  READY_TO_WORK_OPTIONS,
  PAYMENT_TYPES_OPTIONS,
  HIGHLIGHT_LISTINGS_OPTIONS,
} from '../lib/constants';
import {
  Box,
  Text,
  RSelect,
  Checkbox,
  Input,
  PrimaryButton,
  SecondaryButton,
  theme,
} from 'ui';

export const SearchBarLayout = ({
  values,
  errors,
  touched,
  resetForm,
  handleBlur,
  handleSubmit,
  handleChange,
  isSubmitting,
  isValidating,
  setFieldValue,
  registerField,
  unregisterField,
}: FormikProps<ISearchFormState>) => {
  const { t } = useTranslation();

  return (
    <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Box
        width={['100%', '100%', '100%', '320px']}
        border="1px solid"
        borderColor="UIBlue"
        borderTop="0px"
        style={{ boxShadow: 'rgba(0, 0, 0, 0.14) 0px 4px 59px' }}
      >
        <Box flexDirection="column" width={1}>
          <SearchType
            values={values}
            resetForm={resetForm}
            isRegionType={isRegionType(values.type)}
            setFieldValue={setFieldValue}
            registerField={registerField}
            unregisterField={unregisterField}
          />

          {isRegionType(values.type) ? (
            <SearchRegion
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          ) : (
            <Box px="25px" flexDirection="column" mb={1}>
              <AddressForm
                values={{
                  country: values.country,
                  address: values.address,
                  city: values.city,
                  continent: values.continent,
                  state: values.state,
                  postal_code: values.postal_code,
                }}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                validationSchema={{
                  city: true,
                  state: true,
                  country: true,
                  continent: true,
                }}
                addressFieldHint="Please start your filling in with typing your address in an Address Field. For example: New York, USA"
              />

              <Input
                id="radius"
                name="radius"
                type="number"
                hint="Miles (1 mile = 1.6 kilometers)"
                label="Radius"
                error={touched.radius && errors.radius}
                value={values.radius || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                required={true}
              />
            </Box>
          )}

          <SectionTitle title="Job Options" />

          <Box px="25px" flexDirection="column" mb={1}>
            <RSelect
              id="damage_type"
              name="damage_type"
              label="Damage type"
              value={DAMAGE_TYPES.find(x => x.value === values.damage_type) || null}
              error={touched.damage_type && errors.damage_type}
              onBlur={handleBlur}
              options={DAMAGE_TYPES}
              onChange={(option: any) => setFieldValue('damage_type', option.value)}
              required={true}
            />
          </Box>

          <SectionTitle title="Date and Pricing Options" />

          <Box px="25px" flexDirection="column" mb={1}>
            <RSelect
              id="ready_to_work"
              name="ready_to_work"
              label="Ready to work within"
              error={touched.ready_to_work && errors.ready_to_work}
              value={
                READY_TO_WORK_OPTIONS.find(x => x.value === values.ready_to_work) || null
              }
              onBlur={handleBlur}
              options={READY_TO_WORK_OPTIONS}
              onChange={(option: any) => setFieldValue('ready_to_work', option.value)}
              required={true}
              getOptionLabel={x =>
                x.value === READY_TO_WORK_OPTIONS[0].value
                  ? t(x.label)
                  : `${x.value} ${t(x.label)}`
              }
            />

            <RSelect
              id="payment_type"
              name="payment_type"
              label="Payment type"
              error={touched.payment_type && errors.payment_type}
              value={
                PAYMENT_TYPES_OPTIONS.find(x => x.value === values.payment_type) || null
              }
              onBlur={handleBlur}
              options={PAYMENT_TYPES_OPTIONS}
              onChange={(option: any) => setFieldValue('payment_type', option.value)}
              required={true}
            />

            <Input
              id="min_pay_per_vehicle"
              name="min_pay_per_vehicle"
              type="number"
              label="Min pay per vehicle"
              error={touched.min_pay_per_vehicle && errors.min_pay_per_vehicle}
              value={values.min_pay_per_vehicle || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <Input
              id="min_percent_pay"
              name="min_percent_pay"
              type="number"
              label="Min percent pay"
              error={touched.min_percent_pay && errors.min_percent_pay}
              value={values.min_percent_pay || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <RSelect
              id="highlight_listings"
              name="highlight_listings"
              label="Highlight listings"
              error={touched.highlight_listings && errors.highlight_listings}
              value={
                HIGHLIGHT_LISTINGS_OPTIONS.find(
                  x => x.value === values.highlight_listings,
                ) || null
              }
              onBlur={handleBlur}
              options={HIGHLIGHT_LISTINGS_OPTIONS}
              onChange={(option: any) =>
                setFieldValue('highlight_listings', option.value)
              }
              getOptionLabel={x => `${x.value} ${t(x.label)}`}
            />

            <Box justifyContent="center" mt={2}>
              <Checkbox
                id="highlighted_on_top"
                name="highlighted_on_top"
                label="Highlighted on top"
                checked={Boolean(values.highlighted_on_top)}
                onChange={() =>
                  setFieldValue('highlighted_on_top', !values.highlighted_on_top)
                }
              />
            </Box>

            <RSelect
              id="sort_by"
              name="sort_by"
              label="Sort by"
              error={touched.sort_by && errors.sort_by}
              value={SORT_BY_OPTIONS.find(x => x.value === values.sort_by) || null}
              onBlur={handleBlur}
              options={SORT_BY_OPTIONS}
              onChange={(option: any) => setFieldValue('sort_by', option.value)}
            />
          </Box>
        </Box>
      </Box>

      <Box
        width={['100%', '100%', '100%', '320px']}
        flexDirection="column"
        mt={4}
        mb={[4, 4, 4, '0px']}
      >
        <PrimaryButton type="submit">{t('Search')}</PrimaryButton>

        <Box justifyContent="center" mt="15px">
          <Checkbox
            id="search_save"
            name="search_save"
            label="Save this search"
            checked={Boolean(values.search_save)}
            onChange={() => setFieldValue('search_save', !values.search_save)}
          />
        </Box>

        <SecondaryButton
          type="button"
          style={{ color: theme.colors.UIBlue, borderColor: theme.colors.UIBlue }}
          onClick={() => history.push('/searches')}
        >
          {t('My saved searches')}&nbsp;&nbsp;&gt;
        </SecondaryButton>
      </Box>

      <ScrollToError<ISearchFormState>
        errors={errors}
        isSubmitting={isSubmitting}
        isValidating={isValidating}
      />
    </Form>
  );
};

export type ISearchFormState = {
  type: number;
  continents: IContinentOption[];
  countries: Array<{ value: string; label: string }>;
  city?: string;
  states: Array<{ value: string; label: string }>;
  postal_code?: string;
  damage_type: string;
  ready_to_work: number | undefined;
  payment_type: string;
  min_pay_per_vehicle: number | undefined;
  min_percent_pay: number | undefined;
  highlight_listings: number | undefined;
  highlighted_on_top: boolean;
  sort_by: number | undefined;
  search_save: number;
  radius: number | undefined;
  country?: string;
  address?: string;
  continent: string;
  state?: string;
};

export const SearchBar = withFormik({
  mapPropsToValues: ({
    searchConfig,
  }: {
    searchConfig: ISearchConfig;
  }): ISearchFormState => {
    const {
      type,
      city,
      radius,
      states,
      sort_by,
      address,
      countries,
      continents,
      damage_type,
      postal_code,
      payment_type,
      ready_to_work,
      min_pay_per_vehicle,
      min_percent_pay,
      highlight_listings,
      highlighted_on_top,
    } = searchConfig;

    return {
      type: type || 0,
      continents: generateAddressDataForRegionType(searchConfig).continents,
      countries: generateAddressDataForRegionType(searchConfig).countries,
      states: generateAddressDataForRegionType(searchConfig).states,
      damage_type: damage_type || '',
      ready_to_work: isNumber(ready_to_work),
      payment_type: payment_type || '',
      min_pay_per_vehicle: isNumber(min_pay_per_vehicle),
      min_percent_pay: isNumber(min_percent_pay),
      highlight_listings: isNumber(highlight_listings),
      highlighted_on_top: highlighted_on_top || false,
      sort_by: isNumber(sort_by),
      search_save: 0,
      radius: isNumber(radius),
      state: (states && states![0]) || '',
      address: address || '',
      country: (countries && countries![0]) || '',
      city: city || '',
      continent: (continents && continents![0]) || '',
      postal_code: postal_code || '',
    };
  },

  validationSchema: Yup.object().shape({
    countries: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }),
      )
      .nullable(),
    continents: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
          code: Yup.string(),
        }),
      )
      .nullable(),
    damage_type: Yup.string().required('Damage type field is required'),
    ready_to_work: Yup.number().required('Ready to work field is required'),
    payment_type: Yup.string().required('Payment type field is required'),
  }),

  handleSubmit: (values: ISearchFormState, fb) => {
    windowScrollToTop();
    fb.setFieldValue('search_save', 0);

    setSearchConfig(
      values.type === SEARCH_TYPE.REGION
        ? generateDataForRegionSearch(values)
        : generateDataForCitySearch(values),
    );
  },
})(SearchBarLayout);

export const SectionTitle = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <Text
      mb="20px"
      pl="25px"
      fontSize="18px"
      fontWeight="bold"
      lineHeight="48px"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="UIBlue"
    >
      {t(title)}
    </Text>
  );
};
