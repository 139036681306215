import styled from 'styled-components';

import { Text } from 'ui/components';
import { theme } from 'ui/theme';

export const StatisticNumber = styled(Text)<{ isHighlighted: boolean }>`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${theme.colors.UIWhite};
  font-size: 14px;
  font-weight: bold;

  border-radius: 50%;
  background-color: ${theme.colors.UIDarkGray};

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    background-color: ${theme.colors.UIOrange};
  `}
`;
