import { request } from 'lib/request';
import {
  ISignUpState,
  ISignInState,
  IResetPasswordState,
  IEmailConfirmationState,
  IRequestResetPasswordState,
} from 'pages/auth';

const signUp = (body: ISignUpState) =>
  request({ method: 'POST', url: '/auth/register', data: body });

const signIn = (body: ISignInState) =>
  request({ method: 'POST', url: '/auth/login', data: body });

const resetPassword = (body: IResetPasswordState) =>
  request({ method: 'POST', url: '/auth/password/reset', data: body });

const requestResetPassword = (body: IRequestResetPasswordState) =>
  request({ method: 'POST', url: '/auth/password/reset/send', data: body });

const emailConfirmation = (body: IEmailConfirmationState) => {
  return request({ method: 'POST', url: '/auth/account/confirm', data: body });
};

export { signIn, signUp, resetPassword, emailConfirmation, requestResetPassword };
