import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';

import { AuthTemplate } from '../templates/auth';
import { H3, Card, Box, Form, Input, PrimaryButton, NavLink, BoxError, theme } from 'ui';
import {
  pageUnmounted,
  invokeRequestResetPassword,
  $requestResetPasswordError,
  requestResetPasswordFething,
} from './model';

export const RequestPasswordPage = () => {
  const { t } = useTranslation();
  const error = useStore($requestResetPasswordError);
  const isDone = useStore(requestResetPasswordFething.isDone);

  useEffect(() => () => pageUnmounted(), []);

  return (
    <AuthTemplate
      card={
        <Card>
          <RequestPasswordForm />
        </Card>
      }
      error={error && <BoxError>{t(error.message)}</BoxError>}
      success={
        isDone
          ? {
              title:
                'Reset instructions have been sent to your email. Please follow them then come back here to sign in again with your new password',
              route: '/signin',
            }
          : undefined
      }
    />
  );
};

const RequestPasswordLayout = ({
  values,
  errors,
  handleBlur,
  handleSubmit,
  handleChange,
}: FormikProps<IRequestPasswordFormState>) => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="column">
      <H3 textAlign="center">{t('Forgot password')}</H3>

      <Form onSubmit={handleSubmit}>
        <Input
          id="email"
          name="email"
          type="email"
          label="Email"
          error={errors.email}
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
        />

        <Box flexDirection="column" alignItems="center" mt={4}>
          <PrimaryButton type="submit">{t('Send reset instructions')}</PrimaryButton>

          <NavLink to="/signin" mt={2} color="UIDarkGray" fontSize={theme.fontSizes[1]}>
            {`< ${t('Cancel')}`}
          </NavLink>
        </Box>
      </Form>
    </Box>
  );
};

const RequestPasswordForm = withFormik({
  mapPropsToValues: (): IRequestPasswordFormState => ({
    email: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Email field is required'),
  }),

  handleSubmit: (values: IRequestPasswordFormState) => invokeRequestResetPassword(values),
})(RequestPasswordLayout);

export interface IRequestPasswordFormState {
  email: string;
}
