import React from 'react';
import { useTranslation } from 'react-i18next';

import { history } from 'lib/routing';
import { Box, Card, Image, Text, PrimaryButton, theme } from 'ui';

interface ISuccessCard {
  title: string;
  route: string;
}

export const SuccessCard = ({ title, route }: ISuccessCard) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Box justifyContent="center">
        <Image src={theme.images.successIcon} width="50px" />
      </Box>

      <Text fontSize={theme.fontSizes[4]} textAlign="center" my={theme.space[4]}>
        {t(title)}
      </Text>

      <PrimaryButton onClick={() => history.replace(route)}>{t('OK')}</PrimaryButton>
    </Card>
  );
};
