import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Image, theme, Text, PrimaryButton } from 'ui';
import { history } from 'lib/routing';

interface IEmptyEntityCard {
  route: string;
  waringText: string;
  warningButtonText: string;
}

export const EmptyEntityCard = ({
  route,
  waringText,
  warningButtonText,
}: IEmptyEntityCard) => {
  const { t } = useTranslation();

  return (
    <Box
      p="30px"
      width={['100%', '330px']}
      alignItems="center"
      flexDirection="column"
      style={{ boxShadow: '0px 4px 59px rgba(0, 0, 0, 0.14)' }}
    >
      <Image src={theme.images.warningIcon} />

      <Text my="22px" fontWeight="bold" textAlign="center">
        {t(waringText)}
      </Text>

      <PrimaryButton type="button" onClick={() => history.push(route)}>
        {t(warningButtonText)}
      </PrimaryButton>
    </Box>
  );
};
