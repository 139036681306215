import React from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import { history } from 'lib/routing';
import { ButtonTooltip } from 'features/common';
import { HIRING_STATUSES } from '../lib/hiring-statuses';
import { Box, PrimaryButton, WarnButton } from 'ui';
import { confirmProposal, declineProposal, cancelProposal } from 'api/proposals';

export const ProposalActions = ({
  proposalId,
  status,
  userId,
}: {
  proposalId: string;
  status: number;
  userId: string;
}) => {
  const renderActions = () => {
    switch (status) {
      case HIRING_STATUSES.PROPOSED:
        return <ActionsForProposedStep proposalId={proposalId} />;
      case HIRING_STATUSES.NOT_CONFIRMED:
        return <ActionsForNotConfirmedStep proposalId={proposalId} />;
      case HIRING_STATUSES.CONFIRMED:
        return <ActionsForConfirmedStep proposalId={proposalId} />;
      case HIRING_STATUSES.FINISHED:
        return (
          <ActionsForFinishedAndCanceledSteps proposalId={proposalId} userId={userId} />
        );
      case HIRING_STATUSES.CANCELLED:
        return (
          <ActionsForFinishedAndCanceledSteps proposalId={proposalId} userId={userId} />
        );
      default:
        return null;
    }
  };

  return renderActions();
};

const ActionsForProposedStep = ({ proposalId }: { proposalId: string }) => {
  const { t } = useTranslation();
  const [declineProposalMutation] = useMutation(declineProposal);
  const [confirmProposalMutation] = useMutation(confirmProposal);

  const onDeclineProposal = async () => {
    try {
      await declineProposalMutation(proposalId);

      history.goBack();
    } catch (e) {}
  };

  const onConfirmProposal = async () => {
    try {
      await confirmProposalMutation(proposalId);

      history.goBack();
    } catch (e) {}
  };

  return (
    <Box justifyContent="center">
      <Box mr="15px">
        <ButtonTooltip tooltip="This job offer will be rejected and removed from the list of offers.">
          <WarnButton onClick={() => onDeclineProposal()}>{t('Decline')}</WarnButton>
        </ButtonTooltip>
      </Box>

      <ButtonTooltip tooltip="You hire a technician. After he confirms his consent to this job, it will move to the section <u>Hired.</u>">
        <PrimaryButton onClick={() => onConfirmProposal()}>{t('Hire')}</PrimaryButton>
      </ButtonTooltip>
    </Box>
  );
};

const ActionsForNotConfirmedStep = ({ proposalId }: { proposalId: string }) => {
  const { t } = useTranslation();
  const [cancelProposalMutation] = useMutation(cancelProposal);
  const onCancelProposal = async (id: string) => {
    try {
      await cancelProposalMutation(id);
      history.goBack();
    } catch (e) {}
  };

  return (
    <ButtonTooltip tooltip="The job application will be rejected and permanently removed from the section <u>Not Confirmed.</u>">
      <WarnButton onClick={() => onCancelProposal(proposalId)}>{t('Delete')}</WarnButton>
    </ButtonTooltip>
  );
};

const ActionsForConfirmedStep = ({ proposalId }: { proposalId: string }) => {
  const { t } = useTranslation();
  const [cancelApplyMutation] = useMutation(declineProposal);
  const [confirmApplyMutation] = useMutation(confirmProposal);

  const onCancelProposal = async () => {
    try {
      await cancelApplyMutation(proposalId);

      history.goBack();
    } catch (e) {}
  };

  const onConfirmProposal = async () => {
    try {
      await confirmApplyMutation(proposalId);

      history.goBack();
    } catch (e) {}
  };

  return (
    <Box justifyContent="center">
      <Box mr="15px">
        <ButtonTooltip tooltip="Contact the technician in order to resolve the issues regarding your refusal to provide this job.">
          <WarnButton onClick={() => onCancelProposal()}>{t('Cancel')}</WarnButton>
        </ButtonTooltip>
      </Box>

      <ButtonTooltip tooltip="The job was completed by this technician. Please leave your honest feedback to the technician in the section <u>Finished.</u>">
        <PrimaryButton onClick={() => onConfirmProposal()}>{t('Job done')}</PrimaryButton>
      </ButtonTooltip>
    </Box>
  );
};

const ActionsForFinishedAndCanceledSteps = ({
  proposalId,
  userId,
}: {
  proposalId: string;
  userId: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box justifyContent="center">
      <PrimaryButton
        onClick={() => history.push(`/hiring/feedback/proposals/${proposalId}/${userId}`)}
      >
        {t('Leave feedback')}
      </PrimaryButton>
    </Box>
  );
};
