import React, { useState } from 'react';

import { IUser } from 'lib/types';
import { UserMenu } from './user-menu';
import { MobileMenu } from './mobile-menu';
import { dropdownMenus } from '../lib/dropdown-menus';
import { LanguageSelector } from './language-selector';
import { Box, theme, Image } from 'ui';
import { DesktopDropdownMenu } from './desktop-dropdown-menu';

interface IHeader {
  profile: IUser;
}

export const Header = ({ profile }: IHeader) => {
  return (
    <Box
      width={1}
      height="60px"
      backgroundColor="UIWhite"
      style={{
        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.25)',
      }}
      justifyContent="center"
    >
      <Desktop profile={profile} />

      <Mobile profile={profile} />
    </Box>
  );
};

const Desktop = ({ profile }: { profile: IUser }) => {
  return (
    <Box
      width="800px"
      display={['none', 'none', 'flex']}
      alignItems="center"
      justifyContent="space-between"
    >
      <Logo />

      <Box alignItems="center">
        {dropdownMenus.map((x, i) => (
          <Box mr={4} key={i}>
            <DesktopDropdownMenu {...x} user={profile} />
          </Box>
        ))}

        <Box mr={5}>
          <UserMenu profile={profile} />
        </Box>

        <LanguageSelector />
      </Box>
    </Box>
  );
};

const Mobile = ({ profile }: { profile: IUser }) => {
  const [isVisibleMobileMenu, setVisibleMobileMenu] = useState(false);

  return (
    <Box
      px={4}
      width={1}
      display={['flex', 'flex', 'none']}
      position="relative"
      alignItems="center"
      justifyContent="space-between"
    >
      <Image
        src={theme.images.menuIcon}
        isPointer={true}
        onClick={() => setVisibleMobileMenu(!isVisibleMobileMenu)}
      />

      <Logo />

      <LanguageSelector />

      {isVisibleMobileMenu && (
        <MobileMenu profile={profile} setVisibleMobileMenu={setVisibleMobileMenu} />
      )}
    </Box>
  );
};

const Logo = () => (
  <a href="http://pdrdispatch.com/">
    <Image src={theme.images.logo} width="50px" />
  </a>
);
