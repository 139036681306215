import { createEvent, createEffect, forward } from 'effector';

import { IResponse } from 'lib/types/response';
import { ProfileDTO, updateProfile } from 'api/profile';
import { IUser, IError } from 'lib/types';
import { history } from 'lib/routing';

export const invokeProfileUpdate = createEvent<ProfileDTO>();
const profileUpdateEffect = createEffect<ProfileDTO, IResponse<IUser>, IError>();

profileUpdateEffect.use(x => updateProfile(x));

profileUpdateEffect.done.watch(() => history.push('/profile'));

forward({
  from: invokeProfileUpdate,
  to: profileUpdateEffect,
});
