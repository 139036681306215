import React from 'react';

// Component type issue "withRouter" types definition
type Component = React.ComponentType<any>;
type Guard<C> = (route: Route<C>, context: C) => Route<C> | null;

export type Route<C> = {
  key?: string;
  path?: string;
  exact?: boolean;
  component: Component;
  guards?: Guard<C>[];
};

type ReactRoute = {
  key?: string;
  path?: string;
  exact: boolean;
  component: Component;
};

export const compileGuards = <C>(config: Route<C>[], context: C): ReactRoute[] => {
  return config.reduce((all, route) => {
    const appliedRoute = applyGuards(route, context);
    if (isRoute(appliedRoute)) {
      all.push(toReactRoute(appliedRoute));
    }
    return all;
  }, [] as ReactRoute[]);
};

function applyGuards<C>(route: Route<C>, context: C): Route<C> | null {
  if (route.guards) {
    let currentRoute: Route<C> | null = route;
    for (const guard of route.guards) {
      if (!currentRoute) return null;
      currentRoute = guard(currentRoute, context);
    }
    return currentRoute;
  }
  return route;
}

function isRoute<C>(route: Route<C> | null): route is Route<C> {
  return Boolean(route);
}

function toReactRoute<C>(route: Route<C>): ReactRoute {
  return {
    key: route.key,
    path: route.path,
    exact: typeof route.exact === 'boolean' ? route.exact : true,
    component: route.component,
  };
}
