export interface IThemeColor {
  [name: string]: string;
}

export const colors = {
  UIRed: '#D20000',
  UIBlue: '#008FDF',
  UIWhite: '#FFFFFF',
  UIBlack: '#000000',
  UIGray: '#CBCBCB',
  UIDarkGray: '#9A9A9A',
  UIOrange: '#FF9E75',
  UIGreen: '#00d241',
};
