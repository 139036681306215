import { createGlobalStyle } from 'styled-components';
import { theme } from 'ui';

import 'react-tabs/style/react-tabs.css';
import 'rc-time-picker/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

  h1, h2, h3, h4, h5, h6, ul, li, p {
    margin: 0px;
    padding: 0px;
  }

  ul {
    list-style: none;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;

    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

  #root {
    height: 100%;

    position: relative;
  }

  // STYLES FOR REACT TIME PICKER LIBRARY

  .rc-time-picker {
    width: 100%;
  }

  .rc-time-picker-input {
    height: 40px !important;
    padding-left: 47px !important;

    font-size: 16px !important;
    color: ${theme.colors.UIBlack} !important;

    border-color: ${theme.colors.UIGray} !important;
    border-radius: 0 !important;

    background-image: url(${theme.images.inputClockIcon}) !important;
    background-repeat: no-repeat;
    background-position: 17px center;
    background-size: 13px;
}
  }

  .rc-time-picker-clear {
    top: 8px !important;
  }

  .rc-time-picker-panel-inner {
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  // STYLES FOR REACT PAGINATE LIBRARY

  .react-paginate {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    list-style: none;
  }

  .react-paginate li {
    padding: 0 10px;

    cursor: pointer

    font-weight: bold;
  }

  .react-paginate li.selected {
    color: ${theme.colors.UIBlue}
  }

  .react-paginate li.disabled {
    color: ${theme.colors.UIDarkGray}
  }

  // STYLES FOR REACT DATEPICKER LIBRARY

  .react-datepicker {
    border-radius: 0px !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--today.react-datepicker__day--selected {
    border-radius: 0px !important;
    background-color: ${theme.colors.UIBlue} !important;
  }

  .react-datepicker__day--today {
    color: ${theme.colors.UIWhite} !important;
    background-color: ${theme.colors.UIDarkGray} !important;
  }

  .react-datepicker__input-container input {
    width: 100%;
    height: 40px;
    padding-left: 47px;

    font-size: 16px;
    cursor: pointer;
    color: ${theme.colors.UIBlack};

    border: 1px solid ${theme.colors.UIGray};
    background-image: url(${theme.images.calendarIcon});
    background-repeat: no-repeat;
    background-position: 17px center;
    background-size: 13px;
  }

  // STYLES FOR REACT CAROUSEL LIBRARY

  .carousel {
    position: relative;

    margin-top: 20px;
  }

  .carousel__slider {
    margin-bottom: 32px;
  }

  .carousel__dot {
    padding: 0;
    width: 70px;
    height: 70px;

    opacity: 0.5;
    border: 0px;

    margin-right: 10px;
  }

  .carousel__dot:last-child {
    margin-right: 0px;
  }

  .carousel__dot--selected {
    opacity: 1;
  }
`;
