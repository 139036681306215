import { createStore, forward, createEffect, createEvent } from 'effector';
import { createGate } from 'effector-react';

import { IError } from 'lib/types';
import { IResponse } from 'lib/types/response';
import { createFetching } from 'lib/fetching';
import { getAllJobSearches, GetAllSearchesDTO, deleteJobSearch } from 'api/jobs';

// GET ALL SEARCHES
export const $searches = createStore<GetAllSearchesDTO[]>([]);
export const SearchesGate = createGate();
export const searchesLoadingEffect = createEffect<
  void,
  IResponse<GetAllSearchesDTO[]>,
  IError
>();
export const searchesLoadingFetching = createFetching(searchesLoadingEffect);

searchesLoadingEffect.use(() => getAllJobSearches());

$searches.on(searchesLoadingEffect.done, (state, { params, result }) => result.data);

forward({
  from: SearchesGate.open,
  to: searchesLoadingEffect,
});

// DELETE SEARCH

export const deleteSearchEffect = createEffect<
  number,
  IResponse<GetAllSearchesDTO[]>,
  IError
>();
export const invokeDeleteSearch = createEvent<number>();
export const deleteSearchFetching = createFetching(deleteSearchEffect);

deleteSearchEffect.use(x => deleteJobSearch(x.toString()));

$searches.on(deleteSearchEffect.done, (state, { params, result }) => result.data);

forward({
  from: invokeDeleteSearch,
  to: deleteSearchEffect,
});
