import React from 'react';
import { useStore } from 'effector-react';

import { PicturesField } from './pictures-field';
import { setJobPicture, $jobPictures, deleteJobPicture } from '../model';

export const AddPicturesField = () => {
  const jobPictures = useStore($jobPictures);

  return (
    <PicturesField
      pictures={jobPictures}
      handlePictureDeleting={x => deleteJobPicture(x)}
      handlePictureSelection={x => setJobPicture(x as File)}
    />
  );
};
