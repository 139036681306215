import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Image } from 'ui';
import { Initials } from './initials';

interface IAvatar {
  username: string;
  avatar: string | null;
  handleAvatarClick?: () => void;
}

export const Avatar = ({ avatar, username, handleAvatarClick }: IAvatar) => {
  return (
    <Fragment>
      {avatar ? (
        <AvatarImage src={avatar} isPointer={true} onClick={handleAvatarClick} />
      ) : (
        <Initials username={username} onClick={handleAvatarClick} />
      )}
    </Fragment>
  );
};

export const AvatarImage = styled(Image)`
  width: 35px;
  height: 35px;

  border-radius: 50%;
`;
