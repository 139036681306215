export const ReactSelectStyles = (error: boolean) => ({
  control: (base: any, state: any): object => ({
    ...base,
    height: '40px',
    minHeight: '40px',
    width: '100%',
    minWidth: '75px',
    maxWidth: '100%',

    cursor: 'pointer',
    borderColor: state.isFocused ? '#008FDF' : error ? '#D20000' : '#CBCBCB',
    borderRadius: '0',
    boxShadow: 'none',

    '&:hover': {
      borderColor: state.isFocused ? '#008FDF' : '#CBCBCB',
    },
  }),
  menu: (base: any): object => ({
    ...base,
    maxWidth: '100%',
    margin: '0',

    border: '1px solid #008FDF',
    borderTop: '0',
    boxShadow: 'none',
    borderRadius: '0px',
  }),
  menuList: (base: any): object => ({
    ...base,
    maxWidth: '100%',
    padding: '0',
  }),
  indicatorSeparator: (base: any): object => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base: any, state: any): object => ({
    ...base,
    transform: state.isFocused ? 'rotate(0deg)' : 'rotate(-90deg)',
  }),
  clearIndicator: (base: any): object => ({
    ...base,
    padding: 4,
  }),
  singleValue: (base: any): object => ({
    ...base,
    color: 'black',
    fontSize: '16px',
  }),
  placeholder: (base: any): object => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base: any): object => ({
    ...base,
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    padding: '0px 6px',
  }),
  input: (base: any): object => ({
    ...base,
    margin: 0,
    padding: 0,

    fontSize: '16px',
  }),
  option: (base: any, state: any): object => ({
    ...base,
    color: '#4E4D4D',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '-0.2px',

    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#F5F9FC' : '#FFFFFF',
    borderBottom: '1px solid #008FDF',

    padding: '10px 10px',

    '&:hover': {
      backgroundColor: '#F5F9FC',
    },

    '&:last-child': {
      borderBottom: '0',
    },
  }),
  noOptionsMessage: (base: any): object => ({
    ...base,
    height: '28px',
    minHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#4E4D4D',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '-0.2px',

    cursor: 'pointer',
  }),
});
