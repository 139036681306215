import React from 'react';
import { useQuery } from 'react-query';

import { JobCard } from 'features/jobs';
import { history } from 'lib/routing';
import { getApplies } from 'api/applies';
import { ProfileGate } from 'pages/profile';
import { IJob, IResponse } from 'lib/types';
import { Box, DashboardTemplate } from 'ui';
import { Header, PageLoader, HiringNavigation } from 'features/common';
import {
  PageHint,
  getCurrentPageByPathname,
  workerHiringNavigationItems,
} from 'features/hiring';

export const AppliesPage = () => {
  const { pathname } = history.location;
  const currentPage = getCurrentPageByPathname(pathname, workerHiringNavigationItems);
  const { data, isLoading } = useQuery<IResponse<IJob[]>>(
    ['applies', currentPage?.status],
    getApplies,
  );

  return (
    <DashboardTemplate
      render={profile => (
        <Box width={['100%', '850px', '850px', '850px']} flexDirection="column">
          <ProfileGate />

          <HiringNavigation navItems={workerHiringNavigationItems} isProposals={false} />

          {currentPage && (
            <PageHint title={currentPage.label} description={currentPage.hint} />
          )}

          <Box m="0 auto" width={['100%', '510px']} flexDirection="column">
            {data &&
              Boolean(data.data.length) &&
              data.data.map(x => (
                <JobCard
                  key={x.id}
                  job={x}
                  jobRoute={`/hiring/applies/${x.id}/${currentPage?.status}`}
                />
              ))}
          </Box>

          {isLoading && <PageLoader />}
        </Box>
      )}
      renderHeader={profile => <Header profile={profile} />}
    />
  );
};
