import { useEffect, RefObject, Dispatch } from 'react';

export const useOutsideClick = (
  ref: RefObject<HTMLDivElement>,
  setVisibleMenuItem: Dispatch<boolean>,
) => {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target as Node | null)) {
      setVisibleMenuItem(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
