import React, { useState } from 'react';
import styled from 'styled-components';

import { IInput, Box, Input, theme } from 'ui';

interface HintProps {
  hint: string;
  onHintClick: () => void;
}

export const SocialInput = ({ hint, onHintClick, ...props }: HintProps & IInput) => {
  const { onChange } = props;
  const [isVisibleHint, setVisibleHint] = useState(false);

  return (
    <Box flexDirection="column">
      <Input
        {...props}
        onChange={e => {
          setVisibleHint(true);

          return onChange && onChange(e);
        }}
      />

      {isVisibleHint && (
        <Hint
          onClick={() => {
            onHintClick();
            setVisibleHint(false);
          }}
        >
          {hint}
        </Hint>
      )}
    </Box>
  );
};

const Hint = styled.span`
  display: inline-block;
  margin-top: -10px;
  margin-bottom: 15px;

  cursor: pointer;
  font-size: ${theme.fontSizes[0]}px;
`;
