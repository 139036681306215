import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ICenterContentTemplate {
  children: ReactNode;
}

export const CenterContentTemplate = ({ children }: ICenterContentTemplate) => (
  <CenterContent>{children}</CenterContent>
);

export const CenterContent = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  overflow-y: auto;
`;
