import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';

import { IUser } from 'lib/types';
import { useOutsideClick } from '../lib/detect-outside-click';
import { Box, Text, NavLink, theme, Image } from 'ui';

export const DesktopDropdownMenu = ({
  title,
  links,
  user,
}: {
  user: IUser;
  title: { label: string; field: string };
  links: Array<{ title: string; route: string; isProposalOrApplyLink: boolean }>;
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isVisibleJobsMenu, setVisibleJobsMenu] = useState(false);
  useOutsideClick(ref, setVisibleJobsMenu);

  return (
    <Box position="relative">
      <Box alignItems="center">
        <Text fontWeight="bold" isPointer={true} onClick={() => setVisibleJobsMenu(true)}>
          {t(title.label)}
        </Text>

        {user.minified_statistics[title.field].hasNew && (
          <Image
            src={theme.images.alertIcon}
            height="20px"
            style={{ marginLeft: '7px' }}
          />
        )}
      </Box>

      {isVisibleJobsMenu && (
        <Box
          py={2}
          ref={ref}
          width="250px"
          position="absolute"
          top="50px"
          right="calc(100% - 32px)"
          flexDirection="column"
          backgroundColor="UIWhite"
          style={{
            boxShadow: '0px 4px 59px rgba(0, 0, 0, 0.14)',
          }}
          zIndex={99999}
        >
          {links.map((x, i) => (
            <JobLinkWrapper
              key={i}
              hasNewStatistic={
                x.isProposalOrApplyLink && user.minified_statistics[title.field].hasNew
              }
            >
              <JobLink
                to={x.route}
                onClick={() => setVisibleJobsMenu(false)}
                activeClassName="active"
              >
                {t(x.title)}
              </JobLink>
            </JobLinkWrapper>
          ))}
        </Box>
      )}
    </Box>
  );
};

const JobLink = styled(NavLink)`
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;

  color: ${theme.colors.UIBlack};
  border-left: 4px solid transparent;
  text-decoration: none;

  :hover {
    background-color: ${lighten(0.16, theme.colors.UIGray)};
  }

  &.active {
    border-left: 4px solid ${theme.colors.UIBlue};
  }
`;

const JobLinkWrapper = styled.div<{ hasNewStatistic: boolean }>`
  > ${JobLink} {
    position: relative;

    ${({ hasNewStatistic }) =>
      hasNewStatistic &&
      `
      &:after {
        content: '';

        background: url(${theme.images.alertIcon});
        background-position: center center;
        background-size: contain;

        width: 20px;
        height: 20px;

        position: absolute;
        transform: translateX(7px);
      }
    `}
  }
`;
