import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

export const H1 = styled.h1`
  margin: 0;
  margin-bottom: 20px;

  font-size: ${({ theme }) => theme.fontSizes[6]}px;
  line-height: 4rem;
  user-select: none;
`;

export const H2 = styled.h2`
  margin: 0;
  margin-bottom: 20px;

  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  line-height: 3rem;
  user-select: none;
`;

export const H3 = styled.h3<TypographyProps>`
  margin: 0;
  margin-bottom: 34px;

  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  line-height: 2rem;
  user-select: none;

  ${typography}
`;

export const H4 = styled.h4`
  margin: 0;
  margin-bottom: 20px;

  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  line-height: 2rem;
  user-select: none;
`;

export const H5 = styled.h5`
  margin: 0;
  margin-bottom: 20px;

  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  line-height: 2rem;
  user-select: none;
`;

export const H6 = styled.h6`
  margin: 0;
  margin-bottom: 20px;

  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  line-height: 2rem;
  user-select: none;
`;
