import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps, withRouter } from 'react-router';

import { Box } from 'ui';
import { getApply } from 'api/applies';
import { PageLoader } from 'features/common';
import { FullJobCard } from 'features/jobs';
import { IJob, IResponse } from 'lib/types';
import { ApplicationCard, ApplyActions } from 'features/hiring';

export const ApplyPage = withRouter(
  ({ match }: RouteComponentProps<{ id: string; status: string }>) => {
    const { id, status } = match.params;
    const { data, isLoading } = useQuery<IResponse<IJob>>(
      ['apply', id, status],
      getApply,
    );

    return (
      <Fragment>
        {data && data.data.job_applies && (
          <Box pt="90px" alignItems="center" flexDirection="column">
            <Box mb="50px">
              <FullJobCard job={data.data} withActionsBar={false} />
            </Box>

            <Box mb="100px">
              <ApplicationCard jobApply={data.data.job_applies[0]}>
                <ApplyActions
                  status={parseInt(status)}
                  userId={data.data.job_applies[0].client_id.toString()}
                  applyId={data.data.job_applies[0].id.toString()}
                />
              </ApplicationCard>
            </Box>
          </Box>
        )}

        {isLoading && <PageLoader />}
      </Fragment>
    );
  },
);
