import React from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import { history } from 'lib/routing';
import { ButtonTooltip } from 'features/common';
import { HIRING_STATUSES } from '../lib/hiring-statuses';
import { Box, PrimaryButton, WarnButton } from 'ui';
import { cancelApply, confirmApply, declineApply } from 'api/applies';

export const ApplyActions = ({
  applyId,
  status,
  userId,
}: {
  applyId: string;
  status: number;
  userId: string;
}) => {
  const renderActions = () => {
    switch (status) {
      case HIRING_STATUSES.APPLIED:
        return <ActionsForAppliedStep applyId={applyId} />;
      case HIRING_STATUSES.NOT_CONFIRMED:
        return <ActionsForNotConfirmedStep applyId={applyId} />;
      case HIRING_STATUSES.CONFIRMED:
        return <ActionsForConfirmedStep applyId={applyId} />;
      case HIRING_STATUSES.FINISHED:
        return <ActionsForFinishedAndCanceledSteps applyId={applyId} userId={userId} />;
      case HIRING_STATUSES.CANCELLED:
        return <ActionsForFinishedAndCanceledSteps applyId={applyId} userId={userId} />;
      default:
        return null;
    }
  };

  return renderActions();
};

const ActionsForAppliedStep = ({ applyId }: { applyId: string }) => {
  const { t } = useTranslation();
  const [cancelApplyMutation] = useMutation(cancelApply);
  const onCancelApply = async (id: string) => {
    try {
      await cancelApplyMutation(id);
      history.goBack();
    } catch (e) {}
  };

  return (
    <ButtonTooltip tooltip="The job card together with your apply-card will be permanently deleted.">
      <WarnButton onClick={() => onCancelApply(applyId)}>{t('Delete')}</WarnButton>
    </ButtonTooltip>
  );
};

const ActionsForNotConfirmedStep = ({ applyId }: { applyId: string }) => {
  const { t } = useTranslation();
  const [declineApplyMutation] = useMutation(declineApply);
  const [confirmApplyMutation] = useMutation(confirmApply);

  const onDeclineApply = async () => {
    try {
      await declineApplyMutation(applyId);

      history.goBack();
    } catch (e) {}
  };

  const onConfirmApply = async () => {
    try {
      await confirmApplyMutation(applyId);

      history.goBack();
    } catch (e) {}
  };

  return (
    <Box justifyContent="center">
      <Box mr="15px">
        <ButtonTooltip tooltip="The job card together with your apply-card will be permanently deleted.">
          <WarnButton onClick={() => onDeclineApply()}>{t('Decline')}</WarnButton>
        </ButtonTooltip>
      </Box>

      <ButtonTooltip tooltip="You are hired for this job. Make sure to enter into a contract with the employer in order to have legal confirmation of your transaction.">
        <PrimaryButton onClick={() => onConfirmApply()}>{t('Confirm')}</PrimaryButton>
      </ButtonTooltip>
    </Box>
  );
};

const ActionsForConfirmedStep = ({ applyId }: { applyId: string }) => {
  const { t } = useTranslation();
  const [cancelApplyMutation] = useMutation(declineApply);
  const [confirmApplyMutation] = useMutation(confirmApply);

  const onCancelApply = async () => {
    try {
      await cancelApplyMutation(applyId);

      history.goBack();
    } catch (e) {}
  };

  const onConfirmApply = async () => {
    try {
      await confirmApplyMutation(applyId);

      history.goBack();
    } catch (e) {}
  };

  return (
    <Box justifyContent="center">
      <Box mr="15px">
        <ButtonTooltip tooltip="Contact the employer in order to resolve issues regarding your refusal to perform the job.">
          <WarnButton onClick={() => onCancelApply()}>{t('Cancel')}</WarnButton>
        </ButtonTooltip>
      </Box>

      <ButtonTooltip tooltip="You have completed the job in accordance with your contractual obligations. Please, leave your honest feedback to the employer in the section <u>Finished.</u>">
        <PrimaryButton onClick={() => onConfirmApply()}>{t('Job done')}</PrimaryButton>
      </ButtonTooltip>
    </Box>
  );
};

const ActionsForFinishedAndCanceledSteps = ({
  applyId,
  userId,
}: {
  applyId: string;
  userId: string;
}) => {
  const { t } = useTranslation();

  return (
    <Box justifyContent="center">
      <PrimaryButton
        onClick={() => history.push(`/hiring/feedback/applies/${applyId}/${userId}`)}
      >
        {t('Leave feedback')}
      </PrimaryButton>
    </Box>
  );
};
