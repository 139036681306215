import { createStore, createEvent } from 'effector';

export const $token = createStore(localStorage.getItem('token') || null);
export const $isAuthenticated = $token.map((token: string | null) => !!token);

export const tokenChanged = createEvent<string>();
export const tokenDropped = createEvent();

$token.on(tokenDropped, (_: any) => {
  localStorage.clear();

  return null;
});

$token.on(tokenChanged, (_: any, token: string) => {
  localStorage.setItem('token', token);

  return token;
});
