export const FEEDBACK_STATUS: { [key: number]: string } = {
  0: 'Negative',
  1: 'Positive',
};

export const FEEDBACK_STATUS_OPTIONS = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
];

export const FEEDBACK_REASON_OPTIONS = [
  {
    label: 'Late payment',
    value: 0,
  },
  {
    label: 'Non-payment',
    value: 1,
  },
  {
    label: 'Incomplete payment',
    value: 2,
  },
  {
    label: 'Other payment issue',
    value: 3,
  },
  {
    label: 'Vehicle(s) not ready for work',
    value: 4,
  },
  {
    label: 'Terms different than agreed',
    value: 5,
  },
  {
    label: 'Unfair distribution of work',
    value: 6,
  },
  {
    label: 'Low quality of work',
    value: 7,
  },
  {
    label: 'Disruption of planned schedule',
    value: 8,
  },
  {
    label: 'Late cancellation or no show',
    value: 9,
  },
];
