import React from 'react';
import { useTranslation } from 'react-i18next';
import { darken } from 'polished';
import styled from 'styled-components';

import { IUser } from 'lib/types';
import { Avatar } from 'features/common';
import { formatDate } from 'lib/format-date';
import { SectionTemplate } from './job-section';
import { Box, Text, theme } from 'ui';

interface IJobUserInfo {
  user: IUser | undefined;
  postedDate: string | Date;
  postedLabel?: string;
}

export const JobUserInfo = ({
  user,
  postedDate,
  postedLabel = 'Posted date',
}: IJobUserInfo) => {
  const { t } = useTranslation();

  return (
    <SectionTemplate>
      <UserInfo
        justifyContent="space-between"
        alignItems="center"
        onClick={e => e.stopPropagation()}
      >
        {user && (
          <a
            rel="noopener noreferrer"
            href={`${window.location.origin}/users/${user?.id}`}
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Box alignItems="center">
              <Avatar avatar={user.avatar} username={user.username} />

              <Text ml={2} fontWeight="bold" className="username">
                {user.username}
              </Text>
            </Box>
          </a>
        )}

        <Box flexDirection="column">
          <Text fontWeight="bold">{t(postedLabel)}:</Text>

          <JobPostDate>{formatDate(postedDate)}</JobPostDate>
        </Box>
      </UserInfo>
    </SectionTemplate>
  );
};

const UserInfo = styled(Box)`
  cursor: pointer;

  .username {
    color: ${theme.colors.UIBlack};
    font-weight: bold;
  }

  :hover .username {
    text-decoration: underline;
  }
`;

const JobPostDate = styled(Text)`
  color: ${darken(0.3, theme.colors.UIGray)};
`;
