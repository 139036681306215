import { theme } from 'ui';

export const WORK_PLACE_OPTIONS = [
  { value: 'dealer', label: 'Dealer' },
  { value: 'auction', label: 'Auction' },
  { value: 'body shop', label: 'Body shop' },
  { value: 'manufacturer', label: 'Manufacturer' },
  { value: 'rental agency', label: 'Rental agency' },
  { value: 'broker', label: 'Brokers place' },
  { value: 'technician', label: 'Technician place' },
];

export const PRICING_TYPES = Object.freeze({
  BY_AGREEMENT: {
    value: 'by_agreement',
    label: 'By agreement',
    icon: theme.images.handshakeIcon,
    fieldName: 'price_by_agreement' as 'price_by_agreement',
    fieldSign: '',
  },
  FOR_CAR: {
    value: 'for_car',
    label: 'For a car',
    icon: theme.images.dollarIcon,
    fieldName: 'price_from_car' as 'price_from_car',
    fieldSign: '$',
  },
  FROM_ASSESSMENT: {
    value: 'from_assessment',
    label: 'From assessment',
    icon: theme.images.percentIcon,
    fieldName: 'price_from_percentage' as 'price_from_percentage',
    fieldSign: '%',
  },
});
