import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { withRouter, RouteComponentProps } from 'react-router';

import { ApplyJobForm } from 'features/hiring';
import { pageUnmounted } from 'features/jobs';
import { Header, PageLoader } from 'features/common';
import { DashboardTemplate, Box } from 'ui';
import { $job, jobFetching, JobGate } from 'pages/job/model';

export const ApplyJobPage = withRouter(
  ({ match }: RouteComponentProps<{ id: string }>) => {
    const job = useStore($job);
    const isLoading = useStore(jobFetching.isLoading);

    useEffect(() => () => pageUnmounted(), []);

    return (
      <DashboardTemplate
        render={profile => (
          <Box width={['100%', '510px', '510px', '510px']}>
            <JobGate id={match.params.id} />

            {job && <ApplyJobForm job={job} />}

            {isLoading && <PageLoader />}
          </Box>
        )}
        renderHeader={profile => <Header profile={profile} />}
      />
    );
  },
);
