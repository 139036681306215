import React, { Fragment } from 'react';
import { useStore } from 'effector-react';

import { Text, Box } from 'ui';
import { $enviroment } from 'lib/config';
import { PicturesField } from './pictures-field';
import {
  invokeJobPictureUpload,
  invokeJobPictureDelete,
  $uploadingPictureApiError,
} from '../model';

export const EditPicturesField = ({
  id,
  pictures,
}: {
  id: number | undefined;
  pictures: string | undefined;
}) => {
  const enviroment = useStore($enviroment);
  const storageURL = enviroment.STORAGE_URL;
  const parsedPictures: string[] = pictures ? JSON.parse(pictures) : [];
  const enviromentRegexp = new RegExp(storageURL);
  const uploadingPictureApiError = useStore($uploadingPictureApiError);

  return (
    <Fragment>
      <PicturesField
        pictures={parsedPictures.map(x => `${storageURL}${x}`)}
        handlePictureDeleting={(_, picture) =>
          invokeJobPictureDelete({
            id,
            pictureURL: (picture as string).replace(enviromentRegexp, ''),
          } as {
            id: number;
            pictureURL: string;
          })
        }
        handlePictureSelection={x =>
          invokeJobPictureUpload({ id, picture: x } as { id: number; picture: File })
        }
      />

      {uploadingPictureApiError && (
        <Box width="100%">
          <Text
            mb={3}
            style={{ width: '100%' }}
            color="UIRed"
            fontSize="14px"
            textAlign="center"
          >
            {uploadingPictureApiError}
          </Text>
        </Box>
      )}
    </Fragment>
  );
};
