import React, { Suspense } from 'react';
import i18n from 'i18n';
import { Router } from 'react-router';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';

import { theme } from './ui/theme';
import { Pages } from 'pages';
import { history } from 'lib/routing';
import { PageLoader } from 'features/common';
import { ScrollToTop } from 'lib/scroll-to-top';
import { GlobalStyles } from './global-styles';

export const App = () => (
  <Suspense fallback={<PageLoader />}>
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <ScrollToTop />

            <Normalize />

            <GlobalStyles />

            <Pages />
          </React.Fragment>
        </ThemeProvider>
      </Router>
    </I18nextProvider>
  </Suspense>
);
