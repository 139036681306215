import React from 'react';
import { useTranslation } from 'react-i18next';

import { IUser } from 'lib/types';
import { history } from 'lib/routing';
import { USER_ROLES } from 'lib/user-roles';
import { AvatarImage } from './avatar-loader';
import { isProfileBelongsToUser } from 'features/common';
import { Box, theme, Image, Text } from 'ui';

const ROLE_IMAGE_MAP = Object.freeze({
  [USER_ROLES.TECHNICIAN.value]: theme.images.technicianRoleIcon,
  [USER_ROLES.BROKER.value]: theme.images.brokerRoleIcon,
  [USER_ROLES.DEALER.value]: theme.images.dealerRoleIcon,
  [USER_ROLES.BODY_SHOP.value]: theme.images.bodyShopRoleIcon,
  [USER_ROLES.AUCTION.value]: theme.images.auctionRoleIcon,
  [USER_ROLES.RENTAL_AGENCY.value]: theme.images.rentalAgencyRoleIcon,
  [USER_ROLES.MANUFACTURER.value]: theme.images.manufacturerRoleIcon,
});

export const GeneralInfo = ({ profile }: { profile: IUser }) => {
  const { t } = useTranslation();
  const userRole = Object.values(USER_ROLES).find(x => x.value === profile.user_role)
    ?.label;

  return (
    <Box
      py={['20px', '40px']}
      px={['15px', '60px']}
      mb="40px"
      width={1}
      style={{ boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)' }}
      position="relative"
      borderTop="5px solid"
      borderColor="UIBlue"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width={1} mb={4} flexDirection={['column', 'row']}>
        <Box mb={[3, '0px']}>
          <AvatarImage src={profile.avatar || theme.images.emptyAvatarIcon} />
        </Box>

        <Box ml={['0px', 3]} flexDirection="column">
          <Text
            mb={2}
            fontSize={4}
            lineHeight="32px"
            fontWeight="bold"
            textAlign={['left', 'center']}
          >
            {profile.username}
          </Text>

          <Box alignItems="center">
            <Image src={ROLE_IMAGE_MAP[profile.user_role]} />

            <Text lineHeight="13px" ml={1}>
              {t(userRole as string)}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box width={1} flexDirection="column">
        <Location location={`${profile.city}, ${profile.country}`} />

        <Text mt={3}>{profile.description}</Text>
      </Box>

      {isProfileBelongsToUser(profile.id) && (
        <Box
          position="absolute"
          right="30px"
          top="30px"
          onClick={() => history.push('/profile/edit')}
        >
          <Image src={theme.images.profileEditIcon} isPointer={true} />
        </Box>
      )}
    </Box>
  );
};

const Location = ({ location }: { location: string }) => (
  <Box alignItems="center">
    <Image src={theme.images.locationIcon} />

    <Text lineHeight="13px" ml={1}>
      {location}
    </Text>
  </Box>
);
