import { createStore } from 'effector';

export interface IEnvironment {
  API_URL: string;
  STORAGE_URL: string;
}

const development: IEnvironment = {
  API_URL: process.env.API_URL
    ? process.env.API_URL
    : 'https://app.pdrdispatch.com/backend/api',
  STORAGE_URL: 'https://app.pdrdispatch.com/storage/',
};

const production: IEnvironment = {
  API_URL: 'https://app.pdrdispatch.com/backend/api',
  STORAGE_URL: 'https://app.pdrdispatch.com/storage/',
};

export const $enviroment = createStore<IEnvironment>(
  process.env.REACT_APP_ENVIRONMENT === 'production' ? production : development,
);
