import React from 'react';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';

import { theme } from 'ui/theme';

const defaultOptions: google.maps.MapOptions = {
  fullscreenControl: true,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: true,
  disableDefaultUI: true,
  draggable: true,
  clickableIcons: false,
};

export const Map = withGoogleMap(
  ({
    lat,
    lng,
    isMarkerShown,
  }: {
    lat: number;
    lng: number;
    isMarkerShown?: boolean;
  }) => (
    <GoogleMap defaultOptions={defaultOptions} defaultZoom={13} center={{ lat, lng }}>
      {isMarkerShown && (
        <Marker icon={theme.images.locationIcon} position={{ lat, lng }} />
      )}
    </GoogleMap>
  ),
);
