import { forward, createEvent, createEffect } from 'effector';

import { IApply } from 'lib/types/apply';
import { history } from 'lib/routing';
import { IResponse, IError } from 'lib/types';
import { applyJob, ApplyJobDTO } from 'api/applies';

// APPLY JOB
export const invokeJobApply = createEvent<{ id: string; data: ApplyJobDTO }>();
const jobApplyEffect = createEffect<
  { id: string; data: ApplyJobDTO },
  IResponse<IApply>,
  IError
>();

jobApplyEffect.use(x => applyJob(x.id, x.data));

jobApplyEffect.done.watch(() => history.push('/hiring/applies'));

forward({
  from: invokeJobApply,
  to: jobApplyEffect,
});
