import React, { Fragment, ReactNode } from 'react';
import styled from 'styled-components';

import { Box, Text, theme } from 'ui';
import { useTranslation } from 'react-i18next';

export const SectionTemplate = ({
  children,
  withDivider = true,
}: {
  children: ReactNode;
  withDivider?: boolean;
}) => (
  <Fragment>
    <Box flexDirection="column" mb="15px">
      {children}
    </Box>

    {withDivider && <Divider />}
  </Fragment>
);

export const SectionTitle = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <Text fontWeight="bold" color={theme.colors.UIBlue} mb={2} fontSize={3}>
      {t(title)}:
    </Text>
  );
};

export const Divider = styled.div`
  height: 1px;
  margin: 0 -40px;
  margin-bottom: 15px;

  background-color: ${theme.colors.UIGray};

  @media screen and (max-width: 576px) {
    margin: 0 -20px;
    margin-bottom: 15px;
  }
`;
