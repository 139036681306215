import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';

import { theme, Box, Text } from 'ui';

export const JobInfoCell = ({
  title,
  value,
}: {
  title: string;
  value: string | number | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <Box mb={[2, 1]}>
      <Text fontWeight="bold" mr={1} style={{ flex: '0 0 auto' }}>
        {t(title)}:
      </Text>

      <InfoCellValue>{value}</InfoCellValue>
    </Box>
  );
};

const InfoCellValue = styled(Text)`
  color: ${darken(0.3, theme.colors.UIGray)};
  word-break: break-all;
`;
