import { HIRING_STATUSES } from '../hiring-statuses';

type HiringNavItem = {
  label: string;
  route: string;
  status: number;
  hint: string;
};

const workerHiringNavigationItems: HiringNavItem[] = [
  {
    label: 'Applied',
    route: '/hiring/applies',
    status: HIRING_STATUSES.APPLIED,
    hint:
      'You are waiting for the confirmation of your apply by the employer. At this stage, there is no guarantee that your application will be confirmed and you will be hired for this job. This is due to the fact that, perhaps, the employer will receive several applies from technicians, of which he will confirm only the applies that are beneficial and relevant to him. If your apply is rejected, then the job card for which you applied and your apply will be automatically removed from the section Applied.',
  },
  {
    label: 'Not confirmed',
    route: '/hiring/applies/not-confirmed',
    status: HIRING_STATUSES.NOT_CONFIRMED,
    hint:
      'The employer has selected your application and is waiting for your confirmation. If this work is still relevant for you and you intend to complete it, try to contact the employer to discuss all the details. If everything suits you, click Confirm, thereby the deal between you will be confirmed.',
  },
  {
    label: 'Confirmed',
    route: '/hiring/applies/confirmed',
    status: HIRING_STATUSES.CONFIRMED,
    hint:
      'With the help of our site, you managed to conclude a deal with the employer, but at the same time, we are not an intermediary who can provide you with legal assistance and protection in disputable or conflict situations. In order to protect yourself and your business legally, conclude a contract with the employer, which will be legally binding. After fulfilling your obligations for the job, click the Job done button. If you cannot find the card of the job that you have completed, then look for this card in the section Finished. Since the employer could have pressed the Job done button first, thereby moving the job card to the section Finished.',
  },
  {
    label: 'Finished',
    route: '/hiring/applies/finished',
    status: HIRING_STATUSES.FINISHED,
    hint:
      'You see cards of jobs that you have already completed. Expanding the job card, you will see the Leave Feedback button. By clicking on which, you can leave your feedback to the employer. Please leave an honest review so that your friends and business partners could see the real and true employer rating and protect themselves, in the future, from unsuccessful business relationships.',
  },
  {
    label: 'Cancelled',
    route: '/hiring/applies/cancelled',
    status: HIRING_STATUSES.CANCELLED,
    hint:
      'Here are the cards of jobs that were canceled from the section Confirmed  by you or the party that hired you. Expanding the job card, you will see the Leave Feedback button. By clicking on which, you can leave your feedback to the employer. If necessary, leave an honest review so that your friends and business partners can see the real and true employer rating and protect themselves, in the future, from unsuccessful business relationships.',
  },
];

const customerHiringNavigationItems: HiringNavItem[] = [
  {
    label: 'Proposals',
    route: '/hiring/proposals',
    status: HIRING_STATUSES.PROPOSED,
    hint:
      'Select from the list of respondents for your job offer the most suitable candidate for you to do this job. Please contact him to discuss all the details and nuances. If you are satisfied with everything, you can press the button Hire and wait for confirmation from a potential technician.',
  },
  {
    label: 'Not confirmed',
    route: '/hiring/proposals/not-confirmed',
    status: HIRING_STATUSES.NOT_CONFIRMED,
    hint:
      'This page contains your job cards for which technicians were hired by you. You are waiting for the confirmation from the technician. When the technician confirms, your job card will go to the section Hired.',
  },
  {
    label: 'Hired',
    route: '/hiring/proposals/hired',
    status: HIRING_STATUSES.HIRED,
    hint:
      'With the help of our site, you managed to conclude a deal with a technician, but at the same time, we are not an intermediary who can provide you with legal assistance and protection in disputable or conflict situations. In order to protect yourself and your business legally, conclude a contract with the technician that will be legally binding. After fulfilling your obligations for the job, click the button Job done. If you cannot find the card of the job that was completed for you, then look for this card in the section Finished. Since the technician could have clicked the Job done button first, thereby moving the job card to the section Finished.',
  },
  {
    label: 'Finished',
    route: '/hiring/proposals/finished',
    status: HIRING_STATUSES.FINISHED,
    hint:
      'You can see the cards of the job that was done for you. Expanding the job card, you will see the button Leave Feedback. By clicking it, you can leave your feedback to the technician. Please leave honest feedback so that your friends and business partners can see the real and true rating of the technician and protect themselves, in the future, from unsuccessful business relationships.',
  },
  {
    label: 'Cancelled',
    route: '/hiring/proposals/cancelled',
    status: HIRING_STATUSES.CANCELLED,
    hint:
      'Here are job cards that were canceled from the section Confirmed by you or the party you hired. Expanding the job card, you will see the Leave Feedback button, by clicking on which you can leave your feedback to the technician. If necessary, leave an honest review so that your friends and business partners see the real and true rating of the technician and protect themselves, in the future, from unsuccessful business relationships.',
  },
];

const getCurrentPageByPathname = (pathname: string, navItems: HiringNavItem[]) =>
  navItems.find(n => n.route === pathname);

export {
  getCurrentPageByPathname,
  workerHiringNavigationItems,
  customerHiringNavigationItems,
};
