import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { withFormik, FormikProps } from 'formik';

import { USER_ROLES } from 'lib/user-roles';
import { AuthTemplate } from '../templates/auth';
import { pageUnmounted, invokeSignUp, $signUpError } from './model';
import {
  H3,
  Box,
  Text,
  Card,
  Form,
  Input,
  theme,
  NavLink,
  RSelect,
  BoxError,
  PrimaryButton,
} from 'ui';

export const SignUpPage = () => {
  const { t } = useTranslation();
  const error = useStore($signUpError);

  useEffect(() => () => pageUnmounted(), []);

  return (
    <AuthTemplate
      card={
        <Card>
          <SignUpForm />

          <SignUpActions />
        </Card>
      }
      error={error && <BoxError>{t(error.message)}</BoxError>}
    />
  );
};

const SignUpFormLayout = ({
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldValue,
}: FormikProps<ISignUpFormState>) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <H3 textAlign="center">{t('Registration')}</H3>

      <RSelect
        label="Role"
        error={touched.role && errors.role}
        onBlur={handleBlur}
        options={Object.values(USER_ROLES)}
        onChange={(option: any) => setFieldValue('role', option.value)}
        required={true}
      />

      <Input
        id="email"
        type="email"
        name="email"
        label="Email"
        value={values.email}
        error={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        required={true}
      />

      <Input
        id="password"
        type="password"
        name="password"
        label="Password"
        value={values.password}
        error={touched.password && errors.password}
        onBlur={handleBlur}
        onChange={handleChange}
        required={true}
      />

      <Text
        fontSize={0}
        color="UIDarkGray"
        lineHeight="15px"
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        By continuing, you agree to PDRDispatch
        <Link href="/privacy/terms-of-use.pdf">Terms of Service</Link> and
        <Link href="/privacy/privacy-policy.pdf">Privacy Policy</Link>.
      </Text>

      <Box mt={4} justifyContent="center">
        <PrimaryButton type="submit">{t('Sign up')}</PrimaryButton>
      </Box>
    </Form>
  );
};

export const SignUpActions = () => {
  const { t } = useTranslation();

  return (
    <Box mt={3} justifyContent="center">
      <Text mr={1} fontSize={theme.fontSizes[1]}>
        {t('Have account?')} -
      </Text>

      <NavLink to="/signin" color="UIDarkGray" fontSize={theme.fontSizes[1]}>
        {t('Sign In')}
      </NavLink>
    </Box>
  );
};

const SignUpForm = withFormik({
  mapPropsToValues: (): ISignUpFormState => ({
    email: '',
    role: '',
    password: '',
  }),

  validationSchema: Yup.object().shape({
    role: Yup.string().required('Role field is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Email field is required'),
    password: Yup.string().required('Password field is required'),
  }),

  handleSubmit: (values: ISignUpFormState) => invokeSignUp(values),
})(SignUpFormLayout);

export interface ISignUpFormState {
  role: string;
  email: string;
  password: string;
}

const Link = styled.a`
  padding: 0 3px;

  color: ${theme.colors.UIBlue};
`;
