import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from './text';
import { Label } from './input';
import { theme } from 'ui/theme';
import { FieldError } from './input';

interface ICheckboxInput {
  id?: string;
  name?: string;
  error?: boolean | string;
  value?: string | number;
  label?: string;
  checked?: boolean;
  required?: boolean;
  onChange?: (e: ChangeEvent) => void;
}

export const Checkbox = ({
  id,
  name,
  label,
  value,
  error,
  checked,
  onChange,
  required,
}: ICheckboxInput) => {
  const { t } = useTranslation();

  return (
    <CheckboxWrapper>
      <CheckboxNative
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />

      {label && (
        <Label htmlFor={id}>
          {required && (
            <Text color="UIRed" mr={0}>
              *
            </Text>
          )}

          {t(label)}
        </Label>
      )}

      {error && <FieldError>{t(error as string)}</FieldError>}
    </CheckboxWrapper>
  );
};

const CheckboxNative = styled.input``;

const CheckboxWrapper = styled.div`
  margin-bottom: 15px;

  ${CheckboxNative} {
    margin-right: ${theme.space[2]}px;

    display: none;

    &:checked + label:before {
      border-color: ${theme.colors.UIGray};
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  ${Label} {
    display: inline-block;
    min-height: 20px;
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: '';
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: -1px;
      width: 18px;
      height: 18px;
      border: 2px solid ${theme.colors.UIGray};
    }

    &:after {
      top: 3px;
      left: 4px;
      width: 10px;
      height: 10px;
      transform: scale(0);
      background-color: ${theme.colors.UIBlue};
    }
  }
`;
