import React from 'react';
import Select from 'react-select';
import { Props } from 'react-select/src/Select';
import { OptionsType } from 'react-select/src/types';
import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

import { Text } from './text';
import { ReactSelectStyles } from 'lib/lib-styles/react-select.styles';
import { FieldError, Label } from './input';

export interface IOption {
  value: any;
  label: string;
}

interface ISelectComponent {
  mb?: string;
  label?: string;
  error?: string | boolean | string[] | FormikErrors<IOption>[];
  options: OptionsType<IOption>;
  required?: boolean;
  allOption?: any;
  allowSelectAll?: boolean;
}

export const RSelect = ({
  mb = '15px',
  label,
  error,
  styles,
  options,
  required,
  allOption,
  components,
  allowSelectAll,
  // tslint:disable:trailing-comma
  ...props
}: ISelectComponent & Props<IOption>): React.ReactElement => {
  const { t } = useTranslation();
  const translatedOptions = options.map(({ value, label, ...rest }: any) => ({
    label: t(label),
    value: value,
    ...rest,
  }));
  const selectProps = {
    ...props,
    styles: {
      ...ReactSelectStyles(Boolean(error)),
      ...styles,
    },
    options: allowSelectAll ? [allOption, ...translatedOptions] : translatedOptions,
  };

  return (
    <div style={{ marginBottom: mb, width: '100%' }}>
      {label && (
        <Label disabled={props.isDisabled}>
          {required && (
            <Text color="UIRed" mr={0}>
              *
            </Text>
          )}

          {label}
        </Label>
      )}

      <Select {...selectProps} />

      {Boolean(error) && <FieldError>{t(error as string)}</FieldError>}
    </div>
  );
};
