import { request } from 'lib/request';

export type LeaveFeebackDTO = {
  id: string;
  type: string;
  data: {
    text: string;
    status: number;
    reason?: number;
  };
};

const leaveFeedback = ({ id, type, data }: LeaveFeebackDTO) =>
  request({ method: 'POST', url: `user/${type}/${id}/feedback`, data });

const getFeedbacks = (_: string, id: string) =>
  request({ method: 'GET', url: `profile/${id}/feedbacks` });

const getFeedback = (_: string, id: string, type: string) =>
  request({ method: 'GET', url: `user/${type}/${id}/feedback` });

export { getFeedbacks, leaveFeedback, getFeedback };
