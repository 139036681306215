import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik, Field } from 'formik';

import { IJob } from 'lib/types';
import { getProperty } from 'lib/get-object-property';
import { invokeJobUpdate } from 'pages/edit-job/model';
import { AddPicturesField } from './add-pictures-field';
import { EditPicturesField } from './edit-pictures-field';
import { CustomPricingOption } from './custom-pricing-option';
import { removeEmptyFieldsInObject } from 'lib/remove-blank-fields-in-object';
import { PRICING_TYPES, WORK_PLACE_OPTIONS } from '../lib/constants';
import { invokeJobCreation, jobCreationFetching } from '../model';
import {
  Datepicker,
  Timepicker,
  SlimLoader,
  ScrollToError,
  ButtonTooltip,
} from 'features/common';
import { AddressForm, PAYMENT_TYPES, MAX_TEXTAREA_LENGTH } from 'features/common';
import {
  CardWithTopBorder,
  Form,
  Box,
  PrimaryButton,
  Text,
  theme,
  RadioInput,
  RSelect,
  Textarea,
  Input,
} from 'ui';

const DAMAGE_TYPES = Object.freeze({
  HAIL_DAMAGE: 'hail',
  OTHER_DAMAGE: 'other',
});

const validationSchema = Yup.object().shape({
  continent: Yup.string().required('Continent field is required'),
  country: Yup.string().required('Country field is required'),
  state: Yup.string().required('State field is required'),
  city: Yup.string().required('City field is required'),
  work_place: Yup.string().required('Work place field is required'),
  work_start_date: Yup.date().required('Start date field is required'),
  payment_type: Yup.string().required('Payment type field is required'),
  pricing_type: Yup.string().required('Pricing type field is required'),
  damage_type: Yup.string().required('Damage type field is required'),
  price_from_car: Yup.string().when('pricing_type', {
    is: 'for_car',
    then: Yup.string().required('Price field is required'),
    otherwise: Yup.string(),
  }),
  price_from_percentage: Yup.string().when('pricing_type', {
    is: 'from_assessment',
    then: Yup.string().required('Price field is required'),
    otherwise: Yup.string(),
  }),
  job_info: Yup.string().max(
    MAX_TEXTAREA_LENGTH,
    `Job info length should not exceed ${MAX_TEXTAREA_LENGTH} characters`,
  ),
  additional_terms: Yup.string().max(
    MAX_TEXTAREA_LENGTH,
    `Additional terms length should not exceed ${MAX_TEXTAREA_LENGTH} characters`,
  ),
});

const NewEditJobLayout = ({
  job,
  values,
  errors,
  touched,
  handleBlur,
  handleSubmit,
  handleChange,
  setFieldValue,
  isSubmitting,
  isValidating,
}: FormikProps<INewJobFormState> & { job?: IJob }) => {
  const { t } = useTranslation();
  const isLoading = useStore(jobCreationFetching.isLoading);
  const isEditMode = Boolean(job);

  const handlePricingTypeChange = ({ value, label, fieldName }: any) => {
    setFieldValue('pricing_type', value);

    Object.values(PRICING_TYPES).forEach(x => {
      setFieldValue(x.fieldName, undefined);
    });

    if (value === PRICING_TYPES.BY_AGREEMENT.value) {
      setFieldValue(PRICING_TYPES.BY_AGREEMENT.fieldName, 1);
    }
  };

  return (
    <CardWithTopBorder>
      <Form onSubmit={handleSubmit}>
        <SectionTitle title={t('Job location')} />

        <AddressForm
          values={{
            country: values.country,
            address: values.address,
            city: values.city,
            continent: values.continent,
            state: values.state,
            postal_code: values.postal_code,
          }}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          validationSchema={validationSchema.fields}
          addressFieldHint="Please start your filling in with typing your address in an Address Field. For example: New York, USA"
        />

        <SectionTitle title={t('Job information')} />

        <Box justifyContent="space-around" mb={4}>
          <RadioInput
            id={DAMAGE_TYPES.HAIL_DAMAGE}
            name="damage_type"
            label="Hail damage"
            value={DAMAGE_TYPES.HAIL_DAMAGE}
            onChange={handleChange}
            checked={values.damage_type === DAMAGE_TYPES.HAIL_DAMAGE}
          />

          <RadioInput
            id={DAMAGE_TYPES.OTHER_DAMAGE}
            name="damage_type"
            label="Other damage"
            value={DAMAGE_TYPES.OTHER_DAMAGE}
            onChange={handleChange}
            checked={values.damage_type === DAMAGE_TYPES.OTHER_DAMAGE}
          />
        </Box>

        <RSelect
          label="Work place"
          error={touched.work_place && errors.work_place}
          value={WORK_PLACE_OPTIONS.find(x => x.value === values.work_place) || null}
          onBlur={handleBlur}
          options={WORK_PLACE_OPTIONS}
          onChange={(option: any) => setFieldValue('work_place', option.value)}
          required={true}
        />

        <Textarea
          id="job_info"
          name="job_info"
          label="Job info"
          value={values.job_info}
          error={touched.job_info && errors.job_info}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength={MAX_TEXTAREA_LENGTH}
        />

        {isEditMode ? (
          <EditPicturesField id={job?.id} pictures={job?.pictures} />
        ) : (
          <AddPicturesField />
        )}

        <Field
          id="work_start_date"
          name="work_start_date"
          label="Start date"
          error={touched.work_start_date && errors.work_start_date}
          value={values.work_start_date || ''}
          onChange={handleChange}
          required={true}
          component={Datepicker}
        />

        <Field
          id="work_start_time"
          name="work_start_time"
          label="Start time"
          error={touched.work_start_time && errors.work_start_time}
          value={values.work_start_time || ''}
          onChange={handleChange}
          component={Timepicker}
        />

        <SectionTitle title={t('Pricing and payment')} />

        <RSelect
          label="Payment type"
          error={touched.payment_type && errors.payment_type}
          value={
            Object.values(PAYMENT_TYPES).find(x => x.value === values.payment_type) ||
            null
          }
          onBlur={handleBlur}
          options={Object.values(PAYMENT_TYPES)}
          onChange={(option: any) => setFieldValue('payment_type', option.value)}
          required={true}
        />

        <RSelect
          label="Pricing type"
          error={touched.pricing_type && errors.pricing_type}
          onBlur={handleBlur}
          value={
            Object.values(PRICING_TYPES).find(x => x.value === values.pricing_type) ||
            null
          }
          options={Object.values(PRICING_TYPES)}
          onChange={(option: any) => handlePricingTypeChange(option)}
          formatOptionLabel={CustomPricingOption}
          required={true}
        />

        {Object.values(PRICING_TYPES).map((x, i) =>
          x.value === values.pricing_type &&
          values.pricing_type !== PRICING_TYPES.BY_AGREEMENT.value ? (
            <Input
              id={x.fieldName}
              key={i}
              icon={x.icon}
              name={x.fieldName}
              type="text"
              error={getProperty(errors, x.fieldName)}
              value={getProperty(values, x.fieldName) || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              required={true}
            />
          ) : null,
        )}

        <SectionTitle title="Additional information" />

        <Input
          id="order_id"
          name="order_id"
          type="text"
          label="Order ID"
          error={touched.order_id && errors.order_id}
          value={values.order_id || ''}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <Textarea
          id="additional_terms"
          name="additional_terms"
          label="Additional terms"
          value={values.additional_terms || ''}
          error={touched.additional_terms && errors.additional_terms}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength={MAX_TEXTAREA_LENGTH}
        />

        <Box mt="40px" justifyContent="center">
          {isLoading ? (
            <SlimLoader height="40px" width="40px" />
          ) : (
            <SubmitButton isEditMode={isEditMode} />
          )}
        </Box>

        <ScrollToError<INewJobFormState>
          errors={errors}
          isSubmitting={isSubmitting}
          isValidating={isValidating}
        />
      </Form>
    </CardWithTopBorder>
  );
};

export const NewEditJob = withFormik({
  mapPropsToValues: ({ job }: { job?: IJob }): INewJobFormState => ({
    continent: job?.continent || '',
    country: job?.country || '',
    address: job?.address || '',
    city: job?.city || '',
    state: job?.state || '',
    postal_code: job?.postal_code || '',
    damage_type: job?.damage_type || 'hail',
    job_info: job?.job_info || '',
    work_place: job?.work_place || '',
    work_start_date: job?.work_start_date || '',
    work_start_time: job?.work_start_time || '',
    payment_type: job?.payment_type || '',
    pricing_type: job?.pricing_type || '',
    order_id: job?.order_id || '',
    additional_terms: job?.additional_terms || '',
    price_from_car: job?.price_from_car || undefined,
    price_from_percentage: job?.price_from_percentage || undefined,
  }),

  validationSchema,

  handleSubmit: (values: INewJobFormState, { props }) => {
    props.job
      ? invokeJobUpdate(
          removeEmptyFieldsInObject<INewJobFormState & IJob>(
            Object.assign({}, props.job, values),
          ),
        )
      : invokeJobCreation(removeEmptyFieldsInObject<INewJobFormState>(values));
  },
})(NewEditJobLayout);

export type INewJobFormState = {
  continent: string;
  country: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  damage_type: string;
  job_info: string;
  work_place: string;
  work_start_date: Date | null | string;
  work_start_time: string;
  price_from_percentage?: number | undefined;
  price_from_car?: number | undefined;
  payment_type: string;
  pricing_type: string;
  price_by_agreement?: number | undefined;
  order_id: string;
  additional_terms: string;
};

const SectionTitle = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <Box my="40px" justifyContent="center">
      <Text fontSize={theme.fontSizes[4]} fontWeight="bold">
        {t(title)}
      </Text>
    </Box>
  );
};

const SubmitButton = ({ isEditMode }: { isEditMode: boolean }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {isEditMode ? (
        <PrimaryButton type="submit">{t('Edit job')}</PrimaryButton>
      ) : (
        <ButtonTooltip tooltip={'Now your job will be visible to job seekers.'}>
          <PrimaryButton type="submit">{t('Post job')}</PrimaryButton>
        </ButtonTooltip>
      )}
    </Fragment>
  );
};
