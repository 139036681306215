import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { $enviroment } from 'lib/config';
import { history } from 'lib/routing';
import { tokenDropped, $token } from 'lib/token';

const { API_URL } = $enviroment.getState();
const ERROR_MESSAGES = Object.freeze({
  UNAUTHENTICATED: 'Unauthenticated.',
});

const client = axios.create({
  baseURL: API_URL,
});

// TOKEN EXPIRATION INTERCEPTOR
client.interceptors.response.use(
  x => x,
  error => {
    const data = error.response.data;

    if (data.status === 400 && data.message === ERROR_MESSAGES.UNAUTHENTICATED) {
      history.replace('/signin');
      tokenDropped();
    }

    return Promise.reject(error);
  },
);

// SET AUTHORIZATION TOKEN
client.interceptors.request.use(config => {
  const token = $token.getState();

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

// REQUEST WRAPPER
export const request = (options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    console.debug('Request successfull!', response);

    return response.data;
  };

  const onError = (error: AxiosError) => {
    const { response } = error;

    if (response) {
      console.error(response.status);
      console.error(response.headers);
      console.error(response.data);
    }

    return Promise.reject(response && response.data);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};
