import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

import { RSelect, Card, Box, Text } from 'ui';

const PAGINATION_OPTIONS = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

interface IJobsPagination {
  totalJobs: number;
  currentPage: number;
  itemsPerPage: number;
  triggerPagination: (payload: { per_page: number; current_page: number }) => void;
}

export const JobsPagination = ({
  totalJobs,
  currentPage,
  itemsPerPage,
  triggerPagination,
}: IJobsPagination) => {
  const { t } = useTranslation();
  const pageCount = Math.ceil(totalJobs / itemsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [itemsPerPage, currentPage]);

  return (
    <Card>
      <Box
        justifyContent="space-between"
        flexDirection={['column', 'row']}
        alignItems={['center']}
      >
        <Box order={[1, 0]} mt={[3, '0px']} flexDirection="column">
          <Box alignItems="center">
            <Text fontWeight="bold" mr={2}>
              {t('Jobs per page')}:
            </Text>

            <Box width="70px">
              <RSelect
                mb="0px"
                options={PAGINATION_OPTIONS}
                value={PAGINATION_OPTIONS.find(x => x.value === itemsPerPage)}
                onChange={x =>
                  triggerPagination({
                    current_page: 1,
                    per_page: (x as any).value,
                  })
                }
              />
            </Box>
          </Box>

          <Text fontSize="14px">
            Total jobs: <b>{totalJobs}</b>
          </Text>
        </Box>

        <ReactPaginate
          pageCount={pageCount ? pageCount : 1}
          nextLabel=">"
          onPageChange={x =>
            triggerPagination({ per_page: itemsPerPage, current_page: x.selected + 1 })
          }
          previousLabel="<"
          containerClassName="react-paginate"
          pageRangeDisplayed={1}
          marginPagesDisplayed={2}
        />
      </Box>
    </Card>
  );
};
