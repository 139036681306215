import React from 'react';
import styled from 'styled-components';
import { useTable, CellProps } from 'react-table';

import { history } from 'lib/routing';
import { theme, Image, Box } from 'ui';
import { setSearchConfig } from 'pages/search-job/model';
import { GetAllSearchesDTO } from 'api/jobs';
import { invokeDeleteSearch } from 'pages/searches/model';
import { removeEmptyFieldsInObject } from 'lib/remove-blank-fields-in-object';
import {
  DAMAGE_TYPES,
  PAYMENT_TYPES_OPTIONS,
  READY_TO_WORK_OPTIONS,
} from '../lib/constants';

export const SearchesTable = ({ searches }: { searches: GetAllSearchesDTO[] }) => {
  const data = React.useMemo(() => searches, [searches]);
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Continent(s)',
        accessor: 'continents',
        Cell: ({ cell }: CellProps<GetAllSearchesDTO>) => {
          return cell.value.join(', ');
        },
      },
      {
        Header: 'Country(s)',
        accessor: 'countries',
        Cell: ({ cell: { value } }: CellProps<GetAllSearchesDTO>) => {
          return value ? value.join(', ') : '-';
        },
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell: ({ cell: { value } }: CellProps<GetAllSearchesDTO>) => {
          return value ? value : '-';
        },
      },
      {
        Header: 'Radius',
        accessor: 'radius',
        Cell: ({ cell: { value } }: CellProps<GetAllSearchesDTO>) => {
          return value ? value : '-';
        },
      },
      {
        Header: 'Damage type',
        accessor: 'damage_type',
        Cell: ({ cell: { value } }: CellProps<GetAllSearchesDTO>) => {
          const type = DAMAGE_TYPES.find(x => x.value === value);

          return type ? type.label : '-';
        },
      },
      {
        Header: 'Payment type',
        accessor: 'payment_type',
        Cell: ({ cell: { value } }: CellProps<GetAllSearchesDTO>) => {
          const type = PAYMENT_TYPES_OPTIONS.find(x => x.value === value);

          return type ? type.label : '-';
        },
      },
      {
        Header: 'Ready to work',
        accessor: 'ready_to_work',
        Cell: ({ cell: { value } }: CellProps<GetAllSearchesDTO>) => {
          const type = READY_TO_WORK_OPTIONS.find(x => x.value === value);

          if (type) {
            const { value, label } = type;

            return value === READY_TO_WORK_OPTIONS[0].value ? label : `${value} ${label}`;
          }
        },
      },
      {
        Header: 'Actions',
        accessor: '',
        Cell: ({ cell }: CellProps<GetAllSearchesDTO>) => {
          const searchID = cell.row.original.id;

          return (
            <Box m="-8px" height="35px" justifyContent="center" isPointer={true}>
              <Box
                width="100%"
                alignItems="center"
                justifyContent="center"
                borderRight="1px solid"
                borderColor="UIBlack"
                isPointer={true}
                onClick={() => {
                  const searchConfig = removeEmptyFieldsInObject(cell.row.original);
                  setSearchConfig(searchConfig);
                  history.push('/jobs/search');
                }}
              >
                <Image height="15px" src={theme.images.searchIcon} />
              </Box>

              <Box
                width="100%"
                onClick={() => invokeDeleteSearch(searchID)}
                alignItems="center"
                justifyContent="center"
              >
                <Image height="15px" src={theme.images.deleteSearchIcon} />
              </Box>
            </Box>
          );
        },
      },
    ],
    [],
  );
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    /* tslint:disable */
    <Table {...getTableProps()}>
      <thead>
        <Row>
          {headers.map((column, i) => (
            <HeaderCell {...column.getHeaderProps()}>
              {column.render('Header')}
            </HeaderCell>
          ))}
        </Row>
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Row {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                return <Cell {...cell.getCellProps()}>{cell.render('Cell')}</Cell>;
              })}
            </Row>
          );
        })}
      </tbody>
    </Table>
    /* tslint:enable */
  );
};

const Table = styled.table`
  width: 100%;
  margin: 0 40px;

  box-shadow: 0px 4px 59px -5px rgba(0, 0, 0, 0.14);
  border-spacing: 0;
  border: 1px solid ${theme.colors.UIBlack};
`;

const Row = styled.tr`
  :last-child {
    td {
      border-bottom: 0;
    }
  }
`;

const Cell = styled.td`
  margin: 0;
  padding: ${theme.space[2]}px;

  border-bottom: 1px solid ${theme.colors.UIBlack};
  border-right: 1px solid ${theme.colors.UIBlack};

  :last-child {
    border-right: 0;
  }
`;

const HeaderCell = styled.th`
  margin: 0;
  padding: ${theme.space[2]}px;

  text-align: left;

  border-bottom: 1px solid ${theme.colors.UIBlack};
  border-right: 1px solid ${theme.colors.UIBlack};

  :last-child {
    border-right: 0;
  }
`;
