import React, { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikTouched } from 'formik';
import { countries as countriesList } from 'countries-list';

import { Box, RSelect } from 'ui';
import { ISearchFormState } from './search-bar';
import { CONTINENT_OPTIONS, resetFields } from 'features/common';
import { USA_COUNTRY_NAME, USA_STATES_OPTIONS } from '../lib/constants';

interface ISearchRegion {
  values: ISearchFormState;
  errors: FormikErrors<ISearchFormState>;
  touched: FormikTouched<ISearchFormState>;
  handleBlur: (e: FocusEvent) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export const SearchRegion = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
}: ISearchRegion) => {
  const { t } = useTranslation();
  const { continents, countries } = values;

  const continentCodes = continents ? continents.map(x => x.code) : [];
  const countriesByContinent = Object.values(countriesList)
    .filter(x => continentCodes.includes(x.continent))
    .map(y => ({ label: y.name, value: y.name }));

  return (
    <Box px="25px" flexDirection="column" mb={1}>
      <RSelect
        id="continents"
        name="continents"
        label="Continent"
        value={continents || null}
        error={
          ((touched.continents as unknown) as boolean) && (errors.continents as string)
        }
        onBlur={handleBlur}
        options={CONTINENT_OPTIONS}
        onChange={(option: any) => {
          resetFields(['countries', 'states'], x => setFieldValue(x, ''));
          setFieldValue('continents', option);
        }}
        isMulti={true}
        required={true}
        getOptionLabel={x => t(x.label)}
      />

      <RSelect
        id="countries"
        name="countries"
        label="Country"
        error={
          !!countriesByContinent.length &&
          ((touched.countries as unknown) as boolean) &&
          (errors.countries as string)
        }
        onBlur={handleBlur}
        value={countries || null}
        isMulti={true}
        options={countriesByContinent}
        onChange={(option: any) => {
          resetFields(['states'], x => setFieldValue(x, ''));
          setFieldValue('countries', option);
        }}
        isDisabled={!countriesByContinent.length}
        getOptionLabel={x => t(x.label)}
      />

      {countries && countries.length === 1 && countries[0].value === USA_COUNTRY_NAME && (
        <RSelect
          id="states"
          name="states"
          label="State"
          value={values.states || null}
          onBlur={handleBlur}
          isMulti={true}
          options={USA_STATES_OPTIONS}
          onChange={(option: any) => {
            setFieldValue('states', option);
          }}
        />
      )}
    </Box>
  );
};
