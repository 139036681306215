import React, { Fragment } from 'react';
import { useStore } from 'effector-react';

import { Box } from 'ui/components';
import { IUser } from 'lib/types';
import { PageLoader } from 'features/common';
import { $profile, ProfileGate, profileFetching } from 'pages/profile';

interface IDashboardTemplate {
  render: (profile: IUser) => React.ReactNode;
  renderHeader: (profile: IUser) => React.ReactNode;
}

export const DashboardTemplate = ({ render, renderHeader }: IDashboardTemplate) => {
  const profile = useStore($profile);
  const isLoading = useStore(profileFetching.isLoading);

  return (
    <Box flexDirection="column" mb="90px">
      {!profile && <ProfileGate />}

      {profile && (
        <Fragment>
          <Box position="fixed" left="0px" top="0px" width={1} zIndex={10}>
            {renderHeader(profile)}
          </Box>

          {profile && (
            <Box justifyContent="center" height="100%" mt="120px">
              {render(profile)}
            </Box>
          )}
        </Fragment>
      )}

      {isLoading && <PageLoader />}
    </Box>
  );
};
