import { SuccessCard } from 'features/common';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthTemplate } from '../templates/auth';

export const SignUpSuccess = () => {
  const { t } = useTranslation();
  return (
    <AuthTemplate
      card={
        <SuccessCard
          title={t(
            `Your account has been successfully created! You need to verify your email address.
            If you do not receive a verification email within a few minutes of signing up, please check your spam folder.
            If so, select the confirmation message and click Not Spam, which will allow future messages to get through.`,
          )}
          route="/signin"
        />
      }
    />
  );
};
