import React from 'react';

import { Box } from 'ui';

interface IProfileInfoTemplate {
  styles?: {
    [key: string]: string;
  };
  info: React.ReactNode;
  title?: React.ReactNode;
}

export const ProfileInfoTemplate = ({ styles, title, info }: IProfileInfoTemplate) => (
  <Box
    {...styles}
    width={1}
    style={{ boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)' }}
    position="relative"
    borderTop="5px solid"
    borderColor="UIBlue"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    {title}

    <Box width={1} alignItems="flex-start" flexDirection="column" pl="30px" mb="14px">
      {info}
    </Box>
  </Box>
);
