import React, { ChangeEvent, FocusEvent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { Box } from './box';
import { Text } from './text';
import { theme } from 'ui/theme';
import { FieldBox, Label } from './input';

interface ITextarea {
  id: string;
  label: string;
  error?: boolean | string;
  name?: string;
  value: string;
  disabled?: boolean;
  onBlur: (e: FocusEvent) => void;
  onChange: (e: ChangeEvent) => void;
  required?: boolean;
  placeholder?: string;
  autoComplete?: string;
  maxLength?: number;
}

export const Textarea = ({
  id,
  name,
  error,
  label,
  value,
  onBlur,
  onChange,
  disabled,
  required,
  autoComplete,
  maxLength,
}: ITextarea) => {
  const { t } = useTranslation();
  const [isFocused, setFocused] = useState(Boolean(value));
  const [fieldLength, setFieldLength] = useState(0);
  const symbolsLeft = maxLength && maxLength - fieldLength;

  return (
    <FieldBox>
      <Label htmlFor={id}>
        {required && (
          <Text color="UIRed" mr={0}>
            *
          </Text>
        )}

        {t(label)}
      </Label>

      <TextareaNative
        id={id}
        name={name}
        rows={7}
        value={value}
        error={Boolean(error)}
        onBlur={e => {
          if (!value) {
            setFocused(false);
          }

          onBlur(e);
        }}
        onFocus={() => setFocused(true)}
        disabled={disabled}
        onChange={e => {
          onChange(e);
          setFieldLength(e.target.value.length);
        }}
        isFocused={isFocused}
        autoComplete={autoComplete}
      />

      {!!symbolsLeft && symbolsLeft >= 0 && (
        <Box width={1} justifyContent="flex-end" position="absolute">
          <SymbolsLeftHint>
            <Trans i18nKey="Textarea symbols left">
              You have {{ symbolsLeft }} characters left
            </Trans>
          </SymbolsLeftHint>
        </Box>
      )}

      {error && <TextareaError>{t(error as string)}</TextareaError>}
    </FieldBox>
  );
};

const TextareaNative = styled.textarea<{ error: boolean; isFocused: boolean }>`
  width: 100%;
  padding: 10px 12px;

  resize: none;
  outline: none;
  border: 1px solid ${theme.colors.UIGray};

  ${({ isFocused }) =>
    isFocused &&
    `
    border-color: ${theme.colors.UIBlue};
  `}

  ${({ error }) =>
    error &&
    `
    color: ${theme.colors.UIRed};
    border: 1px solid ${theme.colors.UIRed};
  `}
`;

const TextareaError = styled.span`
  display: block;
  margin-top: 2px;

  color: ${theme.colors.UIRed};
  font-size: ${theme.fontSizes[0]}px;
`;

const SymbolsLeftHint = styled.span`
  display: block;
  margin-top: 2px;

  color: ${theme.colors.UIDarkGray};
  font-size: ${theme.fontSizes[0]}px;
`;
