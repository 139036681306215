import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { IUser } from 'lib/types/user';
import { IFeedback } from 'lib/types';
import { InfoTitle } from './info-title';
import { PageLoader } from 'features/common';
import { JobUserInfo } from 'features/jobs';
import { getFeedbacks } from 'api/feedbacks';
import { Box, Card, Text, theme } from 'ui';
import { ProfileInfoTemplate } from '../templates/profile-info-template';
import { FEEDBACK_STATUS, FEEDBACK_REASON_OPTIONS } from 'features/hiring';

export const FeedbacksInfo = ({ user }: { user: IUser }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery<IFeedback[]>(
    [`get-feedbacks`, id || user.id],
    getFeedbacks,
  );

  if (!data) {
    return null;
  }

  const feedbackFromUser = data.find(x => x.author_id === user.id);

  return (
    <ProfileInfoTemplate
      styles={{ mt: '40px', position: 'relative' }}
      title={<InfoTitle title="Feedbacks" />}
      info={
        <Fragment>
          {Boolean(feedbackFromUser) && (
            <Box pb={4}>
              <Text>{t('Your rating')}:</Text>

              <FeedbackStatus status={feedbackFromUser?.status as number} />
            </Box>
          )}

          <StyledTabs style={{ marginTop: Boolean(feedbackFromUser) ? '40px' : '0px ' }}>
            <TabList>
              <Tab>
                {t('All')} ({data.length})
              </Tab>

              <Tab>
                {t('Positive')} ({data.filter(x => x.status).length})
              </Tab>

              <Tab>
                {t('Negative')} ({data.filter(x => !x.status).length})
              </Tab>
            </TabList>

            <TabPanel>
              {data.map(f => (
                <FeedbackItem {...f} key={f.id} />
              ))}
            </TabPanel>

            <TabPanel>
              {data
                .filter(x => x.status)
                .map(f => (
                  <FeedbackItem {...f} key={f.id} />
                ))}
            </TabPanel>

            <TabPanel>
              {data
                .filter(x => !x.status)
                .map(f => (
                  <FeedbackItem {...f} key={f.id} />
                ))}
            </TabPanel>
          </StyledTabs>

          {isLoading && <PageLoader />}
        </Fragment>
      }
    ></ProfileInfoTemplate>
  );
};

const FeedbackItem = ({ text, status, reason, author, created_at }: IFeedback) => {
  const { t } = useTranslation();
  const reasonLabel = FEEDBACK_REASON_OPTIONS.find(x => x.value === reason)?.label;

  return (
    <Card style={{ marginBottom: '20px' }}>
      <JobUserInfo user={author} postedDate={created_at} />

      <Box flexDirection="column">
        {reasonLabel && (
          <Text fontWeight="bold" mb={2}>
            {t(reasonLabel)}:
          </Text>
        )}

        <TruncatedText text={text} />
      </Box>
    </Card>
  );
};

export const FeedbackStatus = ({ status }: { status: number }) => {
  const { t } = useTranslation();

  return <Status status={status}>{t(FEEDBACK_STATUS[status])}</Status>;
};

const TruncatedText = ({ text, length = 100 }: { text: string; length?: number }) => {
  const [isTruncated, setTruncated] = useState(true);

  if (text.length < length) {
    return <Text>{text}</Text>;
  }

  return (
    <Fragment>
      <Text>{isTruncated ? `${text.slice(0, length)}...` : text}</Text>

      <Text
        mt={1}
        onClick={() => setTruncated(state => !state)}
        style={{
          cursor: 'pointer',
          textAlign: 'right',
          fontStyle: 'italic',
          textDecoration: 'underline',
        }}
      >
        {isTruncated ? 'Show more' : 'Show less'}
      </Text>
    </Fragment>
  );
};

export const Status = styled(Text)<{ status: number }>`
  margin-left: 10px;
  padding-left: 20px;
  position: relative;

  :after {
    content: '';
    width: 15px;
    height: 15px;

    position: absolute;
    left: 0px;

    border-radius: 3px;
    background-color: ${({ status }) =>
      status ? theme.colors.UIGreen : theme.colors.UIRed};
  }
`;

const StyledTabs = styled(Tabs)`
  position: absolute;
  left: 0;

  width: 100%;

  .react-tabs__tab-list {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    background-color: ${theme.colors.UIWhite};
    border-bottom: 3px solid ${theme.colors.UIGray};
  }

  .react-tabs__tab-panel {
    margin-bottom: 40px;
  }

  .react-tabs__tab {
    border-width: 3px;
    border-radius: 0;

    flex: 1;
    bottom: -3px;
    padding: 13px 25px;
    text-align: center;

    &--selected {
      border-color: ${theme.colors.UIBlue};
    }

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
`;
