import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Image, theme, SecondaryButton } from 'ui';

interface IPicturesField {
  pictures: (File | string)[];
  handlePictureDeleting: (i: number, picture: File | string) => void;
  handlePictureSelection: (picture: File | string) => void;
}

export const PicturesField = ({
  pictures,
  handlePictureDeleting,
  handlePictureSelection,
}: IPicturesField) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedJobPicture, selectJobPicture] = useState<number | null>(null);
  const [isDeleteJobPictureVisible, setDeleteJobPictureVisible] = useState(false);

  return (
    <Box flexDirection="column" mt="-15px" mb="15px">
      <Input
        ref={inputRef}
        type="file"
        styles={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
        accept="image/jpeg,image/png"
        onChange={e => {
          const target = e.target as HTMLInputElement;

          if (target.files) {
            handlePictureSelection(target.files[0]);
            target.value = '';
          }
        }}
      />

      <Box>
        {pictures.map((picture, i) => (
          <Box
            key={i}
            mb="20px"
            mr={3}
            position="relative"
            onMouseOver={() => {
              selectJobPicture(i);
              setDeleteJobPictureVisible(true);
            }}
            onMouseLeave={() => {
              selectJobPicture(null);
              setDeleteJobPictureVisible(false);
            }}
          >
            <Image
              src={picture instanceof File ? URL.createObjectURL(picture) : picture}
              width="70px"
              height="70px"
              isPointer={true}
            />

            {isDeleteJobPictureVisible && selectedJobPicture === i && (
              <Image
                src={theme.images.deleteFileIcon}
                style={{
                  position: 'absolute',
                  top: '-13px',
                  left: '87%',
                }}
                onClick={() => handlePictureDeleting(i, picture)}
                isPointer={true}
              />
            )}
          </Box>
        ))}
      </Box>

      {pictures.length !== 3 && (
        <SecondaryButton
          type="button"
          style={{ width: '100%' }}
          onClick={() => inputRef.current && inputRef.current.click()}
        >
          {t('+ Add picture')}
        </SecondaryButton>
      )}
    </Box>
  );
};
