import React, { Fragment, useEffect, useCallback } from 'react';
import { FormikState, FieldValidator } from 'formik';

import { SEARCH_TYPE } from '../lib/constants';
import { initialValues } from '../lib/form-initial-values';
import { Box, RadioInput } from 'ui';
import { IContinentOption } from 'features/common';
import { ISearchFormState, SectionTitle } from './search-bar';
import { validateField, validateArrayField } from '../lib/field';

interface ISearchType {
  values: ISearchFormState;
  isRegionType: boolean;
  resetForm: (nextState?: FormikState<ISearchFormState>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  registerField: (
    name: string,
    fns: {
      validate?: FieldValidator | undefined;
    },
  ) => void;
  unregisterField: (name: string) => void;
}

interface IField {
  name: string;
  validate: ((x: any) => string) | undefined;
}

export const SearchType = ({
  values,
  resetForm,
  isRegionType,
  setFieldValue,
  registerField,
  unregisterField,
}: ISearchType) => {
  const regionSearchFields = [
    {
      name: 'continents',
      validate: (x: any) =>
        validateArrayField<IContinentOption>(x, 'Continent is required'),
    },
  ];
  const citySearchFields = [
    { name: 'city', validate: (x: any) => validateField(x, 'City is required') },
    { name: 'state', validate: (x: any) => validateField(x, 'State is required') },
    { name: 'radius', validate: (x: any) => validateField(x, 'Radius is required') },
    { name: 'country', validate: (x: any) => validateField(x, 'Country is required') },
    {
      name: 'continent',
      validate: (x: any) => validateField(x, 'Continent is required'),
    },
    { name: 'postal_code', validate: undefined },
  ];

  // UTILS FOR REGISTRATION/UNREGISTRATION/SET VALUE FUNCTIONS
  const registerFields = useCallback(
    (fields: Array<IField>) => {
      fields.forEach(({ name, validate }) =>
        registerField(name, {
          validate,
        }),
      );
    },
    [registerField],
  );
  const setFieldsValue = (fields: Array<IField>) =>
    fields.forEach(x => setFieldValue(x.name, ''));
  const unregisterFields = (fields: Array<IField>) =>
    fields.forEach(x => unregisterField(x.name));

  // HANDLE RAGION CHANGE REGISTER/UNREGISTER FIELDS REGARDING SEARCH TYPE
  const handleRadioChange = () => {
    // CLEAR SEARCH CONFIG BY CHANGING SEARCH TYPE AND RESET FORM
    localStorage.removeItem('search-config');
    resetForm({
      values: initialValues,
      errors: {},
      touched: {},
      isSubmitting: false,
      submitCount: 0,
      isValidating: false,
    });

    if (isRegionType) {
      registerFields(citySearchFields);
      setFieldsValue(citySearchFields);

      unregisterFields(regionSearchFields);
    } else {
      registerFields(regionSearchFields);
      setFieldsValue(regionSearchFields);

      unregisterFields(citySearchFields);
    }

    return isRegionType
      ? setFieldValue('type', SEARCH_TYPE.CITY)
      : setFieldValue('type', SEARCH_TYPE.REGION);
  };

  // REGISTER REGIONS FIELDS INITIALLY
  useEffect(() => {
    if (isRegionType) {
      registerFields(regionSearchFields);
    }
  }, [regionSearchFields, registerFields, isRegionType]);

  return (
    <Fragment>
      <SectionTitle title="Job Location" />

      <Box px="25px">
        <Box mr={3}>
          <RadioInput
            id="region_type"
            name="type"
            label="Region search"
            value={SEARCH_TYPE.REGION}
            onChange={() => handleRadioChange()}
            checked={values.type === SEARCH_TYPE.REGION}
          />
        </Box>

        <RadioInput
          id="city_type"
          name="type"
          label="City search"
          value={SEARCH_TYPE.CITY}
          onChange={() => handleRadioChange()}
          checked={values.type === SEARCH_TYPE.CITY}
        />
      </Box>
    </Fragment>
  );
};
