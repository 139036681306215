import { IOption } from 'ui';

export const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const convertSelectOptionsToArray = (value: IOption[]) => value.map(x => x.value);

export const convertArrayToSelectOptions = (value: string[] | undefined): IOption[] =>
  Array.isArray(value)
    ? value.map(x => ({ label: capitalizeFirstLetter(x), value: x }))
    : [];
