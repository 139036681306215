import { request } from 'lib/request';

export type ProfileDTO = {
  viber: string;
  whatsapp: string;
  address: string;
  username: string;
  description: string;
  since_years_in_business: number;
  country: string;
  city: string;
  state: string;
  postal_code: string;
  phone: string;
  languages: string[];
  contact_method: string[];
  telegram: string;
  continent: string;
};

const createProfile = (data: ProfileDTO) =>
  request({ method: 'POST', url: '/profile', data });

const getProfile = () => request({ method: 'GET', url: '/profile' });

const updateProfile = (data: ProfileDTO) =>
  request({ method: 'PUT', url: '/profile', data });

const loadAvatar = (body: File) => {
  const data = new FormData();

  data.append('avatar', body);

  return request({
    method: 'POST',
    url: '/profile/avatar',
    data,
  });
};

const getProfileById = (_: string, id: string) =>
  request({
    method: 'GET',
    url: `/profile/${id}`,
  });

export { getProfile, loadAvatar, createProfile, updateProfile, getProfileById };
