import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from '../lib/detect-outside-click';
import { capitalizeFirstLetter } from 'lib/react-select-format-array';
import { Box, Image, theme, Text } from 'ui';
import { LANGUAGES, LANGUAGE_LABELS } from 'i18n';

export const LanguageSelector = () => {
  const ref = useRef(null);
  const { i18n } = useTranslation();
  const [isVisibleLanguagesMenu, setVisibleLanguagesMenu] = useState(false);
  useOutsideClick(ref, setVisibleLanguagesMenu);

  return (
    <Box position="relative">
      <Box width="35px" isPointer={true} onClick={() => setVisibleLanguagesMenu(true)}>
        <Text mr={1} fontWeight="bold">
          {capitalizeFirstLetter(i18n.language)}
        </Text>

        <Image src={theme.images.arrowDownIcon} />
      </Box>

      {isVisibleLanguagesMenu && (
        <Box
          py={2}
          ref={ref}
          width="150px"
          position="absolute"
          top="50px"
          right="calc(100% - 32px)"
          flexDirection="column"
          backgroundColor="UIWhite"
          style={{
            boxShadow: '0px 4px 59px rgba(0, 0, 0, 0.14)',
          }}
          zIndex={99999}
        >
          {Object.values(LANGUAGES).map((x, i) => (
            <Language
              pl={3}
              py={2}
              key={i}
              onClick={() => {
                setVisibleLanguagesMenu(false);
                i18n.changeLanguage(x);
              }}
              isActive={x === i18n.language}
              isPointer={true}
            >
              {capitalizeFirstLetter(LANGUAGE_LABELS[x])}
            </Language>
          ))}
        </Box>
      )}
    </Box>
  );
};

const Language = styled(Text)<{ isActive: boolean }>`
  border-left: 4px solid transparent;

  :hover {
    background-color: ${lighten(0.16, theme.colors.UIGray)};
  }

  ${({ isActive }) =>
    isActive &&
    `
      border-left: 4px solid ${theme.colors.UIBlue};
    `}
`;
