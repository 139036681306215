import { createEvent, createEffect, forward } from 'effector';

import { history } from 'lib/routing';
import { updateJob } from 'api/jobs';
import { IJob, IResponse, IError } from 'lib/types';

export const invokeJobUpdate = createEvent<IJob>();
const jobUpdatingEffect = createEffect<IJob, IResponse<IJob[]>, IError>();

jobUpdatingEffect.use(x => updateJob(x));

jobUpdatingEffect.done.watch(x => history.push('/jobs'));

forward({
  from: invokeJobUpdate,
  to: jobUpdatingEffect,
});
