import React from 'react';

import { Box, Image, Text } from 'ui';

export const CustomPricingOption = ({ label, icon }: any) => {
  return (
    <Box alignItems="center">
      <Image src={icon} width="15px" height="15px" /> <Text ml={2}>{label}</Text>
    </Box>
  );
};
