import { createGate } from 'effector-react';
import { createEffect, forward, createStore, createEvent } from 'effector';

import { getProfile } from 'api/profile';
import { IError, IUser, IResponse } from 'lib/types';
import { createFetching } from 'lib/fetching';

export const $profile = createStore<IUser | null>(null);
export const ProfileGate = createGate();
const profileLoadingEffect = createEffect<void, IResponse<{ user: IUser }>, IError>();
export const profileFetching = createFetching(profileLoadingEffect);
export const resetProfile = createEvent();

profileLoadingEffect.use(() => getProfile());

$profile
  .on(profileLoadingEffect.done, (_, { result }) => result.data.user)
  .reset(resetProfile);

forward({
  from: ProfileGate.open,
  to: profileLoadingEffect,
});
