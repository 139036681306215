import React from 'react';

import { Box, Text } from 'ui';
import { useTranslation } from 'react-i18next';

export const InfoTitle = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <Box
      py="20px"
      pl="30px"
      mb="30px"
      width={1}
      borderBottom="1px solid"
      borderColor="UIGray"
    >
      <Text fontSize={4} fontWeight="bold">
        {t(title)}
      </Text>
    </Box>
  );
};
