export const SEARCH_TYPE = Object.freeze({
  REGION: 0,
  CITY: 1,
});

export const USA_STATES_OPTIONS = [
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'AL', value: 'AL' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' },
];

export const PAYMENT_TYPES_OPTIONS = [
  { label: 'Cash', value: 'cash' },
  { label: 'Check', value: 'check' },
  { label: 'Bank Transfer', value: 'transfer' },
  { label: 'All', value: 'all' },
];

export const DAMAGE_TYPES = [
  { label: 'Hail', value: 'hail' },
  { label: 'Other', value: 'other' },
  { label: 'All', value: 'all' },
];

export const READY_TO_WORK_OPTIONS = [
  { label: 'Today', value: 0 },
  { label: 'day', value: 1 },
  { label: 'days', value: 2 },
  { label: 'days', value: 3 },
  { label: 'days', value: 4 },
  { label: 'days', value: 5 },
  { label: 'days', value: 6 },
  { label: 'days', value: 7 },
  { label: 'days', value: 10 },
  { label: 'days', value: 15 },
  { label: 'days', value: 20 },
  { label: 'days', value: 30 },
];

export const HIGHLIGHT_LISTINGS_OPTIONS = [
  { label: 'hour', value: 1 },
  { label: 'hours', value: 2 },
  { label: 'hours', value: 4 },
  { label: 'hours', value: 8 },
  { label: 'hours', value: 16 },
  { label: 'hours', value: 24 },
];

export const SORT_BY_OPTIONS = [
  { label: 'Location', value: 0 },
  { label: 'Post date', value: 1 },
  { label: 'Price', value: 2 },
  { label: 'Percent', value: 3 },
];

export const USA_COUNTRY_NAME = 'United States';
