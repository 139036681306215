import styled from 'styled-components';

import { theme } from 'ui/theme';

export const Card = styled.div`
  width: 100%;
  padding: 26px 40px;
  display: flex;
  flex-flow: column;
  flex-shrink: 0;

  box-shadow: 0px 4px 59px rgba(0, 0, 0, 0.14);
`;

export const CardWithTopBorder = styled(Card)`
  border-top: 5px solid ${theme.colors.UIBlue};
`;
