import { request } from 'lib/request';

export type ApplyJobDTO = {
  init_price?: number;
  final_price?: number;
  cover_letter: string;
  by_agreement?: boolean;
};

const applyJob = (jobID: string, data: ApplyJobDTO) =>
  request({ method: 'POST', url: `/job/${jobID}/apply`, data });

const checkApply = (_: string, jobID: string) =>
  request({ method: 'GET', url: `/job/${jobID}/apply/activeExists` });

const getApplies = (_: string, status: number) =>
  request({ method: 'GET', url: '/user/applies', params: { status } });

const getApply = (_: string, id: string, status: number) =>
  request({ method: 'GET', url: `/user/job/${id}/applies`, params: { status } });

const declineApply = (id: string) =>
  request({ method: 'POST', url: `/user/applies/${id}/cancel` });

const confirmApply = (id: string) =>
  request({ method: 'POST', url: `/user/applies/${id}/confirm` });

const cancelApply = (id: string) =>
  request({ method: 'POST', url: `/user/applies/${id}/cancel` });

const getAppliesStatistic = (_: string) =>
  request({ method: 'GET', url: `/user/applies/statistics` });

export {
  applyJob,
  getApply,
  getApplies,
  checkApply,
  cancelApply,
  declineApply,
  confirmApply,
  getAppliesStatistic,
};
