import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IJob } from 'lib/types/job';
import { history } from 'lib/routing';
import { useStore } from 'effector-react';
import { $profile } from 'pages/profile';
import { formatDate } from 'lib/format-date';
import { JobUserInfo } from './job-user-info';
import { JobInfoCell } from './job-info-cell';
import { invokeDeleteJob } from '../model';
import { JobPaymentSection } from './job-payment-section';
import { JobPicturesCarousel } from './job-pictures-carousel';
import { capitalizeFirstLetter } from 'lib/react-select-format-array';
import { JobLocationInfo, JobMapInfo } from './job-location-info';
import { SectionTemplate, SectionTitle } from './job-section';
import { Box, Text, WarnButton, PrimaryButton, theme } from 'ui';
import { ButtonTooltip } from 'features/common';

type FullJobCardProps = {
  job: IJob;
  withActionsBar?: boolean;
};

export const FullJobCard = ({ job, withActionsBar = true }: FullJobCardProps) => {
  const {
    id,
    damage_type,
    work_start_date,
    work_place,
    user,
    created_at,
    job_info,
    pictures,
    continent,
    country,
    state,
    city,
    user_id,
    payment_type,
    price_by_agreement,
    price_from_car,
    price_from_percentage,
    pricing_type,
    order_id,
    additional_terms,
    work_start_time,
  } = job;
  const profile = useStore($profile);

  const CardActions =
    profile && user_id === profile.id ? (
      <JobActions jobID={id} />
    ) : (
      <ApplyJobAction jobID={id} />
    );

  return (
    <Box
      width={['100%', '100%', '550px', '650px']}
      flexDirection="column"
      mx={['10px', '10px', '0px']}
      position="relative"
    >
      <Box
        p={['20px', '20px 40px']}
        width={['100%', '100%', '550px', '650px']}
        style={{ boxShadow: '0px 4px 59px rgba(0, 0, 0, 0.14)' }}
        flexDirection="column"
      >
        <JobUserInfo user={user} postedDate={created_at} />

        <JobInformation
          workPlace={work_place}
          damageType={damage_type}
          workStartDate={work_start_date}
          workStartTime={work_start_time}
        />

        {job_info && (
          <SectionTemplate>
            <SectionTitle title="Description" />

            <Text style={{ wordBreak: 'break-all' }}>{job_info}</Text>
          </SectionTemplate>
        )}

        {!!JSON.parse(pictures).length && (
          <SectionTemplate>
            <SectionTitle title="Job pictures" />

            <JobPicturesCarousel pictures={pictures} />
          </SectionTemplate>
        )}

        <SectionTemplate>
          <SectionTitle title="Payment information" />

          <JobPaymentSection
            pricingType={pricing_type}
            paymentType={payment_type}
            paymentValues={{ price_by_agreement, price_from_percentage, price_from_car }}
          />
        </SectionTemplate>

        {(order_id || additional_terms) && (
          <JobAdditionalInfo orderID={order_id} additionalTerms={additional_terms} />
        )}

        <JobLocation location={{ continent, country, state, city }} />
      </Box>

      {withActionsBar && CardActions}

      <Box
        position="absolute"
        top="-13px"
        right="-13px"
        width="25px"
        height="25px"
        borderRadius="50%"
        backgroundColor="UIBlue"
        alignItems="center"
        justifyContent="center"
        isPointer={true}
        onClick={() => history.goBack()}
      >
        <Text color="UIWhite">&#10005;</Text>
      </Box>
    </Box>
  );
};

const JobInformation = ({
  workPlace,
  damageType,
  workStartDate,
  workStartTime,
}: {
  workPlace: string;
  damageType: string;
  workStartDate: string;
  workStartTime: string;
}) => {
  return (
    <SectionTemplate>
      <SectionTitle title="Job information" />

      <Box flexDirection={['column', 'row']}>
        <Box flexDirection="column" mr="50px">
          <Box>
            <JobInfoCell title="Damage type" value={capitalizeFirstLetter(damageType)} />
          </Box>

          <Box>
            <JobInfoCell title="Work place" value={capitalizeFirstLetter(workPlace)} />
          </Box>
        </Box>

        <Box flexDirection="column">
          <Box>
            <JobInfoCell title="Start date" value={formatDate(workStartDate)} />
          </Box>

          {workStartTime && (
            <Box>
              <JobInfoCell title="Start time" value={workStartTime} />
            </Box>
          )}
        </Box>
      </Box>
    </SectionTemplate>
  );
};

interface IJobLocation {
  location: {
    continent: string;
    country: string;
    state: string;
    city: string;
  };
}

const JobLocation = ({ location }: IJobLocation) => (
  <SectionTemplate withDivider={false}>
    <SectionTitle title="Job location" />

    <Box mb={3}>
      <JobLocationInfo location={location} />
    </Box>

    <JobMapInfo location={location} />
  </SectionTemplate>
);

const JobAdditionalInfo = ({
  orderID,
  additionalTerms,
}: {
  orderID: string;
  additionalTerms: string;
}) => (
  <SectionTemplate>
    <SectionTitle title="Additional information" />

    <Box flexDirection="column">
      {orderID && <JobInfoCell title="Order ID" value={orderID} />}

      {additionalTerms && (
        <JobInfoCell title="Additional terms" value={additionalTerms} />
      )}
    </Box>
  </SectionTemplate>
);

const ApplyJobAction = ({ jobID }: { jobID: number }) => {
  const { t } = useTranslation();

  return (
    <Box mt={4} justifyContent="center">
      <ButtonTooltip tooltip="You are going to apply for this job.">
        <ApplyLink to={`/jobs/apply/${jobID}`} target="_blank">
          {t('Apply')}
        </ApplyLink>
      </ButtonTooltip>
    </Box>
  );
};

const JobActions = ({ jobID }: { jobID: number }) => {
  const { t } = useTranslation();

  return (
    <Box mt={4} justifyContent="center">
      <Box mr={2}>
        <ButtonTooltip tooltip="You can edit your job card. Something can be added or corrected.">
          <PrimaryButton onClick={() => history.push(`/jobs/edit/${jobID}`)}>
            {t('Edit')}
          </PrimaryButton>
        </ButtonTooltip>
      </Box>

      <ButtonTooltip tooltip="Your job card will be deleted from the following path: Post Job > <u>My posted Jobs.</u> The job card will also no longer be visible in Search for everyone. ">
        <WarnButton onClick={() => invokeDeleteJob(jobID)}>{t('Delete')}</WarnButton>
      </ButtonTooltip>
    </Box>
  );
};

const ApplyLink = styled(Link)`
  color: ${theme.colors.UIWhite};
  font-weight: bold;
  text-decoration: none;

  padding: 10px 16px;

  cursor: pointer;
  border: 1px solid ${theme.colors.UIWhite};
  outline: none;
  transition: all 0.1s linear;
  background-color: ${theme.colors.UIBlue};

  :hover {
    border-color: ${theme.colors.UIBlue};
  }
`;
