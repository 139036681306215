import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IUser } from 'lib/types';
import { Box, Text, Image, theme, NavLink } from 'ui';

export const MobileDropdownMenu = ({
  user,
  title,
  links,
}: {
  user: IUser;
  title: { label: string; field: string };
  links: Array<{ title: string; route: string; isProposalOrApplyLink: boolean }>;
}) => {
  const { t } = useTranslation();
  const [isVisibleJobsMenu, setVisibleJobsMenu] = useState(false);

  return (
    <Box
      py="15px"
      px="25px"
      borderBottom="1px solid"
      borderColor="UIGray"
      flexDirection="column"
    >
      <Box
        width={1}
        onClick={() => setVisibleJobsMenu(!isVisibleJobsMenu)}
        isPointer={true}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box alignItems="center">
          <Text
            fontWeight="bold"
            isPointer={true}
            onClick={() => setVisibleJobsMenu(true)}
            color={isVisibleJobsMenu ? theme.colors.UIBlue : theme.colors.UIBlack}
          >
            {t(title.label)}
          </Text>

          {user.minified_statistics[title.field].hasNew && (
            <Image
              src={theme.images.alertIcon}
              height="20px"
              style={{ marginLeft: '7px' }}
            />
          )}
        </Box>

        <Image
          src={
            isVisibleJobsMenu ? theme.images.arrowTopBlueIcon : theme.images.arrowDownIcon
          }
          height="10px"
        />
      </Box>

      {isVisibleJobsMenu && (
        <Box flexDirection="column" mt={3}>
          {links.map((x, i) => (
            <JobLinkWrapper
              key={i}
              hasNewStatistic={
                x.isProposalOrApplyLink && user.minified_statistics[title.field].hasNew
              }
            >
              <JobLink to={x.route} activeClassName="active">
                {t(x.title)}
              </JobLink>
            </JobLinkWrapper>
          ))}
        </Box>
      )}
    </Box>
  );
};

const JobLink = styled(NavLink)`
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;

  color: ${theme.colors.UIBlack};
  border-left: 4px solid transparent;
  text-decoration: none;

  &.active {
    border-left: 4px solid ${theme.colors.UIBlue};
  }
`;

const JobLinkWrapper = styled.div<{ hasNewStatistic: boolean }>`
  > ${JobLink} {
    position: relative;

    ${({ hasNewStatistic }) =>
      hasNewStatistic &&
      `
      &:after {
        content: '';

        background: url(${theme.images.alertIcon});
        background-position: center center;
        background-size: contain;

        width: 20px;
        height: 20px;

        position: absolute;
        transform: translateX(7px);
      }
    `}
  }
`;
