import { createStore, createEvent, createEffect, forward } from 'effector';

import { resetProfile } from 'pages/profile/view/model';
import { createFetching } from 'lib/fetching';
import { IError, IResponse, IUser } from 'lib/types';
import { ProfileDTO, createProfile } from 'api/profile';
import { GeneralInfo, IGeneralInfoFormState } from './components/general-info';
import { AddressInfo, IAddressInfoFormState } from './components/address-info';
import { ContactInfo, IContactInfoFormState } from './components/contact-info';
import { history } from 'lib/routing';

// WIZZARD MODEL
export const STEP_INDEXES = Object.freeze({
  GENERAL_INFO: 0,
  ADDRESS_INFO: 1,
  CONTACT_INFO: 2,
});

export const $steps = createStore([
  { component: GeneralInfo },
  { component: AddressInfo },
  { component: ContactInfo },
]);
export const $currentStep = createStore(STEP_INDEXES.GENERAL_INFO);
export const changeCurrentStep = createEvent<number>();

$currentStep.on(changeCurrentStep, (_, payload) => payload);

// PROFILE MODEL
export type OnboardingDataType = ProfileDTO | null;
export const $onboardingData = createStore<OnboardingDataType>(null);
export const populateProfileData = createEvent<
  IGeneralInfoFormState | IAddressInfoFormState | IContactInfoFormState
>();
export const invokeProfileCreation = createEvent<ProfileDTO>();
const profileCreationEffect = createEffect<
  ProfileDTO,
  IResponse<{ user: IUser }>,
  IError
>();
export const profileCreationFetching = createFetching(profileCreationEffect);

profileCreationEffect.use(x => createProfile(x));

profileCreationEffect.done.watch(() => {
  resetProfile();
  history.push('/');
});

$onboardingData.on(populateProfileData, (state, payload) =>
  Object.assign({}, state, payload),
);

forward({
  from: invokeProfileCreation,
  to: profileCreationEffect,
});
