import React from 'react';

import { Box, theme, Text } from 'ui';

export const Initials = ({
  username,
  onClick,
}: {
  username: string;
  onClick?: () => void;
}) => (
  <Box
    style={{ borderRadius: '50%', backgroundColor: theme.colors.UIBlue }}
    width="35px"
    height="35px"
    onClick={onClick}
    isPointer={true}
    alignItems="center"
    justifyContent="center"
  >
    <Text color="UIWhite" fontWeight="bold" lineHeight="23px">
      {username[0]}
    </Text>
  </Box>
);
