import { createGate } from 'effector-react';
import { createEffect, createStore, createEvent } from 'effector';

import { createFetching } from 'lib/fetching';
import { IResponse, IError, IJob } from 'lib/types';
import { searchJobs, ListJobsDTO } from 'api/jobs';

export type ISearchConfig = {
  init_search?: boolean;
  type?: number;
  city?: string;
  postal_code?: string;
  damage_type?: string;
  ready_to_work?: number;
  payment_type?: string;
  min_pay_per_vehicle?: number;
  min_percent_pay?: number;
  highlight_listings?: number;
  highlighted_on_top?: boolean;
  sort_by?: number;
  search_save?: number;
  radius?: number;
  continents?: string[];
  countries?: string[];
  states?: string[];
  address?: string;
};

export interface IPaginationConfig {
  page: number;
  total?: number;
  per_page: number;
}

// SEARCH
export const searchConfigFromLocalStorage = JSON.parse(
  localStorage.getItem('search-config') as string,
);

export const $searchConfig = createStore<ISearchConfig>(
  searchConfigFromLocalStorage || { init_search: true },
);
export const $searchJobs = createStore<IJob[] | null>(null);
export const SearchJobsGate = createGate<ISearchConfig>();
const searchJobsLoadingEffect = createEffect<
  ISearchConfig,
  IResponse<ListJobsDTO>,
  IError
>();
export const searchJobsFetching = createFetching(searchJobsLoadingEffect);
export const setSearchConfig = createEvent<ISearchConfig | void>();

searchJobsLoadingEffect.use(x => searchJobs(x));
$searchJobs.on(searchJobsLoadingEffect.done, (_, { result }) => result.data.data);
$searchConfig.on(setSearchConfig, (_, payload) => payload);

setSearchConfig.watch(x => {
  const searchConfig = $searchConfig.getState();
  const { page, per_page } = $paginationConfig.getState();
  const configForLoadJobs = Object.assign({}, x || searchConfig, { page, per_page });

  if (x && !x.init_search) {
    localStorage.setItem('search-config', JSON.stringify(x));
  }

  searchJobsLoadingEffect(configForLoadJobs);
});

// PAGINATION
export const $paginationConfig = createStore<IPaginationConfig>({
  page: 1,
  per_page: 20,
});
export const setPaginationConfig = createEvent<IPaginationConfig>();

$paginationConfig
  .on(setPaginationConfig, (state, payload) => Object.assign({}, state, payload))
  .on(searchJobsLoadingEffect.done, (_, { result }) => {
    const { total, per_page, current_page } = result.data;

    return { total, page: current_page, per_page: parseInt(per_page, 10) };
  });

setPaginationConfig.watch(x => {
  const searchConfig = $searchConfig.getState();

  searchJobsLoadingEffect(Object.assign({}, searchConfig, x as ISearchConfig));
});
