import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';

import { IUser } from 'lib/types/user';
import { ProfileDTO } from 'api/profile';
import { AvatarLoader } from './avatar-loader';
import { invokeProfileUpdate } from 'pages/profile/edit/model';
import {
  CONTACT_METHOD_OPTIONS,
  AddressForm,
  LANGUAGE_OPTIONS,
  ScrollToError,
} from 'features/common';
import {
  convertArrayToSelectOptions,
  convertSelectOptionsToArray,
} from 'lib/react-select-format-array';
import {
  Form,
  Input,
  PrimaryButton,
  RSelect,
  Textarea,
  IOption,
  Text,
  theme,
  Box,
} from 'ui';

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username field is required'),
  since_years_in_business: Yup.string().required(
    'Since year in business field is required',
  ),
  languages: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.mixed().default() }))
    .nullable()
    .required('Language of communication is field required'),
  contact_method: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.mixed().default() }))
    .nullable()
    .required('Contact method field is required'),
  phone: Yup.string().required('Phone field is required'),
  address: Yup.string().required('Address field is required'),
  continent: Yup.string().required('Continent field is required'),
  country: Yup.string().required('Country field is required'),
  state: Yup.string().required('State field is required'),
  city: Yup.string().required('City field is required'),
  postal_code: Yup.number().required('Postal code field is required'),
  viber: Yup.string(),
  whatsapp: Yup.string(),
  telegram: Yup.string(),
  description: Yup.string(),
});

const EditProfileLayout = ({
  values,
  errors,
  profile,
  touched,
  handleBlur,
  handleSubmit,
  handleChange,
  isSubmitting,
  isValidating,
  setFieldValue,
}: FormikProps<IEditProfileState> & { profile: IUser }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <SectionTitle title="Main information" />

      <AvatarLoader src={profile.avatar} />

      <Input
        id="username"
        name="username"
        type="text"
        label="Username"
        error={touched.username && errors.username}
        value={values.username}
        onBlur={handleBlur}
        onChange={handleChange}
        required={true}
      />

      <Textarea
        id="description"
        name="description"
        label="Description"
        value={values.description}
        error={touched.description && errors.description}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Input
        id="since_years_in_business"
        name="since_years_in_business"
        type="number"
        label="In business since"
        error={touched.since_years_in_business && errors.since_years_in_business}
        value={values.since_years_in_business || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        required={true}
      />

      <SectionTitle title="Contact info" />

      <RSelect
        id="languages"
        name="languages"
        label="Language of communication"
        error={touched.languages && errors.languages}
        value={values.languages}
        isMulti={true}
        options={LANGUAGE_OPTIONS}
        onBlur={handleBlur}
        onChange={(option: any) => setFieldValue('languages', option)}
        required={true}
        getOptionLabel={x => t(x.label)}
      />

      <RSelect
        id="contact_method"
        name="contact_method"
        label="Contact method"
        value={values.contact_method}
        error={touched.contact_method && errors.contact_method}
        isMulti={true}
        options={CONTACT_METHOD_OPTIONS}
        onBlur={handleBlur}
        onChange={(option: any) => setFieldValue('contact_method', option)}
        required={true}
        getOptionLabel={x => t(x.label)}
      />

      <Input
        id="phone"
        name="phone"
        type="text"
        label="Phone"
        error={touched.phone && errors.phone}
        value={values.phone}
        onBlur={handleBlur}
        onChange={handleChange}
        required={true}
      />

      <Input
        id="viber"
        name="viber"
        type="text"
        label="Viber"
        value={values.viber}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Input
        id="whatsapp"
        name="whatsapp"
        type="text"
        label="Whatsapp"
        value={values.whatsapp}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Input
        id="telegram"
        name="telegram"
        type="text"
        label="Telegram"
        value={values.telegram}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <SectionTitle title="Location" />

      <AddressForm
        values={{
          country: values.country,
          address: values.address,
          city: values.city,
          continent: values.continent,
          state: values.state,
          postal_code: values.postal_code,
        }}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        validationSchema={validationSchema.fields}
      />

      <Box mt="40px" justifyContent="center">
        <PrimaryButton type="submit">{t('Save')}</PrimaryButton>
      </Box>

      <ScrollToError<IEditProfileState>
        errors={errors}
        isSubmitting={isSubmitting}
        isValidating={isValidating}
      />
    </Form>
  );
};

export const EditProfileForm = withFormik({
  mapPropsToValues: ({ profile }: { profile: IUser }): IEditProfileState => ({
    username: profile.username || '',
    description: profile.description || '',
    country: profile.country || '',
    city: profile.city || '',
    address: profile.address || '',
    state: profile.state || '',
    phone: profile.phone || '',
    languages: convertArrayToSelectOptions(profile.languages) || [],
    contact_method: convertArrayToSelectOptions(profile.contact_method) || [],
    whatsapp: profile.whatsapp || '',
    viber: profile.viber || '',
    continent: profile.continent || '',
    telegram: profile.telegram || '',
    postal_code: profile.postal_code || '',
    since_years_in_business: profile.since_years_in_business || undefined,
  }),

  validationSchema,

  handleSubmit: (values: IEditProfileState) => {
    const user = Object.assign({}, values, {
      languages: convertSelectOptionsToArray(values.languages),
      contact_method: convertSelectOptionsToArray(values.contact_method),
    });

    invokeProfileUpdate(user as ProfileDTO);
  },
})(EditProfileLayout);

export interface IEditProfileState {
  continent: string;
  username: string;
  description: string;
  postal_code: string;
  since_years_in_business: undefined | number;
  country: string;
  city: string;
  address: string;
  state: string;
  phone: string;
  languages: IOption[];
  contact_method: IOption[];
  whatsapp: string;
  viber: string;
  telegram: string;
}

const SectionTitle = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <Box my="40px" justifyContent="center">
      <Text fontSize={theme.fontSizes[4]} fontWeight="bold">
        {t(title)}
      </Text>
    </Box>
  );
};
