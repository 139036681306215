import React, { Fragment } from 'react';

import { IUser } from 'lib/types';
import { InfoCell } from './info-cell';
import { InfoTitle } from './info-title';
import { formatDate } from 'lib/format-date';
import { ProfileInfoTemplate } from '../templates/profile-info-template';

export const ReferenceInfo = ({ profile }: { profile: IUser }) => {
  return (
    <ProfileInfoTemplate
      title={<InfoTitle title="Reference" />}
      info={
        <Fragment>
          <InfoCell label="In PDRDispatch since" value={formatDate(profile.created_at)} />

          <InfoCell label="In business since" value={profile.since_years_in_business} />
        </Fragment>
      }
    />
  );
};
