import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { RouteComponentProps, withRouter } from 'react-router';

import { history } from 'lib/routing';
import { PageLoader } from 'features/common';
import { FullJobCard } from 'features/jobs';
import { getProposal, declineProposals } from 'api/proposals';
import { Box, WarnButton } from 'ui';
import { IJob, IResponse } from 'lib/types';
import { ApplicationCard, ProposalActions, HIRING_STATUSES } from 'features/hiring';

export const ProposalPage = withRouter(
  ({ match }: RouteComponentProps<{ id: string; status: string }>) => {
    const { t } = useTranslation();
    const { id, status } = match.params;
    const { data, isLoading } = useQuery<IResponse<IJob>>(
      ['proposal', id, status],
      getProposal,
    );
    const [declineProposalsMutation] = useMutation(declineProposals);

    const onDeclineProposals = async () => {
      try {
        await declineProposalsMutation(id);
        history.goBack();
      } catch (e) {}
    };

    return (
      <Fragment>
        {data && data.data.job_applies && (
          <Box pt="90px" alignItems="center" flexDirection="column">
            <Box mb="50px">
              <FullJobCard job={data.data} withActionsBar={false} />
            </Box>

            {parseInt(status) === HIRING_STATUSES.PROPOSED && (
              <Box mb="50px">
                <WarnButton onClick={() => onDeclineProposals()}>
                  {t('Decline all and delete')}
                </WarnButton>
              </Box>
            )}

            <Box mb="100px" flexDirection="column">
              {data.data.job_applies.map(apply => (
                <ApplicationCard key={apply.id} jobApply={apply}>
                  <ProposalActions
                    status={parseInt(status)}
                    userId={apply.worker_id.toString()}
                    proposalId={apply.id.toString()}
                  />
                </ApplicationCard>
              ))}
            </Box>
          </Box>
        )}

        {isLoading && <PageLoader />}
      </Fragment>
    );
  },
);
