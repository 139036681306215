import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

export const LANGUAGES = Object.freeze({
  EN: 'en',
  ES: 'es',
});

export const LANGUAGE_LABELS: { [key: string]: string } = Object.freeze({
  en: 'english',
  es: 'spanish',
});

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: LANGUAGES.EN,
    // debug: true,
    fallbackLng: LANGUAGES.EN,
    keySeparator: false,
    nsSeparator: false,
    interpolation: { escapeValue: false },
  });

export default i18n;
