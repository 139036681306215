import React, { useEffect, Fragment } from 'react';
import { useStore } from 'effector-react';

import { Header, SuccessCard } from 'features/common';
import { DashboardTemplate, Box, Text } from 'ui';
import { pageUnmounted, NewEditJob, jobCreationFetching, $apiError } from 'features/jobs';

export const NewJobPage = () => {
  const isDone = useStore(jobCreationFetching.isDone);
  const apiError = useStore($apiError);

  useEffect(() => () => pageUnmounted(), []);

  return (
    <DashboardTemplate
      render={() => (
        <Fragment>
          {!isDone ? (
            <Box
              px={['10px', '0']}
              mb="90px"
              width={['100%', '490px']}
              flexDirection="column"
            >
              <NewEditJob />

              {apiError && (
                <Text mt={4} color="UIRed" textAlign="center">
                  {apiError}
                </Text>
              )}
            </Box>
          ) : (
            <Box
              px={['10px', '0']}
              width={['100%', '450px']}
              position="absolute"
              top="50%"
              left="50%"
              style={{ transform: 'translate(-50%, -50%)' }}
            >
              <SuccessCard title="Your Job has been successfully posted!" route="/jobs" />
            </Box>
          )}
        </Fragment>
      )}
      renderHeader={profile => <Header profile={profile} />}
    />
  );
};
