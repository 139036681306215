import React, { Fragment, ChangeEvent, FocusEvent } from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { useTranslation } from 'react-i18next';
import { getCode, overwrite } from 'country-list';
import { countries as countriesList } from 'countries-list';

import { resetFields } from '../lib/form-helpers';
import { RSelect, Input } from 'ui';
import { CONTINENT_OPTIONS } from '../lib/select-options/continent-options';
import { GoogleAutocompleteInput } from './google-autocomplete-input';

interface IAddress {
  continent: string | undefined;
  country: string | undefined;
  address: string | undefined;
  city: string | undefined;
  state: string | undefined;
  postal_code: string | undefined;
}

interface IAddressForm {
  values: IAddress;
  errors: FormikErrors<IAddress>;
  touched: FormikTouched<IAddress>;
  handleBlur: (e: FocusEvent) => void;
  handleChange: (e: ChangeEvent) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  // TODO TYPE FOR YUP SCHEMA FIELDS
  validationSchema: any;
  addressFieldHint?: string;
}

export const AddressForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  validationSchema,
  addressFieldHint,
}: IAddressForm) => {
  // OVERWRITE THE COUNTRY CODES REGARDING GOOGLE RULES
  overwrite([
    {
      code: 'RU',
      name: 'Russia',
    },
    {
      code: 'US',
      name: 'United States',
    },
  ]);

  const { t } = useTranslation();

  const handleContinentChange = (option: any) => {
    const countryCode = getCode(values.country as string);
    const continentCode = CONTINENT_OPTIONS.find(x => x.value === option.value)?.code;
    const country = (countriesList as any)[countryCode as string];
    const continentByCountry = country ? country.continent : '';

    if (continentByCountry !== continentCode) {
      resetFields(['address', 'country', 'state', 'city', 'postal_code'], x =>
        setFieldValue(x, ''),
      );
    }

    setFieldValue('continent', option.value);
  };

  return (
    <Fragment>
      <Field
        label="Address"
        name="address"
        error={touched.address && errors.address}
        value={values.address}
        onChange={handleChange}
        component={GoogleAutocompleteInput}
        required={Boolean(validationSchema?.address)}
        addressFieldHint={addressFieldHint}
      />

      <RSelect
        id="continent"
        name="continent"
        label="Continent"
        error={touched.continent && errors.continent}
        value={CONTINENT_OPTIONS.find(x => x.value === values.continent) || null}
        onBlur={handleBlur}
        options={CONTINENT_OPTIONS}
        onChange={(option: any) => handleContinentChange(option)}
        required={Boolean(validationSchema?.continent)}
        getOptionLabel={x => t(x.label)}
      />

      <Input
        id="country"
        name="country"
        type="text"
        label="Country"
        error={touched.country && errors.country}
        value={values.country}
        onBlur={handleBlur}
        onChange={handleChange}
        required={Boolean(validationSchema?.country)}
      />

      <Input
        id="state"
        name="state"
        type="text"
        label="State"
        error={touched.state && errors.state}
        value={values.state}
        onBlur={handleBlur}
        onChange={handleChange}
        required={Boolean(validationSchema?.state)}
      />

      <Input
        id="city"
        name="city"
        type="text"
        label="City"
        error={touched.city && errors.city}
        value={values.city}
        onBlur={handleBlur}
        onChange={handleChange}
        required={Boolean(validationSchema?.city)}
      />

      <Input
        id="postal_code"
        name="postal_code"
        type="text"
        label="Postal code"
        error={touched.postal_code && errors.postal_code}
        value={values.postal_code || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        required={Boolean(validationSchema?.postal_code)}
      />
    </Fragment>
  );
};
