import React from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { useTranslation } from 'react-i18next';
import { FormikProps, FormikHandlers } from 'formik';

import { Box, Label, Text, FieldError } from 'ui';

interface ITimePicker {
  id: string;
  form: FormikProps<any>;
  field: {
    name: string;
    value: string;
    onBlur: FormikHandlers['handleBlur'];
    onChange: FormikHandlers['handleChange'];
  };
  label: string;
  error: string;
  required: boolean;
}

export const Timepicker = ({ id, form, field, label, error, required }: ITimePicker) => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="column">
      {label && (
        <Label htmlFor={id}>
          {required && (
            <Text color="UIRed" mr={0}>
              *
            </Text>
          )}

          {t(label)}
        </Label>
      )}

      <TimePicker
        showSecond={false}
        onChange={value => form.setFieldValue(field.name, value.format('HH:mm'))}
        // Library bug, we should use "any" here
        defaultValue={field.value ? (moment(field.value, 'xx') as any) : null}
        inputReadOnly={true}
      />

      {error && <FieldError>{t(error as string)}</FieldError>}
    </Box>
  );
};
