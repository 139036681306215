const Geocoder = new google.maps.Geocoder();

export const getPositionFromAddress = (
  address: string,
): Promise<{ lat: number; lng: number } | null> =>
  new Promise((resolve, reject) => {
    Geocoder.geocode({ address }, results => {
      if (Array.isArray(results) && results.length > 0) {
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }

      reject(null);
    });
  });
