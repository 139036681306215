import React from 'react';
import * as Yup from 'yup';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { withFormik, FormikProps } from 'formik';

import { AuthTemplate } from '../templates/auth';
import { invokeSignIn, $signInError } from './model';
import {
  H3,
  Box,
  Card,
  Form,
  Text,
  Input,
  theme,
  NavLink,
  BoxError,
  PrimaryButton,
} from 'ui';

export const SignInPage = () => {
  const { t } = useTranslation();
  const error = useStore($signInError);

  return (
    <AuthTemplate
      card={
        <Card>
          <SignInForm />
        </Card>
      }
      error={error && <BoxError>{t(error.message)}</BoxError>}
    />
  );
};

export const SignInFormLayout = ({
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  handleSubmit,
}: FormikProps<ISignInFormState>) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <H3 textAlign="center">{t('Sign In')}</H3>

      <Input
        id="email"
        name="email"
        type="text"
        label="Email"
        error={touched.email && errors.email}
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
        required={true}
      />

      <Input
        id="password"
        name="password"
        type="password"
        label="Password"
        error={touched.password && errors.password}
        value={values.password}
        onBlur={handleBlur}
        onChange={handleChange}
        required={true}
      />

      <Box mt={-2} justifyContent="flex-end">
        <NavLink to="/request-password" color="UIDarkGray" fontSize={theme.fontSizes[1]}>
          {t('Forgot password?')}
        </NavLink>
      </Box>

      <SignInActions />
    </Form>
  );
};

const SignInForm = withFormik({
  mapPropsToValues: (): ISignInFormState => ({
    email: '',
    password: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Email field is required'),
    password: Yup.string().required('Password field is required'),
  }),

  handleSubmit: (values: ISignInFormState) => invokeSignIn(values),
})(SignInFormLayout);

export interface ISignInFormState {
  email: string;
  password: string;
}

export const SignInActions = () => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="column" mt={4} alignItems="center">
      <PrimaryButton type="submit">{t('Sign in')}</PrimaryButton>

      <Box
        mt={3}
        justifyContent="center"
        flexDirection={['column', 'row']}
        alignItems="center"
      >
        <Text mr={1} fontSize={theme.fontSizes[1]}>
          {t('New to PDRDispatch?')} -
        </Text>

        <NavLink to="/signup" color="UIDarkGray" fontSize={theme.fontSizes[1]}>
          {t('Sign up here')}
        </NavLink>
      </Box>
    </Box>
  );
};
