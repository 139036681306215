import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Box, theme } from 'ui';

type IApplicationsQty = {
  applicationsQty: number | undefined;
  activeApplicationsQty: number | undefined;
};

export const ApplicationsQty = ({
  applicationsQty,
  activeApplicationsQty,
}: IApplicationsQty) => (
  <Fragment>
    {activeApplicationsQty ? (
      <QtyBox active={true} justifyContent="center" alignItems="center">
        {activeApplicationsQty}
      </QtyBox>
    ) : null}

    {applicationsQty && !activeApplicationsQty ? (
      <QtyBox active={false} justifyContent="center" alignItems="center">
        {applicationsQty}
      </QtyBox>
    ) : null}
  </Fragment>
);

const QtyBox = styled(Box)<{ active: boolean }>`
  width: 30px;
  height: 30px;

  border-radius: 50%;
  background-color: ${theme.colors.UIDarkGray};

  position: absolute;
  left: calc(100% - 50px);
  bottom: calc(0px + 15px);

  color: ${theme.colors.UIWhite};

  ${({ active }) =>
    active &&
    `
    background-color: ${theme.colors.UIOrange};
  `}
`;
