import { createEvent, createEffect, forward, createStore } from 'effector';

import { IError } from 'lib/types';
import { resetPassword } from 'api/auth';
import { createFetching } from 'lib/fetching';
import { IResetPasswordRequestType } from './page';

export const pageUnmounted = createEvent();
export const $resetPasswordError = createStore<IError | null>(null);
export const invokeResetPassword = createEvent<IResetPasswordRequestType>();
export const resetPasswordEffect = createEffect<IResetPasswordRequestType, any, IError>();
export const resetPasswordFetching = createFetching(resetPasswordEffect, 'loading', {
  reset: pageUnmounted,
});

resetPasswordEffect.use(x => resetPassword(x));

$resetPasswordError
  .on(resetPasswordEffect.fail, (_, { error }) => error)
  .reset(pageUnmounted, resetPasswordFetching.isDone);

forward({
  from: invokeResetPassword,
  to: resetPasswordEffect,
});
