import React from 'react';

import { ProgressBar } from '../components/progress-bar';
import { H3, Box, Card } from 'ui';

interface IOnboardingCardTemplate {
  title: string;
  children: React.ReactNode;
}

export const OnboardingCardTemplate = ({ title, children }: IOnboardingCardTemplate) => (
  <Box flexDirection="column" width={1} position="relative">
    <Card>
      <ProgressBar />

      <Box flexDirection="column" position="relative" mt={3}>
        <H3 textAlign="center">{title}</H3>

        {children}
      </Box>
    </Card>
  </Box>
);
