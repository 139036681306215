import styled from 'styled-components';
import { NavLink as L, NavLinkProps } from 'react-router-dom';
import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  color,
  ColorProps,
} from 'styled-system';

export const NavLink = styled(L)<
  SpaceProps & ColorProps & TypographyProps & NavLinkProps
>`
  ${space}
  ${color}
  ${typography}

  display: block;

  cursor: pointer;
  text-decoration: underline;
`;
