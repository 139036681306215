import React, { Fragment, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { JobCard, JobsPagination } from 'features/jobs';
import { DashboardTemplate, Box, Text } from 'ui';
import { Header, PageLoader, EmptyEntityCard } from 'features/common';
import {
  $postedJobs,
  PostedJobsGate,
  $paginationConfig,
  postedJobsFetching,
} from './model';

export const JobsPage = () => {
  const { t } = useTranslation();
  const isLoading = useStore(postedJobsFetching.isLoading);
  const postedJobs = useStore($postedJobs);
  const paginationConfig = useStore($paginationConfig);
  const [pagination, setPagination] = useState({ per_page: 20, current_page: 1 });
  const isPostedJobsExist = postedJobs && Boolean(postedJobs.length);

  return (
    <Fragment>
      <PostedJobsGate
        per_page={pagination.per_page}
        current_page={pagination.current_page}
      />

      <DashboardTemplate
        render={() => (
          <Fragment>
            {isPostedJobsExist && postedJobs && (
              <Box flexDirection="column" px={['10px', '0']} width={['100%', '510px']}>
                <Text fontWeight="bold" fontSize="4" mb={4}>
                  {t('My posted jobs')}:
                </Text>

                <Box mb={4} flexDirection="column">
                  {postedJobs.map((job, i) => (
                    <JobCard key={i} job={job} />
                  ))}
                </Box>

                {paginationConfig && (
                  <JobsPagination
                    totalJobs={paginationConfig.total as number}
                    currentPage={pagination.current_page}
                    itemsPerPage={paginationConfig.per_page}
                    triggerPagination={({ per_page, current_page }) =>
                      setPagination({ per_page, current_page })
                    }
                  />
                )}
              </Box>
            )}

            {!isPostedJobsExist && (
              <Box height="calc(100vh - 220px)" alignItems="center">
                <EmptyEntityCard
                  route="/jobs/new"
                  waringText="You haven't posted jobs yet."
                  warningButtonText="Create"
                />
              </Box>
            )}
          </Fragment>
        )}
        renderHeader={profile => <Header profile={profile} />}
      />

      {isLoading && <PageLoader />}
    </Fragment>
  );
};
