import React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Box, Text } from 'ui';
import { useParams } from 'react-router';
import { PageLoader } from 'features/common';
import { getFeedback } from 'api/feedbacks';
import { FeedbackForm } from 'features/hiring';
import { getProfileById } from 'api/profile';
import { FeedbackStatus } from 'features/profile';
import { IError, IFeedback, IResponse, IUser } from 'lib/types';

export const FeedbackPage = () => {
  const { t } = useTranslation();
  const { id, type, user_id } = useParams<{
    id: string;
    type: string;
    user_id: string;
  }>();
  const user = useQuery<IResponse<{ user: IUser }>>(
    [`user-${user_id}`, user_id],
    getProfileById,
  );
  const feedback = useQuery<IFeedback, IError>(['get-feedback', id, type], getFeedback);

  return (
    <Box mt="100px" mb="50px" width={1} justifyContent="center">
      <Box
        width={['100%', '100%', '550px', '650px']}
        flexDirection="column"
        mx={['10px', '10px', '0px']}
        position="relative"
      >
        {user.isSuccess && feedback.isSuccess && (
          <Box
            p={['20px', '40px']}
            width={['100%', '100%', '550px', '650px']}
            style={{ boxShadow: '0px 4px 59px rgba(0, 0, 0, 0.14)' }}
            flexDirection="column"
          >
            <Box mb={4} flexDirection="column">
              <Box mb={2}>
                <Text fontSize={4} mr={2}>
                  {t('Leave feedback for')}
                </Text>

                <Text fontSize={4} fontWeight="bold">
                  {user.data?.data.user.username}
                </Text>
              </Box>

              {feedback.data && (
                <Text>
                  {t('Your current rating for this user')}:
                  <FeedbackStatus status={feedback.data.status} />
                </Text>
              )}
            </Box>

            <FeedbackForm
              userId={user.data?.data.user.id as number}
              applyId={id}
              feedback={feedback.data}
              feedbackType={type}
            />
          </Box>
        )}
      </Box>

      {user.isLoading && feedback.isLoading && <PageLoader />}
    </Box>
  );
};
