import React from 'react';

import { Header } from 'features/common';
import { EditProfileForm } from 'features/profile';
import { DashboardTemplate, CardWithTopBorder, Box } from 'ui';

export const ProfileEditPage = () => (
  <DashboardTemplate
    render={profile => (
      <Box px={['10px', '0']} width={['100%', '490px']} flexDirection="column">
        <CardWithTopBorder>
          {profile && <EditProfileForm profile={profile} />}
        </CardWithTopBorder>
      </Box>
    )}
    renderHeader={profile => <Header profile={profile} />}
  />
);
