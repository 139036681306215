import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';

import { SlimLoader } from 'features/common';
import { Box, Image, theme, Input } from 'ui';
import { $avatar, invokeAvatarLoading, avatarFetching } from '../model';

export const AvatarLoader = ({ src }: { src?: string }) => {
  const avatar = useStore($avatar);
  const inputRef = useRef<HTMLInputElement>(null);
  const isLoading = useStore(avatarFetching.isLoading);

  const openUploadWindow = () => inputRef.current && inputRef.current.click();

  return (
    <Box mb={4} justifyContent="center">
      <Input
        ref={inputRef}
        type="file"
        styles={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
        accept="image/jpeg,image/png"
        onChange={e => {
          const target = e.target as HTMLInputElement;

          if (target.files) {
            invokeAvatarLoading(target.files[0]);
          }
        }}
      />

      <Box width="100px" height="100px" position="relative">
        {isLoading && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <SlimLoader />
          </Box>
        )}

        {!isLoading && (
          <AvatarImage
            src={avatar || src || theme.images.emptyAvatarIcon}
            alt="empty-avatar"
            onClick={openUploadWindow}
            isPointer={true}
          />
        )}

        {!isLoading && (
          <Box position="absolute" right="0" zIndex={9} onClick={openUploadWindow}>
            <Image src={theme.images.changeAvatarIcon} isPointer={true} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const AvatarImage = styled(Image)`
  width: 100px;
  height: 100px;

  border-radius: 50%;
`;
