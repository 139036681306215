import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { theme, Box, Image, Text } from 'ui';

export const PageHint = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const [isVisible, setVisibility] = useState(true);

  return (
    <Fragment>
      {isVisible && (
        <Hint p="25px" mb="50px" flexDirection="column" position="relative">
          <Box mb="20px" alignItems="center">
            <Image src={theme.images.hintIcon} />

            <Text ml="15px" fontWeight="bold">
              {title}
            </Text>
          </Box>

          <Text>{description}</Text>

          <CloseIcon
            src={theme.images.closeIcon}
            isPointer={true}
            onClick={() => setVisibility(state => !state)}
          />
        </Hint>
      )}
    </Fragment>
  );
};

const Hint = styled(Box)`
  border: 1px solid ${theme.colors.UIBlue};
`;

const CloseIcon = styled(Image)`
  position: absolute;
  top: 25px;
  right: 25px;
`;
