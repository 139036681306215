import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { FormikProps, FormikHandlers } from 'formik';
import PlacesAutocomplete, {
  Suggestion,
  geocodeByAddress,
} from 'react-places-autocomplete';

import { theme, Input, Box } from 'ui';

interface IGoogleAutocompleteInput {
  form: FormikProps<any>;
  field: {
    name: string;
    value: string;
    onBlur: FormikHandlers['handleBlur'];
    onChange: FormikHandlers['handleChange'];
  };
  label: string;
  error: string;
  required: boolean;
  disabled: boolean;
  autocompleteOptions?: {
    types: string[];
    componentRestrictions: {
      [key: string]: string;
    };
  };
  addressFieldHint?: string;
}

export const GoogleAutocompleteInput = ({
  form,
  label,
  error,
  field,
  required,
  disabled,
  autocompleteOptions,
  addressFieldHint,
}: IGoogleAutocompleteInput) => {
  const setFieldValue = (field: string, value: string) =>
    form.setFieldValue(field, value);

  const handleSelect = async (address: string): Promise<void> => {
    try {
      const geocodeResults = await geocodeByAddress(address);
      const addresses = geocodeResults[0].address_components;

      for (let result of addresses) {
        const addressType = result.types[0];

        if (GOOGLE_PLACES_TYPES[addressType]) {
          const value = (result as GeocoderAddressResult)[
            GOOGLE_PLACES_TYPES[addressType].googleTag
          ];
          const key = GOOGLE_PLACES_TYPES[addressType].formValue;

          setFieldValue('address', address);
          setFieldValue(key, value);
          setFieldValue('continent', '');
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PlacesAutocomplete
      value={field.value || ''}
      onChange={x => setFieldValue(field.name, x)}
      debounce={200}
      onSelect={handleSelect}
      searchOptions={autocompleteOptions}
    >
      {({
        getInputProps,
        getSuggestionItemProps,
        suggestions,
      }: any): React.ReactElement => (
        <Box position="relative" flexDirection="column">
          <Input
            name={field.name}
            type="text"
            error={error}
            {...getInputProps({
              hint: addressFieldHint,
              label,
              required,
              disabled,
            })}
          />

          {!!suggestions.length && (
            <Suggestions>
              {suggestions.map((suggestion: Suggestion, index: number) => (
                <SuggestionItem {...getSuggestionItemProps(suggestion)} key={index}>
                  {suggestion.description}
                </SuggestionItem>
              ))}
            </Suggestions>
          )}
        </Box>
      )}
    </PlacesAutocomplete>
  );
};

const Suggestions = styled.div`
  height: 150px;
  position: absolute;
  top: 60px;
  z-index: 10;

  overflow-y: scroll;
  background-color: ${theme.colors.UIWhite};
  border: 1px solid ${theme.colors.UIBlue};
`;

const SuggestionItem = styled.span`
  display: inline-block;

  width: 100%;
  padding: 10px 12px;

  cursor: pointer;
  border-bottom: 1px solid ${theme.colors.UIBlue};

  :hover {
    background-color: ${lighten(0.16, theme.colors.UIGray)};
  }

  $:last-child {
    border-bottom: 0;
  }
`;

const GOOGLE_PLACES_TYPES: {
  [key: string]: { formValue: string; googleTag: string };
} = {
  street_number: {
    formValue: 'streetNumber',
    googleTag: 'short_name',
  },
  route: {
    formValue: 'street',
    googleTag: 'long_name',
  },
  locality: {
    formValue: 'city',
    googleTag: 'long_name',
  },
  administrative_area_level_1: {
    formValue: 'state',
    googleTag: 'short_name',
  },
  country: {
    formValue: 'country',
    googleTag: 'long_name',
  },
  postal_code: {
    formValue: 'postal_code',
    googleTag: 'short_name',
  },
};

type GeocoderAddressResult = {
  [key: string]: string;
} & google.maps.GeocoderAddressComponent;
