import React from 'react';
import styled from 'styled-components';
import { useStore } from 'effector-react';

import { theme } from 'ui';
import { $currentStep, STEP_INDEXES } from '../model';

export const ProgressBar = (): React.ReactElement => {
  const currentStep = useStore($currentStep);

  return (
    <React.Fragment>
      <Bar />

      <Indicator currentStep={currentStep + 1} />
    </React.Fragment>
  );
};

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  height: 5px;

  background-color: ${theme.colors.UIGray};
`;

const Indicator = styled.div<{ currentStep: number }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;

  width: ${({ currentStep }) =>
    (currentStep * 100) / Object.keys(STEP_INDEXES).length + '%'};
  height: 5px;

  background-color: ${theme.colors.UIBlue};
`;
