import React from 'react';
import { useStore } from 'effector-react';

import { Box } from 'ui';
import { PageLoader } from 'features/common';
import { ProfileGate } from 'pages/profile';
import { FullJobCard } from 'features/jobs';
import { JobGate, $job, jobFetching } from './model';
import { withRouter, RouteComponentProps } from 'react-router';

export const JobPage = withRouter(({ match }: RouteComponentProps<{ id: string }>) => {
  const job = useStore($job);
  const isLoading = useStore(jobFetching.isLoading);

  return (
    <Box mt="100px" mb="50px" width={1} justifyContent="center">
      <JobGate id={match.params.id} />

      <ProfileGate />

      {job && <FullJobCard job={job} />}

      {isLoading && <PageLoader />}
    </Box>
  );
});
