import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from 'ui';

export const InfoCell = ({
  label,
  value,
}: {
  label: string;
  value: string | number | React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <Box flexDirection="column" mb="16px">
      <Text mb={1} fontWeight="bold">
        {t(label)}
      </Text>

      <Text>{value}</Text>
    </Box>
  );
};
