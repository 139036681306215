import { createEvent, createEffect, forward, createStore } from 'effector';

import { IError } from 'lib/types';
import { createFetching } from 'lib/fetching';
import { requestResetPassword } from 'api/auth';
import { IRequestPasswordFormState } from './page';

export const $requestResetPasswordError = createStore<IError | null>(null);
export const invokeRequestResetPassword = createEvent<IRequestPasswordFormState>();
export const pageUnmounted = createEvent();
export const requestResetPasswordEffect = createEffect<
  IRequestPasswordFormState,
  any,
  IError
>();
export const requestResetPasswordFething = createFetching(
  requestResetPasswordEffect,
  'loading',
  { reset: pageUnmounted },
);

requestResetPasswordEffect.use(x => requestResetPassword(x));

$requestResetPasswordError
  .on(requestResetPasswordEffect.fail, (_, { error }) => error)
  .reset(pageUnmounted, requestResetPasswordEffect.done);

forward({
  from: invokeRequestResetPassword,
  to: requestResetPasswordEffect,
});
