import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'ui/theme';
import { LANGUAGES } from 'i18n';
import { Box, Text } from 'ui/components';
import { CenterContentTemplate } from './center-content';

export const LanguageSelectorContentTemplate = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <CenterContentTemplate>
    <Box position={['static', 'absolute']} top="50px" right="100px" mb={5}>
      <LanguageSelector />
    </Box>

    {children}
  </CenterContentTemplate>
);

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  return (
    <Box>
      {Object.values(LANGUAGES).map((language, i) => (
        <Language
          key={i}
          active={i18n.language === language}
          onClick={() => i18n.changeLanguage(language)}
        >
          {language}
        </Language>
      ))}
    </Box>
  );
};

const Language = styled(Text)<{ active: boolean }>`
  margin-right: 15px;

  cursor: pointer;

  :last-child {
    margin-right: 0;
  }

  ${({ active }) =>
    active &&
    `
      border-bottom: 1px solid ${theme.colors.UIBlue};
    `}
`;
