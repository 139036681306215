import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getProfileById } from 'api/profile';
import { IResponse, IUser } from 'lib/types';
import { Header, PageLoader } from 'features/common';
import { DashboardTemplate, Box } from 'ui';
import { GeneralInfo, ContactInfo, ReferenceInfo, FeedbacksInfo } from 'features/profile';

export const UserPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery<IResponse<{ user: IUser }>>(
    [`user-${id}`, id],
    getProfileById,
  );

  return (
    <DashboardTemplate
      render={profile => (
        <Fragment>
          {data && data.data && data.data.user && (
            <Box
              mb={5}
              px={['10px', '0']}
              width={['100%', '490px']}
              flexDirection="column"
            >
              <GeneralInfo profile={data.data.user} />

              <ContactInfo profile={data.data.user} />

              <ReferenceInfo profile={data.data.user} />

              <FeedbacksInfo user={profile} />
            </Box>
          )}

          {isLoading && <PageLoader />}
        </Fragment>
      )}
      renderHeader={profile => <Header profile={profile} />}
    />
  );
};
