import React, { ChangeEvent, FocusEvent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from './text';
import { theme } from 'ui';

export interface IInput {
  id?: string;
  ref?: React.Ref<HTMLInputElement>;
  icon?: string;
  name?: string;
  type: string;
  hint?: string;
  value?: string | number;
  label?: string;
  error?: boolean | string;
  accept?: string;
  required?: boolean;
  disabled?: boolean;
  onBlur?: (e: FocusEvent) => void;
  onChange?: (e: ChangeEvent) => void;
  placeholder?: string;
  autoComplete?: string;
  styles?: {
    [key: string]: string;
  };
}

export const Input = React.forwardRef(
  (
    {
      id,
      name,
      icon,
      type,
      hint,
      value,
      label,
      error,
      onBlur,
      styles,
      disabled,
      required,
      onChange,
      placeholder,
      autoComplete,
    }: IInput,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();
    const [isFocused, setFocused] = useState(Boolean(value));

    return (
      <FieldBox>
        {label && (
          <Label htmlFor={id} disabled={disabled}>
            {required && (
              <Text color="UIRed" mr={0}>
                *
              </Text>
            )}

            {t(label)}
          </Label>
        )}

        <InputNative
          id={id}
          ref={ref}
          icon={icon}
          name={name}
          type={type}
          value={value}
          style={styles && { ...styles }}
          error={Boolean(error)}
          onBlur={e => {
            if (!value) {
              setFocused(false);
            }

            if (onBlur) {
              onBlur(e);
            }
          }}
          onFocus={() => setFocused(true)}
          disabled={disabled}
          onChange={onChange}
          isFocused={isFocused}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />

        {hint && <FieldHint>{t(hint as string)}</FieldHint>}

        {error && <FieldError>{t(error as string)}</FieldError>}
      </FieldBox>
    );
  },
);

export const FieldBox = styled.div`
  position: relative;

  margin-bottom: 15px;
`;

export const InputNative = styled.input<{
  error: boolean;
  isFocused: boolean;
  icon: string | undefined;
}>`
  width: 100%;
  padding: 10px 12px;

  outline: none;
  border: 1px solid ${theme.colors.UIGray};

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  ${({ isFocused }) =>
    isFocused &&
    `
      border-color: ${theme.colors.UIBlue};
    `}

  ${({ error }) =>
    error &&
    `
      color: ${theme.colors.UIRed};
      border: 1px solid ${theme.colors.UIRed};
    `}

  ${({ icon }) =>
    icon &&
    `
      padding-left: 32px;

      background-image: url(${icon});
      background-repeat: no-repeat;
      background-position: 8px;
    `}

  :disabled {
    border-color: ${theme.colors.UIGray};
  }
`;

export const Label = styled.label<{ disabled?: boolean }>`
  display: block;
  margin-bottom: 5px;

  font-size: ${theme.fontSizes[1]}px;
  font-weight: 600;

  ${({ disabled }) =>
    disabled &&
    `
      color: ${theme.colors.UIGray};

      > span {
        color: ${theme.colors.UIGray};
      }
    `}
`;

export const FieldHint = styled.span`
  display: block;
  margin-top: 5px;

  color: ${theme.colors.UIDarkGray};
  font-size: ${theme.fontSizes[0]}px;
  white-space: pre-line;
`;

export const FieldError = styled.span`
  display: block;
  margin-top: 5px;

  color: ${theme.colors.UIRed};
  font-size: ${theme.fontSizes[0]}px;
`;
