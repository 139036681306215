import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';

import { IJob, IResponse } from 'lib/types';
import { JobUserInfo } from 'features/jobs';
import { PRICING_TYPES } from 'features/jobs/lib/constants';
import { invokeJobApply } from '../model';
import { ButtonTooltip, MAX_TEXTAREA_LENGTH, SlimLoader } from 'features/common';
import { Form, Card, Text, Box, Input, Textarea, PrimaryButton } from 'ui';
import { checkApply } from 'api/applies';
import { useQuery } from 'react-query';

const validationSchema = Yup.object().shape({
  cover_letter: Yup.string()
    .max(
      MAX_TEXTAREA_LENGTH,
      `Cover letter length should not exceed ${MAX_TEXTAREA_LENGTH} characters`,
    )
    .required('Cover letter field is required'),
});

const ApplyJobLayout = ({
  job,
  values,
  errors,
  status,
  touched,
  setStatus,
  handleBlur,
  submitForm,
  handleChange,
  validateForm,
  setTouched,
  resetForm,
}: FormikProps<IApplyJobFormState> & { job: IJob }) => {
  const { t } = useTranslation();
  const [pricingType] = Object.values(PRICING_TYPES).filter(
    x => x.value === job.pricing_type,
  );
  const { icon, fieldName, fieldSign } = pricingType;
  const { isLoading, refetch, isSuccess, remove } = useQuery<
    IResponse<{ status: boolean; message?: string }>
  >(['check-apply', job.id], checkApply, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: async data => {
      if (data.data.status) {
        remove();
        resetForm();
        return setStatus({ message: data.data.message });
      }

      await submitForm();
    },
  });

  const onSubmit = async () => {
    setTouched({ cover_letter: true, final_price: true });

    const errors = await validateForm(values);
    const isValid = !Object.keys(errors).length;

    return isValid ? refetch() : null;
  };

  return (
    <Card>
      <JobUserInfo user={job.user} postedLabel="Applied date" postedDate={new Date()} />

      <Form>
        <Box mb="15px" flexDirection="column">
          {!job.price_by_agreement ? (
            <Fragment>
              <Text mb={2} color="UIBlue" fontWeight="bold">
                {t('Conditions')}:
              </Text>

              <Text mb={2}>
                {t(
                  'If you want to change conditions, fill in your proposal in the blank field',
                )}
                :
              </Text>

              <Box
                px="40px"
                mx="-40px"
                borderBottom="1px solid"
                borderBottomColor="UIGray"
                alignItems="flex-start"
              >
                <Box
                  px="12px"
                  mr="25px"
                  height="40px"
                  border="1px solid"
                  alignItems="center"
                  borderColor="UIGray"
                >
                  <Text color="UIDarkGray" fontWeight="bold">
                    {fieldSign}
                  </Text>

                  <Text pl="10px" color="UIDarkGray" fontWeight="bold">
                    {job[fieldName]}
                  </Text>
                </Box>

                <Input
                  id="final_price"
                  name="final_price"
                  type="text"
                  icon={icon}
                  value={values.final_price || ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
            </Fragment>
          ) : (
            <Box
              pb="15px"
              px="40px"
              mx="-40px"
              borderBottom="1px solid"
              borderBottomColor="UIGray"
            >
              <Text color="UIBlue" fontWeight="bold">
                {t('Pricing')}:&nbsp;
              </Text>

              <Text>{t('By agreement')}</Text>
            </Box>
          )}
        </Box>

        <Box mb="10px" flexDirection="column">
          <Text mb={2} color="UIBlue" fontWeight="bold">
            {t('Cover letter')}:
          </Text>

          <Textarea
            id="cover_letter"
            name="cover_letter"
            label=""
            value={values.cover_letter}
            error={touched.cover_letter && errors.cover_letter}
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength={MAX_TEXTAREA_LENGTH}
          />
        </Box>

        <Box justifyContent="center">
          <ButtonTooltip tooltip="Now you can track the status of your apply-card at the following path: Search Job > My applied Jobs > <u>Applied.</u>">
            {isLoading || (isSuccess && !status?.message) ? (
              <SlimLoader />
            ) : (
              <PrimaryButton type="button" style={{ width: '100%' }} onClick={onSubmit}>
                {t('Apply')}
              </PrimaryButton>
            )}
          </ButtonTooltip>
        </Box>
      </Form>

      {status && (
        <Text mt={3} fontSize="12px" textAlign="center" color="UIRed">
          {t(status.message)}
        </Text>
      )}
    </Card>
  );
};

export const ApplyJobForm = withFormik({
  mapPropsToValues: ({ job }: { job: IJob }): IApplyJobFormState => ({
    cover_letter: '',
  }),

  validationSchema,

  handleSubmit: (values: IApplyJobFormState, { props }) => {
    const { job } = props;
    const [pricingType] = Object.values(PRICING_TYPES).filter(
      x => x.value === job.pricing_type,
    );

    const priceForAgreement = job.price_by_agreement
      ? { by_agreement: true }
      : {
          by_percent: pricingType.fieldName === PRICING_TYPES.FROM_ASSESSMENT.fieldName,
          init_price: job[pricingType.fieldName] as number,
          final_price: (values.final_price || job[pricingType.fieldName]) as number,
        };

    invokeJobApply({
      id: job.id.toString(),
      data: {
        cover_letter: values.cover_letter,
        ...priceForAgreement,
      },
    });
  },
})(ApplyJobLayout);

export type IApplyJobFormState = {
  final_price?: number;
  cover_letter: string;
};
