import React, { useState, useEffect, Fragment } from 'react';

import { Map } from 'features/common';
import { Box, Image, theme, Text } from 'ui';
import { capitalizeFirstLetter } from 'lib/react-select-format-array';
import { getPositionFromAddress } from 'lib/position-from-address';

interface IJobLocationInfo {
  location: {
    continent: string;
    country: string;
    state: string;
    city: string;
  };
}

export const JobLocationInfo = ({ location }: IJobLocationInfo) => {
  const { city, ...rest } = location;

  return (
    <Box alignItems="flex-start">
      <Image src={theme.images.locationIcon} style={{ marginRight: theme.space[2] }} />

      <Box flexWrap="wrap">
        <Text mr={1} color="UIDarkGray">
          {getJobAddress(rest)}
          {', '}
        </Text>

        <Text>{city}</Text>
      </Box>
    </Box>
  );
};

export const JobMapInfo = ({ location }: IJobLocationInfo) => {
  const [position, setPosition] = useState<{ lat: number; lng: number } | null>(null);
  const address = getJobAddress(location);

  useEffect(() => {
    getPositionFromAddress(address)
      .then(x => setPosition(x))
      .catch(() => setPosition(null));
  }, [address]);

  return (
    <Fragment>
      {position && (
        <Map
          lat={position.lat}
          lng={position.lng}
          mapElement={<div style={{ height: '200px', width: '100%' }} />}
          isMarkerShown={true}
          containerElement={<div style={{ height: '200px', width: '100%' }} />}
        />
      )}
    </Fragment>
  );
};

export const getJobAddress = (location: {
  continent: string;
  country: string;
  state: string;
  city?: string;
}) =>
  Object.values(location)
    .filter(Boolean)
    .map(x => capitalizeFirstLetter(x || ''))
    .join(', ');
