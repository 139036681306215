import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { getAppliesStatistic } from 'api/applies';
import { getProposalsStatistic } from 'api/proposals';
import { IHiringNavStatistic, IResponse } from 'lib/types';
import { StatisticNumber, NavLink, theme } from 'ui';

type HiringNavigationProps = {
  isProposals: boolean;
  navItems: Array<{ label: string; route: string; status: number }>;
};

export const HiringNavigation = ({ navItems, isProposals }: HiringNavigationProps) => {
  const { data } = useQuery<IResponse<IHiringNavStatistic>>(
    ['statistic'],
    isProposals ? getProposalsStatistic : getAppliesStatistic,
  );

  return (
    <Navigation>
      {data &&
        data.data &&
        navItems.map((x, i) => (
          <NavigationItem key={i}>
            <NavigationLink to={x.route} exact={true} activeClassName="active">
              {x.label}

              <StatisticNumber ml={2} isHighlighted={data.data[x.status].hasNew}>
                {data.data[x.status].amount}
              </StatisticNumber>
            </NavigationLink>
          </NavigationItem>
        ))}
    </Navigation>
  );
};

const Navigation = styled.ul`
  width: 100%;
  display: flex;
  margin-bottom: 50px;

  box-shadow: 0px 4px 59px rgba(0, 0, 0, 0.14);
`;

const NavigationItem = styled.li`
  flex: 1;
  text-align: center;

  border-right: 1px solid ${theme.colors.UIGray};

  :last-child {
    border-right: none;
  }
`;

const NavigationLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${theme.colors.UIBlack};
  font-weight: bold;
  text-decoration: none;

  line-height: 50px;

  border-top: 4px solid transparent;

  :hover {
    border-top-color: ${theme.colors.UIGray};
  }

  &.active {
    border-top-color: ${theme.colors.UIBlue};
  }
`;
