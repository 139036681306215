import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';

import { IUser } from 'lib/types';
import { AvatarLoader } from 'features/profile';
import { OnboardingCardTemplate } from '../templates/onboarding-card-template';
import { Box, Form, Input, PrimaryButton, Textarea } from 'ui';
import { STEP_INDEXES, changeCurrentStep, populateProfileData } from '../model';

const GeneralInfoLayout = ({
  values,
  errors,
  touched,
  handleBlur,
  handleSubmit,
  handleChange,
}: FormikProps<IGeneralInfoFormState>) => {
  const { t } = useTranslation();

  return (
    <OnboardingCardTemplate title={t('General info')}>
      <Form onSubmit={handleSubmit}>
        <AvatarLoader />

        <Input
          id="username"
          name="username"
          type="text"
          label="Username"
          error={touched.username && errors.username}
          value={values.username}
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
          hint="Your Company name, if exists. Otherwise, your First Name and Last Name"
        />

        <Textarea
          id="description"
          name="description"
          label="Description"
          value={values.description}
          error={touched.description && errors.description}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <Input
          id="since_years_in_business"
          name="since_years_in_business"
          type="number"
          label="In business since"
          error={touched.since_years_in_business && errors.since_years_in_business}
          value={values.since_years_in_business || ''}
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
        />

        <Box
          flexDirection="column"
          alignItems="center"
          position="absolute"
          bottom="-100px"
          left="50%"
          style={{ transform: 'translateX(-50%)' }}
        >
          <PrimaryButton type="submit">{t('Next')}</PrimaryButton>
        </Box>
      </Form>
    </OnboardingCardTemplate>
  );
};

export const GeneralInfo = withFormik({
  mapPropsToValues: ({ profile }: { profile: IUser }): IGeneralInfoFormState => ({
    username: profile?.username || '',
    description: profile?.description || '',
    since_years_in_business: profile?.since_years_in_business || undefined,
  }),

  validationSchema: Yup.object().shape({
    username: Yup.string().required('Username field is required'),
    description: Yup.string(),
    since_years_in_business: Yup.string().required('In business since field is required'),
  }),

  handleSubmit: (values: IGeneralInfoFormState) => {
    populateProfileData(values);
    changeCurrentStep(STEP_INDEXES.ADDRESS_INFO);
  },
})(GeneralInfoLayout);

export interface IGeneralInfoFormState {
  username: string;
  description: string;
  since_years_in_business: undefined | number;
}
