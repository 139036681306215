import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import { Header, PageLoader } from 'features/common';
import { DashboardTemplate, Box } from 'ui';
import { NewEditJob, pageUnmounted } from 'features/jobs';
import { JobGate, jobFetching, $job } from 'pages/job/model';
import { withRouter, RouteComponentProps } from 'react-router';

export const EditJobPage = withRouter(
  ({ match }: RouteComponentProps<{ id: string }>) => {
    const job = useStore($job);
    const isLoading = useStore(jobFetching.isLoading);

    useEffect(() => () => pageUnmounted(), []);

    return (
      <DashboardTemplate
        render={() => (
          <Box
            px={['10px', '0']}
            mb="90px"
            width={['100%', '490px']}
            flexDirection="column"
          >
            <JobGate id={match.params.id} />

            {job && <NewEditJob job={job} />}

            {isLoading && <PageLoader />}
          </Box>
        )}
        renderHeader={profile => <Header profile={profile} />}
      />
    );
  },
);
