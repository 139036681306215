export interface IContinentOption {
  code: string;
  label: string;
  value: string;
}

export const CONTINENT_OPTIONS: IContinentOption[] = [
  { label: 'Africa', value: 'Africa', code: 'AF' },
  { label: 'Antarctica', value: 'Antarctica', code: 'AN' },
  { label: 'Asia', value: 'Asia', code: 'AS' },
  { label: 'Europe', value: 'Europe', code: 'EU' },
  { label: 'North America', value: 'North America', code: 'NA' },
  { label: 'Australia', value: 'Australia', code: 'OC' },
  { label: 'South America', value: 'South America', code: 'SA' },
];
