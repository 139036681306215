import { useEffect } from 'react';
import { FormikErrors } from 'formik';

type ScrollToErrorProps<T> = {
  errors: FormikErrors<T>;
  isSubmitting: boolean;
  isValidating: boolean;
};

const getElementBySelector = (selector: string): HTMLInputElement | null =>
  document.querySelector(selector);

export const ScrollToError = <T,>({
  errors,
  isSubmitting,
  isValidating,
}: ScrollToErrorProps<T>) => {
  useEffect(() => {
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = getElementBySelector(`[name="${keys[0]}"]`);
      const reactSelectErrorElement = getElementBySelector(
        `[id="${keys[0]}"]`,
      )?.querySelector('input');

      if (errorElement && errorElement.type !== 'hidden') {
        errorElement.focus();
      } else {
        reactSelectErrorElement?.focus();
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};
