import React from 'react';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';

import { history } from 'lib/routing';
import { IError, IFeedback } from 'lib/types';
import { MAX_TEXTAREA_LENGTH } from 'features/common';
import { removeEmptyFieldsInObject } from 'lib/remove-blank-fields-in-object';
import { leaveFeedback, LeaveFeebackDTO } from 'api/feedbacks';
import { Form, Box, Textarea, PrimaryButton, RSelect, Checkbox } from 'ui';
import {
  FEEDBACK_REASON_OPTIONS,
  FEEDBACK_STATUS_OPTIONS,
} from '../lib/feedback-options';

type IFeedbackFormProps = {
  userId: number;
  applyId: string;
  feedbackType: string;
  feedback: IFeedback | undefined;
};

const validationForUpdatingRule = Yup.object().shape({
  updateRatingRule: Yup.boolean()
    .oneOf([true], 'This rule is required')
    .required('This rule is required'),
});

const validationSchema = Yup.object().shape({
  text: Yup.string()
    .max(
      MAX_TEXTAREA_LENGTH,
      `Feedback length should not exceed ${MAX_TEXTAREA_LENGTH} characters`,
    )
    .required('Text feedback is required'),
  status: Yup.number().required('This field is required'),
  reason: Yup.number().when('status', {
    is: 0,
    then: Yup.number().required('This field is required'),
    otherwise: Yup.number(),
  }),
  createRatingRule: Yup.boolean()
    .oneOf([true], 'This rule is required')
    .required('This rule is required'),
});

const FeedbackLayout = ({
  values,
  errors,
  touched,
  userId,
  applyId,
  feedback,
  handleBlur,
  feedbackType,
  handleChange,
  setFieldValue,
  validateForm,
  setTouched,
}: FormikProps<IFeedbackFormState> & IFeedbackFormProps) => {
  const { t } = useTranslation();
  const shouldSpecifyReason = values.status === FEEDBACK_STATUS_OPTIONS[1].value;
  const [mutationLeaveFeedback] = useMutation<unknown, IError, LeaveFeebackDTO>(
    leaveFeedback,
    {
      onSuccess: () => {
        history.replace(`/users/${userId}`);
      },
    },
  );

  const onSubmit = async () => {
    setTouched({
      text: true,
      status: true,
      reason: true,
      createRatingRule: true,
      updateRatingRule: Boolean(feedback),
    });

    const data = removeEmptyFieldsInObject(values) as LeaveFeebackDTO['data'];
    const errors = await validateForm(values);
    const isValid = !Object.keys(errors).length;

    if (isValid) {
      await mutationLeaveFeedback({
        id: applyId,
        type: feedbackType,
        data,
      });
    }
  };

  return (
    <Form>
      <Box flexDirection="column">
        <RSelect
          id="status"
          name="status"
          label="Did this user meet their contractual obligations?"
          error={touched.status && errors.status}
          value={FEEDBACK_STATUS_OPTIONS.find(x => x.value === values.status) || null}
          options={FEEDBACK_STATUS_OPTIONS}
          onBlur={handleBlur}
          onChange={(option: any) => {
            setFieldValue('status', option.value);
            setFieldValue('reason', undefined);
          }}
          required={true}
          getOptionLabel={x => t(x.label)}
          placeholder={t('Select one')}
        />

        {shouldSpecifyReason && (
          <RSelect
            id="reason"
            name="reason"
            label="Comments"
            error={touched.reason && errors.reason}
            value={FEEDBACK_REASON_OPTIONS.find(x => x.value === values.reason) || null}
            options={FEEDBACK_REASON_OPTIONS}
            onBlur={handleBlur}
            onChange={(option: any) => setFieldValue('reason', option.value)}
            required={true}
            getOptionLabel={x => t(x.label)}
            placeholder={t('Select one')}
          />
        )}

        <Textarea
          id="text"
          name="text"
          label={t('Leave your feedback')}
          value={values.text}
          error={touched.text && errors.text}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength={MAX_TEXTAREA_LENGTH}
          required={true}
        />

        <Box mt={3} flexDirection="column">
          <Checkbox
            id="create_rating_rule"
            name="create_rating_rule"
            label="By submitting a rating you declare that you have conducted business with the rated User. Otherwise, your subscription and/or rating privileges may be suspended."
            error={touched.createRatingRule && errors.createRatingRule}
            checked={Boolean(values.createRatingRule)}
            onChange={() => setFieldValue('createRatingRule', !values.createRatingRule)}
          />

          {Boolean(feedback) && (
            <Checkbox
              id="update_ration_rule"
              name="update_ration_rule"
              label={`By clicking "Update Rating" below you confirm that your complaint is not retaliatory or trivial in nature. Submitting ratings of such types may result in suspension of rating privileges`}
              error={touched.updateRatingRule && errors.updateRatingRule}
              checked={Boolean(values.updateRatingRule)}
              onChange={() => setFieldValue('updateRatingRule', !values.updateRatingRule)}
            />
          )}
        </Box>

        <PrimaryButton type="button" style={{ marginTop: '40px' }} onClick={onSubmit}>
          {feedback ? t('Update feedback') : t('Leave feedback')}
        </PrimaryButton>
      </Box>
    </Form>
  );
};

export const FeedbackForm = withFormik({
  mapPropsToValues: ({ feedback }: IFeedbackFormProps): IFeedbackFormState => ({
    text: feedback ? feedback.text : '',
    status: feedback ? feedback.status : 0 || undefined,
    reason: feedback ? feedback.reason : 0 || undefined,
    createRatingRule: false,
    updateRatingRule: false,
  }),

  validationSchema: ({ feedback }: IFeedbackFormProps) => {
    return Boolean(feedback)
      ? validationSchema.concat(validationForUpdatingRule)
      : validationSchema;
  },

  handleSubmit: (_: IFeedbackFormState) => null,
})(FeedbackLayout);

export type IFeedbackFormState = {
  text: string;
  status: number | undefined;
  reason: number | undefined;
  createRatingRule: boolean;
  updateRatingRule: boolean;
};
