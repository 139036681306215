import { createStore, createEvent, createEffect, forward } from 'effector';

import { loadAvatar } from 'api/profile';
import { createFetching } from 'lib/fetching';
import { IResponse, IError } from 'lib/types';

// AVATAR MODEL
export const $avatar = createStore<string>('');
export const invokeAvatarLoading = createEvent<File>();
export const loadAvatarEffect = createEffect<
  File,
  IResponse<{ avatar: string }>,
  IError
>();
export const avatarFetching = createFetching(loadAvatarEffect);

loadAvatarEffect.use(x => loadAvatar(x));

$avatar.on(loadAvatarEffect.done, (_, { params, result: { data } }) => data.avatar);

forward({
  from: invokeAvatarLoading,
  to: loadAvatarEffect,
});
