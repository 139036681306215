import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { IUser } from 'lib/types';
import { Avatar } from './avatar';
import { history } from 'lib/routing';
import { tokenDropped } from 'lib/token';
import { MobileDropdownMenu } from './mobile-dropdown-menu';
import { Box, Image, Text, theme } from 'ui';
import { dropdownMenus } from '../lib/dropdown-menus';

export const MobileMenu = ({
  profile,
  setVisibleMobileMenu,
}: {
  profile: IUser;
  setVisibleMobileMenu: Dispatch<boolean>;
}) => {
  return (
    <Box
      position="fixed"
      top="60px"
      left="0px"
      style={{ boxShadow: 'inset 0px 4px 20px rgba(0, 0, 0, 0.15)' }}
      width={1}
      zIndex={9999}
      height="100%"
      flexDirection="column"
      backgroundColor="UIWhite"
    >
      <UserInfo
        avatar={profile.avatar}
        userName={profile.username}
        userRole={profile.user_role}
      />

      {dropdownMenus.map((x, i) => (
        <MobileDropdownMenu key={i} {...x} user={profile} />
      ))}

      <MenuItem
        icon={theme.images.profileIcon}
        text="Profile"
        onClick={() => {
          history.push('/profile');
          setVisibleMobileMenu(false);
        }}
      />

      <MenuItem
        icon={theme.images.logoutIcon}
        text="Logout"
        onClick={() => tokenDropped()}
      />
    </Box>
  );
};

const UserInfo = ({
  avatar,
  userName,
  userRole,
}: {
  avatar: string;
  userName: string;
  userRole: string;
}) => (
  <Box
    mt="30px"
    px="25px"
    pb="30px"
    alignItems="center"
    maxHeight="60px"
    width={1}
    borderBottom="1px solid"
    borderColor="UIGray"
  >
    <Avatar avatar={avatar} username={userName} />

    <Box flexDirection="column" pl={2} ml={2}>
      <Text fontWeight="bold">{userName}</Text>

      <Text color="UIDarkGray" fontSize="14px">
        {userRole}
      </Text>
    </Box>
  </Box>
);

const MenuItem = ({
  icon,
  text,
  onClick,
}: {
  icon: string;
  text: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      py="15px"
      px="25px"
      onClick={onClick}
      isPointer={true}
      alignItems="center"
      borderBottom="1px solid"
      borderColor="UIGray"
    >
      <Image src={icon} />

      <Text ml={2} fontWeight="bold" lineHeight="15px">
        {t(text)}
      </Text>
    </Box>
  );
};
