import React, { Fragment } from 'react';

import { Header } from 'features/common';
import { ProfileGate } from './model';
import { DashboardTemplate, Box } from 'ui';
import {
  GeneralInfo,
  ContactInfo,
  ReferenceInfo,
  FeedbacksInfo,
} from 'features/profile/';

export const ProfileViewPage = () => {
  return (
    <DashboardTemplate
      render={profile => (
        <Fragment>
          <ProfileGate />

          {profile && (
            <Box px={['10px', '0']} width={['100%', '490px']} flexDirection="column">
              <GeneralInfo profile={profile} />

              <ContactInfo profile={profile} />

              <ReferenceInfo profile={profile} />

              <FeedbacksInfo user={profile} />
            </Box>
          )}
        </Fragment>
      )}
      renderHeader={profile => <Header profile={profile} />}
    />
  );
};
