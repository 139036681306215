import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { getMessages } from 'api/dashboard';
import { Header, PageLoader } from 'features/common';
import { IMessage, IResponse } from 'lib/types';
import { Box, theme, Text, DashboardTemplate } from 'ui';

export const DashboardPage = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery<IResponse<IMessage[]>>(['messages'], getMessages);

  return (
    <DashboardTemplate
      render={() => (
        <Fragment>
          <Box width={['100%', '800px']} mx={['20px', 0]} mb={5} flexDirection="column">
            <Text fontSize={5} mb={5}>
              {t('Account Dashboard')}
            </Text>

            {data && data.data && (
              <Box flexDirection="column">
                <Text fontSize={3} mb="25px">
                  {t('SYSTEM ALERTS')}
                </Text>

                {data.data.map(m => (
                  <Message key={m.id} title={m.title} description={m.description} />
                ))}
              </Box>
            )}
          </Box>

          {isLoading && <PageLoader />}
        </Fragment>
      )}
      renderHeader={profile => <Header profile={profile} />}
    />
  );
};

const Message = ({ title, description }: { title: string; description: string }) => (
  <Box
    mb="20px"
    padding="20px"
    border="1px solid"
    borderColor="UIOrange"
    borderLeft="5px solid"
    borderLeftColor="UIOrange"
    flexDirection="column"
  >
    <Text fontSize={3} fontWeight="bold" mb={2}>
      {title}
    </Text>

    <Description dangerouslySetInnerHTML={{ __html: description }}></Description>
  </Box>
);

const Description = styled(Text)`
  a {
    color: ${theme.colors.UIBlue};
  }
`;
