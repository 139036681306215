export const initialValues = {
  type: 0,
  continents: [],
  countries: [],
  states: [],
  damage_type: '',
  ready_to_work: undefined,
  payment_type: '',
  min_pay_per_vehicle: undefined,
  min_percent_pay: undefined,
  highlight_listings: undefined,
  highlighted_on_top: false,
  sort_by: undefined,
  search_save: 0,
  radius: undefined,
  state: '',
  address: '',
  country: '',
  city: '',
  continent: '',
  postal_code: '',
};
